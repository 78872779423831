import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';
import { Range } from 'rc-slider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface Props {}

const SeatsRangeSelector = (props: Props) => {
  const { t } = useTranslation();
  const { searchFilters, setSearchFilters } = useSearchCarsContext();

  const [error, setError] = useState(false);
  const [localSeatsRange, setLocalSeatsRange] = useState(
    searchFilters.seatsRange
  );

  const applyPriceRange = () => {
    const [minSeat, maxSeat] = localSeatsRange;

    if (
      minSeat > maxSeat ||
      maxSeat < minSeat ||
      minSeat < 0 ||
      maxSeat < 0 ||
      Number.isNaN(minSeat) ||
      Number.isNaN(maxSeat)
    ) {
      setError(true);
      return;
    }

    setError(false);
    setSearchFilters((prev) => ({ ...prev, seatsRange: localSeatsRange }));
  };

  return (
    <>
      <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">
              {/* {t("searchFilterModal-pricePerDay")} */}
              {t('searchFilterModal-seats')}
            </span>
            <Range
              className="text-red-400"
              min={1}
              max={7}
              defaultValue={localSeatsRange}
              value={localSeatsRange}
              allowCross={false}
              onChange={([minSeat, maxSeat]) =>
                setLocalSeatsRange([minSeat, maxSeat])
              }
            />
          </div>

          <div className="flex justify-between space-x-5">
            <div>
              <label
                htmlFor="minPrice"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                {/* {t("searchFilterModal-minPrice")} */}
                {t('searchFilterModal-minSeats')}
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  type="text"
                  name="minPrice"
                  id="minPrice"
                  min={1}
                  max={localSeatsRange[1]}
                  onChange={(e) =>
                    setLocalSeatsRange([+e.target.value, localSeatsRange[1]])
                  }
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                  value={localSeatsRange[0]}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="maxPrice"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                {t('searchFilterModal-maxPrice')}
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  type="text"
                  name="maxPrice"
                  id="maxPrice"
                  min={localSeatsRange[0]}
                  max={7}
                  onChange={(e) =>
                    setLocalSeatsRange([localSeatsRange[0], +e.target.value])
                  }
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                  value={localSeatsRange[1]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">
              {t('invalid-search')}
            </strong>
          )}
          <ButtonPrimary
            onClick={applyPriceRange}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t('apply')}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default SeatsRangeSelector;
