import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AccountNavbar from "./AccountNavbar";

export interface CommonLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  title: string
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, title }) => {
  const { t } = useTranslation();
  
  return (
    <div className="w-11/12 mx-auto grid grid-cols-4 gap-4 py-4">
      <AccountNavbar className="p-5 border-r-2 border-r-slate-300" />
      <div className="col-span-3 py-5 px-10">
        <CommonLayoutTitle title={t(title)}/>
        {children}
      </div>
    </div>
  );
};

function CommonLayoutTitle({title}: {title: string}) {
  return (
    <div className="border-b-2 border-b-slate-100 pb-2 mb-4 w-fit">
      <h1 className="text-3xl font-semibold text-slate-700">
        {title}
      </h1>
    </div>
  )
}

// const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
//   return (
//     <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
//       <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
//         <div className="container">
//           <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
//             <NavLink
//               activeClassName="!border-primary-500"
//               to="/account"
//               className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
//             >
//               Account info
//             </NavLink>
//             <NavLink
//               activeClassName="!border-primary-500"
//               to="/account-savelists"
//               className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
//             >
//               Save lists
//             </NavLink>
//             <NavLink
//               activeClassName="!border-primary-500"
//               to="/account-password"
//               className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
//             >
//               Change password
//             </NavLink>
//             <NavLink
//               activeClassName="!border-primary-500"
//               to="/account-billing"
//               className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
//             >
//               Change Billing
//             </NavLink>
//           </div>
//         </div>
//       </div>
//       <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
//     </div>
//   );
// };

export default CommonLayout;
