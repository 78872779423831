import NavigationItem from "./NavigationItem";
import { OFFICIAL_NAVIGATION } from "data/navigation";
import { useTranslation } from "react-i18next";

function Navigation() {
  const { t } = useTranslation() 

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {OFFICIAL_NAVIGATION.map((item) => (
        <NavigationItem defaultName={t(`${item.value}`)} key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
