import { useLoadGlobalData } from "hooks/useLoadGlobalData";
import MyRouter from "routers/index";
import './i18n/init'

function App() {
  useLoadGlobalData()

  return (
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
  );
}

export default App;
