import { Item } from "types/Item/Item";
import api from "./api";

const API_ENDPOINT = "/items";
const API_ITEMS_ENDPOINT = "/items/images";

export interface ItemsResponse {
  message: string;
  error: boolean;
  items?: Item[];
}

export interface ItemImagesResponse {
  message: string;
}

export interface ItemResponse {
  message: string;
  error: boolean;
  item?: Item;
}

export interface CarListingSubmissionRequestBody {
    detail_location: string;
    province_location: number;
    canton_location: number;
    district_location: number;
    latitude: string;
    longitude: string;

    id_owner: number;
    id_item_type: number;
    code_item: string;
    detail_item: string;
    brand_item: string;
    year_item: number;
    amount_item: number;
    max_passengers: number;
    description: string;
    available_item: boolean;
    tax_item: number;

    mileage: number;
    transmission: "AUTOMATIC" | "MANUAL";
    external_color: string;
    internal_color: string;
    doors_number: number;

    conditions?: any
    amenities?: any
    insurances?: any
    equipment?: any
}

export interface ItemBonus {
      id: number;
      priceInput: number;
      type: "FIXED" | "DAILY",
      isRequired: boolean;
}

const getItems = async () => {
  return (await api.get<ItemsResponse>(API_ENDPOINT)).data;
};

const createItem = async (carListingSubmission: CarListingSubmissionRequestBody) =>
  (
    await api.post<ItemResponse>(API_ENDPOINT, carListingSubmission, {
      headers: {
        'Content-Type': "application/json",
      },
    
    })
  ).data;

const storeItemImages = async (itemImagesData: any) => (await api.post<ItemImagesResponse>(API_ITEMS_ENDPOINT, itemImagesData, {
  headers: {
    'Content-type': 'multipart/form-data'
  }
})).data

export { getItems, createItem, storeItemImages };
