import { FC, useEffect, useMemo } from "react";
import { CarDataType } from "data/types";
import Heading2 from "components/Heading/Heading2";
import CarCard from "components/CarCard/CarCard";
import { useCarsState } from "hooks/useCarsState";
import { useLoadListingCarPage } from "../hooks/useLoadListingCarPage";
import { carsSelector } from "store/slices/carsSlice";
import { useAppSelector } from "store/hooks/useAppStore";
import { useTranslation } from "react-i18next";
import { useSearchCarsContext } from "../hooks/useSearchCarsContext";
import { checkIfIsReserved, checkIfIsReserved_ } from "helpers/reservations/checkIfIsReserved";
import { Option } from "../context/SearchCarsContext";
import { IReservation } from "types/Reservation";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = ""
}) => {
  useLoadListingCarPage();
  const data = useAppSelector(carsSelector.selectAll);
  const { fetchItemsStatus } = useCarsState() ?? {};
  const { searchInitReservationDate, searchEndReservationDate, searchFilters, setSearchFilters } =
    useSearchCarsContext();

  const { t } = useTranslation();

  const filterByType = (carType: string | undefined) =>
    searchFilters.itemType ? searchFilters.itemType === carType : true;

  const filterByBrand = (brand_item: string) => {
    const brands = searchFilters.brands || [];
    return brands.length > 0 ? brands.some((brand: any) => brand.value == parseInt(brand_item || '0')) : true;
  }

  const filterByModel = (model_item: string) => {
    const models = searchFilters.models || [];
    return models.length > 0 ? models.some((model: any) => model.value == parseInt(model_item || '0')) : true;
  }

  const filterByColor = (external_color: string) => {
    const colors = searchFilters.colors || [];
    return colors.length > 0 ? colors.some((color: any) => color.value == parseInt(external_color || '0')) : true;
  }

  const filterMultipleByType = (id_item_type: number) => {
    const types = searchFilters.types || [];
    return types.length > 0 ? types.some((type: any) => type.value == id_item_type) : true;
  }

  // const filterByYear = (carYear: string) =>
  //   searchFilters.years ? searchFilters.years.some(year => year.label == carYear) : true;

  const filterByTransmission = (carTransmission: string) =>
    searchFilters.transmissions ? searchFilters.transmissions.some((transmission: Option) => transmission.label == carTransmission) : true;

  const filterByPriceRange = (price: number) =>
    price >= searchFilters.pricesRange[0] &&
    price <= searchFilters.pricesRange[1];

  const filterBySeatsRange = (seats: number) =>
    seats >= searchFilters.seatsRange[0] &&
    seats <= searchFilters.seatsRange[1];

    const filterByProvince = (province_location: number) =>{
      const pickUpInputValue = searchFilters.others?.pickUpInputValue;
      console.log('pickUpInputValue', pickUpInputValue)
      return pickUpInputValue == null 
      || pickUpInputValue == '0'
      || province_location == 0 
      || province_location == parseInt(isNaN(pickUpInputValue) ? "0" : pickUpInputValue);
    }

    const filterByMultipleProvince = (province_location: number) =>{
      const provinces = searchFilters.provinces || [];
      return provinces.length > 0 ? provinces.some((province: any) => province_location == parseInt(province.value || '0')) : true;
    }

    const filterByRangeDate = (reservations: IReservation[]) => {
      if(!searchFilters.others)
        return true;
      const {dateRangeValue} = searchFilters.others;

      if(!dateRangeValue)
        return true;
      
      return !checkIfIsReserved_(dateRangeValue, reservations);
    }

  const filteredCars = useMemo(
    () => {
      return data
      ?.filter((car) => Boolean(filterByType(car.type?.detail_item_type)))
      .filter((car) => Boolean(filterByPriceRange(car.amount_item)))
      .filter((car) => Boolean(filterBySeatsRange(car.max_passengers)))
      .filter((car) => Boolean(filterByProvince(car.location ? car.location.province_location : 0)))
      // .filter((car) => Boolean(filterByRangeDate(car.reservations)))
      .filter((car) => Boolean(filterByBrand(car.brand_item)))
      .filter((car) => Boolean(filterByModel(car.model_item)))
      .filter((car) => Boolean(filterByColor(car.external_color)))
      .filter((car) => Boolean(filterMultipleByType(car.id_item_type)))
      .filter((car) => Boolean(filterByMultipleProvince(car.location ? car.location.province_location : 0)))
      // .filter((car) => filterByYear(car.year_item))
      .filter((car) => filterByTransmission(car.specifications?.transmission))
      .sort((a, b) => (a.order || 10000) - (b.order || 10000));
    },
    [data, searchFilters]
  );

  useEffect(()=>{
    console.log(searchFilters.seatsRange)
  },[searchFilters])



  if (fetchItemsStatus === "PENDING") {
    return (
      <div className="flex justify-center mb-16">
        <h4 className="text-5xl animate-pulse">
          Cargando carros y ofertas asombrosas...
        </h4>
      </div>
    );
  }

  if (fetchItemsStatus === "ERROR") {
    return (
      <div className="flex gap-6 flex-col items-center justify-center mb-16">
        <h4 className="text-5xl  font-bold text-red-500">
          {t("itemList-error-title")}
        </h4>
        <h4 className="text-2xl text-red-500">
          {t("itemList-error-subtitle")}
        </h4>
      </div>
    );
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`${t("gridFilterSection-title")} Alajuela`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {/* Items.length */}

            {data?.length === 1
              ? t("carWithCount", { count: 1 })
              : t("pluralCarWithCount", { count: filteredCars?.length })}

            <span className="mx-2">·</span>
            {t("today")}
          </span>
        }
      />

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredCars.length ? (
          filteredCars.map((car) => {
            return (
              <CarCard
                targetDate={searchInitReservationDate}
                endDate={searchEndReservationDate}
                key={car.id_item}
                data={car}
                isReserved={checkIfIsReserved(
                  [searchInitReservationDate, searchEndReservationDate],
                  car.reservations
                )}
              />
            );
          })
        ) : (
          <div>
            <h4 className="font-bold text-red-400">{t("no-cars-found")}</h4>
          </div>
        )}
      </div>

      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
