import { useBrandsState } from "hooks/useCarsBrandsState";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchItems } from "store/slices/brandSlice";

export const useLoadListingBrands = () => {
  const { fetchItemsStatus } = useBrandsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchItemsStatus === 'IDLE') {
      dispatch(fetchItems())
    }
  }, [])
  
}