import { FC } from "react";

export interface ListingCarPageProps {
  className?: string;
}
const ContacPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  return (
    <div>
    </div>
  );
};

export default ContacPage;
