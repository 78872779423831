import { useContext } from "react";
import { SearchCarsContext } from "../context/SearchCarsContext";

const useSearchCarsContext = () => {
  const value = useContext(SearchCarsContext);

  if (!value) {
    throw new Error("Cannot access to the required context");
  }

  return value;
};

export { useSearchCarsContext };
