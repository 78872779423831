import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Select, { MultiValue } from 'react-select';
import { Option } from 'containers/ListingCarPages/context/SearchCarsContext';
import { PROVINCES } from 'constants/provinces';

interface Props {}

const ProvinceSelector = (props: Props) => {
  const { t } = useTranslation();
  const { setSearchFilters } = useSearchCarsContext();

  const provinces = (PROVINCES || [])
    .filter((province) => province.value != '0')
    .map((province) => ({
      value: province.value,
      label: province.text,
    }));

  const [error, setError] = useState(false);

  const [localProvinces, setLocalProvinces] = useState<MultiValue<Option>>([]);

  const applyBrandSelection = () => {
    setError(false);
    setSearchFilters((prev) => ({ ...prev, provinces: localProvinces }));
  };

  return (
    <>
      <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">{t('provinces')}</span>

            <Select
              options={provinces}
              isMulti
              name="cities"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(provinces: any) => setLocalProvinces(provinces)}
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">
              {t('invalid search')}
            </strong>
          )}
          <ButtonPrimary
            onClick={applyBrandSelection}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t('apply')}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default ProvinceSelector;
