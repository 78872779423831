

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import CheckoutStripeAlt from "containers/StripeSuccesfulPage/CheckoutFormPageAlt";

interface PaymentModalProps {
  isOpen: boolean;
  country: number;
  amount: number;
  onCloseModal: () => void;
  setCompletedPayment: Dispatch<SetStateAction<boolean>>;
}

interface PaymentForm {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  email: string;
  cardNumber: string;
  cardCvv: string;
  cardHolderName: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
}

const PaymentModalAlt = ({
  isOpen,
  onCloseModal,
  country,
  setCompletedPayment,
  amount
}: PaymentModalProps) => {
  const { t } = useTranslation();
  const { ...methods } = useForm<PaymentForm>();
  const [submitPayment, setSubmitPayment] = useState<boolean | null>(null);

  const closeModal = () => {
    methods.reset();
    onCloseModal();
  };
  const onSubmitPayment = async () => {
    setSubmitPayment(!submitPayment);
  };

  useEffect(()=>{
    setSubmitPayment(null);
  }, [country]);

  

  return (
    <NcModal
      isOpenProp={isOpen}
      onCloseModal={closeModal}
      modalTitle={t("paymentModal-title")}
      contentPaddingClass="py-8 px-12"
      contentExtraClass="max-w-7xl"
      renderTrigger={() => <></>}
    >
      { <>
          <CheckoutStripeAlt amount={amount} submitPayment={submitPayment} setCompletedPayment={setCompletedPayment}/>
            {/* {country == 53 
              ? <BacCheckoutForm submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservationResponse}></BacCheckoutForm>
              :
                <CheckoutStripe amount={amount} submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservationResponse}></CheckoutStripe>
            } */}

          <div className="mt-4">
            <ButtonPrimary onClick={onSubmitPayment}>Pay ${amount}</ButtonPrimary>
          </div>
          </>    
      }
    </NcModal>
  );
};

export type { PaymentModalProps };
export default PaymentModalAlt;
