import { TimeRage } from "components/HeroSearchForm/FlightSearchForm";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { getDayPrice } from "helpers/reservations/calculatePrices";
import useWindowSize from "hooks/useWindowResize";
import moment, { Moment } from "moment";
import { useState } from "react";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import { useTranslation } from "react-i18next";
import { Item } from "types/Item/Item";

interface ICheckInSectionProps {
  dateRangeValue: DateRage;
  timeRangeValue: TimeRage;
  setDateRangeValue: (date: any) => void;
  currentItem: Item;
}

const CheckInSection = ({
  dateRangeValue,
  timeRangeValue,
  setDateRangeValue,
  currentItem,
}: ICheckInSectionProps) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const getDayPrice_ = (calendar_date: Moment): number | undefined => {
    const price = getDayPrice(currentItem, calendar_date);

    return price;
  }

  return (
    <div className="listingSection__wrap overflow-hidden">
      <div>
        <h2 className="text-2xl font-semibold">{t("availability")}</h2>
      </div>
      <div className="listingSection__wrap__DayPickerRangeController flow-root">
        <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
          <DayPickerRangeController
            renderDayContents={(day, modifiers) => {
              return (
                <div
                  className={
                    modifiers.has("blocked-calendar")
                      ? "text-red-600 font-bold line-through"
                      : ""
                  }
                >
                  <span className="font-bold block">
                    {day.date()}
                  </span>
                  <span className="block text-xs ">
                   ${getDayPrice_(day)}
                  </span>
                </div>
              );
            }}
            isOutsideRange={(day) => day.isBefore(moment(new Date()))}
            isDayBlocked={(day) =>
              currentItem?.reservations?.some(
                ({ init_reservation, end_reservation }) =>
                  day.isBetween(
                    moment(init_reservation),
                    moment(end_reservation),
                    "days",
                    "[]"
                  )
              )
            }
            startDate={dateRangeValue.startDate}
            endDate={dateRangeValue.endDate}
            onDatesChange={(date) => setDateRangeValue(date)}
            focusedInput={focusedInputSectionCheckDate}
            onFocusChange={(focusedInput) =>
              setFocusedInputSectionCheckDate(focusedInput || "startDate")
            }
            initialVisibleMonth={null}
            numberOfMonths={windowSize.width < 1280 ? 1 : 2}
            daySize={getDaySize()}
            hideKeyboardShortcutsPanel
          />
        </div>
      </div>
    </div>
  );
};

export default CheckInSection;
