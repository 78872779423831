import LandingSearchFilters, {
  SearchTab,
} from "components/HeroSearchForm/LandingSearchForm";
import { Dispatch, SetStateAction } from "react";

interface SearchBarProps {
  currentTab: SearchTab;
  setSearchData?: Dispatch<SetStateAction<any>>;
}

const SearchBar = ({ currentTab, setSearchData }: SearchBarProps) => {
  return (
    <div className="flow-root w-full pb-0 md:pb-28 lg:pb-0">
      <div className="z-10 relative md:top-40 w-full md:absolute">
        <LandingSearchFilters
          className="mx-auto"
          currentTab={currentTab}
          setSearchData={setSearchData}
        />
      </div>
    </div>
  );
};
export default SearchBar;
