import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getBrands } from "api/brandsApi";
import { Brand } from "types/Item/Item";

export const fetchItems = createAsyncThunk('brands/fetchBrands', getBrands);

const brandsAdapter = createEntityAdapter<Brand>({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.id - a.id
})

export const brandsSlice = createSlice({
  name: 'brands',
  initialState: brandsAdapter.getInitialState({
    error: false,
    fetchItemsStatus: 'IDLE'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchItems.pending, (state, action) => {
      state.fetchItemsStatus = 'PENDING'
    })

    builder.addCase(fetchItems.fulfilled, (state, action) => {
      state.fetchItemsStatus = 'DONE'
      brandsAdapter.setAll(state, action.payload.brands as Brand[])
    })

    builder.addCase(fetchItems.rejected, (state, action) => {
      state.error = true
      state.fetchItemsStatus = 'ERROR'
    })
  }}
)

export const brandsSelector = brandsAdapter.getSelectors((state: any) => state.brands)
export const brandsReducer = brandsSlice.reducer