import { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

interface FormControlProps {
  id?: string;
  label?: string;
  name?: string;
  registerOptions?: RegisterOptions;
  className?: string;
  children: any;
}

const FormControl: FC<FormControlProps> = ({
  name,
  label,
  id,
  registerOptions,
  className,
  children,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  let registerRest, inputError;

  if (name) {
    registerRest = register(name, registerOptions);
    inputError = errors?.[name];
  }

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <div className="flex justify-between items-end">
        {label && <label htmlFor={id}>{label}</label>}
        {inputError && (
          <small className="text-xs text-red-500 text-right">
            {inputError?.message}
          </small>
        )}
      </div>

      {children({ name, registerRest })}
    </div>
  );
};

export default FormControl;
