import { Dispatch, FC, ReactNode, SetStateAction, useState, useEffect } from "react";
import { SearchTab } from "components/HeroSearchForm/LandingSearchForm";
import { ImageBackground } from "./components/ImageBackground";
import { SearchBar } from "./components/SearchBar";
import Filters from "./components/Filters/Filters";
import GallerySlider from "components/GallerySlider/GallerySlider";
import autosImage from "images/autos.png";
import api from "api/api";

export interface LadingSectionProps {
  className?: string;
  listingType?: ReactNode;
  currentTab: SearchTab;
  rightImage?: string;
  setSearchData?: Dispatch<SetStateAction<any>>;
}

const LandingSection: FC<LadingSectionProps> = ({
  className = "",
  currentTab,
  setSearchData
}) => {

  const [images, setImages] = useState<string[]>([]);

  const getImages = async function(){
    const response = await api.get<any>('/v1/banners');
    const banners = response?.data?.banners || [];
    setImages(banners.map((banner: any) => banner.public_route_image));
  }

  useEffect(() => {
    getImages();
  }, []);



  
  return (
    <div className={`flex flex-col mx-auto relative ${className}`}>
      {/* <ImageBackground /> */}
      <div>
        {images.length > 0 && <GallerySlider galleryImgs={images} uniqueID="landing" />}
      </div>
      
      <SearchBar currentTab={currentTab} setSearchData={setSearchData}/>
      <Filters />
    </div>
  );
};

export default LandingSection;
