const ENVS = {
  local: "http://localhost:8000",
  prod: "https://admin.rentacarsummer.com/rentacar-backend/public",
  stage: "https://rent-admin.stagecr.com/rentacar-backend/public",
};

const ENVS_FRONTEND = {
  local: "http://localhost:3001",
  prod: "http://rentacarsummer.com",
  stage: "http://rent.stagecr.com",
  apigatewaypaymentlocal: "http://localhost:8080",
  apigatewaypaymentprod: "https://mipasareladepago.com",
}

const PAYPAL_CLIENT_ID = {
  local: "AWRuP63DIm9lIFNoVLqmTgTQ0iORclEgyxsVmvHk-JGzRgTXMTO5KLOGk9C7_OonedQRtzFcuXIK2fgV",
  prod: "Adu_H1qsg8asUs-J9vwpuNER__pQrUtWkaorB-jpWjNeanpQlDHQCUd9JI_6LTGAMuvUFHwtv50bzFYV",
  stage: "AWRuP63DIm9lIFNoVLqmTgTQ0iORclEgyxsVmvHk-JGzRgTXMTO5KLOGk9C7_OonedQRtzFcuXIK2fgV"
}

/* Testing Account sb-mch7x479005@business.example.com  Bq=92x6* */

const env = ENVS.prod;
const env_frontend = ENVS_FRONTEND.prod;
const env_paypal = PAYPAL_CLIENT_ID.local;
const env_bac_payment = ENVS_FRONTEND.apigatewaypaymentlocal; // cambiar a prod cuando este listo

export { env, env_frontend, env_paypal, env_bac_payment };
