import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { FaLocationArrow, FaVoicemail, FaPhone } from 'react-icons/fa';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Filters from 'containers/ReservationCheckout/Filters';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import ContactForm from 'containers/PageContact/ContactForm';
export interface PageContactProps {
  className?: string;
}

const PageContact = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`nc-PageContact overflow-hidden`} data-nc-id="PageContact">
      <div className="mb-24 lg:mb-32">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('navigation-contact')}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  <FaLocationArrow className="inline-block mr-2 text-xl text-neutral-500 dark:text-neutral-400" />
                  {t('address').toUpperCase()}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  Mall internacional alajuela local #80
                </span>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  <FaVoicemail className="inline-block mr-2 text-xl text-neutral-500 dark:text-neutral-400" />
                  {t('email').toUpperCase()}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  info@rentacarsummer.com
                </span>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  <FaPhone className="inline-block mr-2 text-xl text-neutral-500 dark:text-neutral-400" />
                  {t('phone').toUpperCase()}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  +506 6405 6463
                </span>
              </div>
              <Filters />
            </div>
            <div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
