import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUSES } from "api/types";
import { getItemTypes } from "api/itemTypesApi";
import { ItemType } from "types/Item/ItemType";

interface IItemTypesState {
  error: boolean;
  fetchItemTypesStatus: API_STATUSES;
  itemTypes: ItemType[];
}

const fetchItemTypes = createAsyncThunk(
  "item/types/fetchItemTypes",
  getItemTypes
);

const initialState: IItemTypesState = {
  error: false,
  fetchItemTypesStatus: "IDLE",
  itemTypes: [],
};

const itemTypesSlice = createSlice({
  name: "itemTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchItemTypes.pending, (state, action) => {
      state.fetchItemTypesStatus = "PENDING";
    });

    builder.addCase(fetchItemTypes.fulfilled, (state, action) => {
      state.fetchItemTypesStatus = "DONE";
      state.itemTypes = action.payload.itemTypes;
    });

    builder.addCase(fetchItemTypes.rejected, (state, action) => {
      state.error = true;
      state.fetchItemTypesStatus = "ERROR";
    });
  },
});

const itemTypesReducer = itemTypesSlice.reducer;

export { itemTypesReducer, fetchItemTypes };
