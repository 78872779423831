import { useModelsState } from "hooks/useCarsModelState";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchModels } from "store/slices/modelSlice";

export const useLoadListingModels = () => {
  const { fetchItemsStatus } = useModelsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchItemsStatus === 'IDLE') {
      dispatch(fetchModels())
    }
  }, [])
  
}