import { TimeRage } from 'components/HeroSearchForm/FlightSearchForm';
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import { DateRage } from 'components/HeroSearchForm/StaySearchForm';
import useWindowSize from 'hooks/useWindowResize';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Item } from 'types/Item/Item';
import { Link } from 'react-router-dom';
import { IReservation } from 'types/Reservation';
import moment from 'moment';
import DeliveryPointSelector from './DeliveryPointSelector';
import { Dispatch, SetStateAction } from 'react';
import { IPointDeliveryCanton } from 'types/PointDeliveryCanton';
import React, { useState } from 'react';
import clsx from 'clsx';
import { AiOutlineCaretDown } from 'react-icons/ai';
interface SidebarPriceSectionProps {
  dateRangeValue: DateRage;
  timeRangeValue: TimeRage;
  setDateRangeValue: (date: any) => void;
  setTimeRangeValue: (date: any) => void;
  handleSubmitReservation: () => void;
  currentItem?: Item;
  subtotal: number;
  daysPrice: number;
  differenceDays: number;
  isReserved: boolean;
  handleCheckoutModal: Function;
  reservationData: IReservation;
  taxes: number;
  setDeliveryPointCanton: Dispatch<
    SetStateAction<IPointDeliveryCanton | null | undefined>
  >;
  setDeliveryStartingPointCanton: Dispatch<
    SetStateAction<IPointDeliveryCanton | null | undefined>
  >;
  isMinimum: boolean;
}

function SidebarPriceSection({
  currentItem,
  dateRangeValue,
  timeRangeValue,
  setDateRangeValue,
  setTimeRangeValue,
  subtotal,
  daysPrice,
  differenceDays,
  handleSubmitReservation,
  handleCheckoutModal,
  isReserved,
  reservationData,
  taxes,
  setDeliveryPointCanton,
  setDeliveryStartingPointCanton,
  isMinimum,
}: SidebarPriceSectionProps) {
  const windowSize = useWindowSize();
  //isMinimum = true;
  const { t } = useTranslation();
  const [accordionVisible, setAccordionVisility] = useState(false);
  return (
    <div className="listingSectionSidebar__wrap shadow-xl">
      {/* <div className="flex justify-between">
        <span className="text-3xl font-semibold">
          ${"price"}{" "}
          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            {" "}
            / {t("day").toLocaleLowerCase()}{" "}
          </span>
        </span>
      </div> */}

      <div className="flex flex-col space-y-1 xl:space-y-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>
            {isNaN(differenceDays) ? 0 : differenceDays}{' '}
            {t('pluralDay').toLocaleLowerCase()}
          </span>
          <span>${isNaN(daysPrice) ? '0' : daysPrice}</span>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700 "></div>
        <DeliveryPointSelector
          setDeliveryPointCanton={setDeliveryStartingPointCanton}
        ></DeliveryPointSelector>
        <DeliveryPointSelector
          setDeliveryPointCanton={setDeliveryPointCanton}
        ></DeliveryPointSelector>
        <div className="max-h-[500px] overflow-y-auto">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <b>{t('additional-charges')}</b>
            <span>
              ${isNaN(subtotal - daysPrice) ? 0 : subtotal - daysPrice}
            </span>
          </div>
          <div
            className={clsx(
              'transition-h ease-in-out duration-1000 overflow-hidden h-0 pl-3',
              accordionVisible ? 'h-60' : ''
            )}
            id="accordion-container"
          >
            {accordionVisible && (
              <div>
                <div>
                  {reservationData?.amenities.length > 0 && (
                    <b>{t('amenities')}</b>
                  )}
                  {reservationData?.amenities.map((amenitie) => (
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>* {amenitie.amenitie.detail_amenities}</span>
                      <span>
                        $
                        {amenitie.price *
                          (amenitie.type == 'DAILY' ? differenceDays : 1)}
                      </span>
                    </div>
                  ))}
                </div>
                <div>
                  {reservationData?.conditions.length > 0 && (
                    <b>{t('conditions')}</b>
                  )}
                  {reservationData?.conditions.map((condition) => (
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>* {condition.condition.detail_conditions}</span>
                      <span>
                        $
                        {condition.price *
                          (condition.type == 'DAILY' ? differenceDays : 1)}
                      </span>
                    </div>
                  ))}
                </div>
                <div>
                  {reservationData?.insurances && (
                    <b>
                      {t('insurances')} x {differenceDays}{' '}
                      {t('pluralDay').toLowerCase()}
                    </b>
                  )}
                  {
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>* {reservationData.insurances?.name}</span>
                      <span>
                        $
                        {(reservationData.insurances?.price || 0) *
                          differenceDays}
                      </span>
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full justify-center border-2 border-transparent hover:border-primary-500 rounded-xl">
            <button
              onClick={(e) => setAccordionVisility(!accordionVisible)}
              className="flex flex-col items-center"
              aria-expanded={accordionVisible}
              aria-controls="accordion-container"
            >
              <AiOutlineCaretDown
                height={20}
                className={clsx(accordionVisible ? 'rotate-180' : '')}
              />
              <p className="text-sm">{t('show-charges')}</p>
            </button>
          </div>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibold">
          <span>Subtotal</span>
          <span>${isNaN(subtotal) ? '' : subtotal}</span>
        </div>
        <div className="flex justify-between font-semibold">
          <span>{t('taxes')}</span>
          <span>${isNaN(taxes) ? '' : taxes.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-semibold">
          <span>Total</span>
          <span>
            ${isNaN(subtotal + taxes) ? '' : (subtotal + taxes).toFixed(2)}
          </span>
        </div>
      </div>
      {(isReserved ? isReserved : isNaN(1) ? true : false) ? (
        <ButtonPrimary disabled={true}> {t('rent')}</ButtonPrimary>
      ) : (isMinimum ? isMinimum : isNaN(1) ? true : false) ? (
        <ButtonPrimary disabled={true}> {t('rent')}</ButtonPrimary>
      ) : (
        <Link
          to={{
            pathname: '/checkout',
            search: `?vehicle_id=${currentItem?.id_item}`,
            state: {
              hour: '18:00',
              subtotal: subtotal,
              total: subtotal + taxes,
              taxes: taxes,
              days: differenceDays,
              dayPrice: daysPrice / differenceDays,
              startDate: moment(dateRangeValue.startDate).format('DD/MM/YYYY'),
              endDate: moment(dateRangeValue.endDate).format('DD/MM/YYYY'),
              reservationData: reservationData,
            },
          }}
        >
          <ButtonPrimary className="w-full" disabled={false}>
            {t('rent')}
          </ButtonPrimary>
        </Link>
      )}
    </div>
  );
}

export default SidebarPriceSection;
