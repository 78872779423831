import { useFormContext, SubmitHandler } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FormControl } from "components/FormControl";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import { useTranslation } from "react-i18next";
import FormSectionWrapper from "../FormSectionWrapper";

interface IListCarAvailabilityForm {}

interface CarAvailabilityProps {
  active?: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  index: number;
}

export function CarAvailability({
  active,
  currentStep,
  setCurrentStep,
  index,
}: CarAvailabilityProps) {
  let methods = useFormContext();

  let { t } = useTranslation();

  const onSubmit = (data: any) => {
    // next();
    setCurrentStep(index + 1)
  };

  return (
    <FormSectionWrapper
      currentStep={currentStep}
      index={index}
      setCurrentStep={setCurrentStep}
      title={t(`car-availability`)}
      active={active}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>

        <div className="mb-6">
          <label htmlFor="">Advance Notice at Home</label>
          <Select className="mt-2" name="advance_notice_at_home" defaultValue={"1"}>
            <option value="1">1 day (Recommended)</option>
            <option value="2">2 days</option>
            <option value="3">3 days</option>
            <option value="4">4 days</option>
            <option value="5">5 days</option>
          </Select>
        </div>

        <div className="mb-6">
          <label htmlFor="">Minimum Trip Duration</label>
          <Select className="mt-2" name="minimum_trip_duration" defaultValue={"1"}>
            <option value="1">1 day (Recommended)</option>
            <option value="2">2 days</option>
          </Select>
        </div>

        <FormControl id="car_model" name="car_model" className="mb-6">
          {(field: any) => (
            <Checkbox
              label={
                "Require a 2-day minimum for trips that start Friday or Saturday"
              }
              {...field}
            />
          )}
        </FormControl>

        <ButtonPrimary type="submit">Next</ButtonPrimary>
      </form>
    </FormSectionWrapper>
  );
}
