import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import Page404 from 'containers/Page404/Page404';
import { ListingCarMapPage, ListingCarPage } from 'containers/ListingCarPages';
import ListingCarDetailPage from 'containers/ListingDetailPage/ListingCarDetailPage';
import SiteHeader from 'containers/SiteHeader';
import { SignUpPage } from 'containers/SignUpPage';
import { LoginPage } from 'containers/LoginPage';
import { StripeSuccesfulPage } from 'containers/StripeSuccesfulPage';
import { ReservationCheckout } from 'containers/ReservationCheckout';
import { ListYourCarPage } from 'containers/ListYourCarPage';
import {
  AccountSettings,
  AccountRents,
  AccountVehicles,
  AccountReviews,
} from 'containers/AccountPage';
import ContacPage from 'containers/ContactPage/ContactPage';
import WhatsappButton from 'shared/WhatsappButton/WhatsappButton';

export const pages: Page[] = [
  { path: '/', exact: true, component: ListingCarPage },
  { path: '/signup', exact: true, component: SignUpPage },
  { path: '/login', exact: true, component: LoginPage },
  { path: '/list-your-car', exact: true, component: ListYourCarPage },
  { path: '/contact', exact: true, component: ContacPage },
  { path: '/listing-car-map', component: ListingCarMapPage },
  { path: '/listing-car-detail', component: ListingCarDetailPage },
  { path: '/checkout', component: ReservationCheckout },
  { path: '/stripe/sucessful-payment', component: StripeSuccesfulPage },
  { path: '/account', exact: true, component: AccountSettings },
  { path: '/account/rents', exact: true, component: AccountRents },
  { path: '/account/vehicles', exact: true, component: AccountVehicles },
  { path: '/account/reviews', exact: true, component: AccountReviews },
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
      <WhatsappButton />
    </BrowserRouter>
  );
};

export default Routes;
