import { useFormContext } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FormControl } from "components/FormControl";
import Select from "shared/Select/Select";
import { useTranslation } from "react-i18next";
import Textarea from "shared/Textarea/Textarea";
import Input from "shared/Input/Input";
import { IAmenity } from "types/Amenity";
import { ICondition } from "types/Condition";
import { IInsurance } from "types/Insurance";
import { ICarEquipment } from "types/CarEquipment";
import Checkbox from "shared/Checkbox/Checkbox";
import FormSectionWrapper from "../FormSectionWrapper";
import ImageUploadPreview from "components/ImagePreview/ImagePreview";
import { requiredRule } from "helpers/formRulesHelper";
import { ICountry } from "types/Country";
import { CarListingSubmissionRequestBody, storeItemImages } from "api/itemsApi";
import { createItem } from "api/itemsApi";
import api from "api/api";

interface IListCarDetailsForm {}

interface CarDetailsProps {
  active?: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  currentStep: number;
  amenities?: IAmenity[];
  conditions?: ICondition[];
  insurances?: IInsurance[];
  carEquipment?: ICarEquipment[];
  countries?: ICountry[];
}

export function CarDetails({
  active,
  setCurrentStep,
  index,
  currentStep,
  amenities,
  conditions,
  insurances,
  carEquipment,
  countries,
}: CarDetailsProps) {
  let methods = useFormContext();
  let { t } = useTranslation();

  function getSelectedBonuses<BonusType>(
    itemBonuses: BonusType[] | undefined,
    checkedOptions: Boolean[]
  ) {
    if (itemBonuses) {
      return itemBonuses
        .map((itemBonus: any, index) => {
          let isItemBonusSelected = checkedOptions[index];
          if(isItemBonusSelected){
            return {
              id: index + 1,
              priceInput: 0,
              type: "FIXED",
              isRequired: true,
            };
          }
        }).filter((itemBonus: any) => itemBonus !== undefined)
    } 
  }

  const handleItemCreation = async () => {
    try {
      let amenitiesCheckboxes = methods.getValues("amenities");
      let selectedAmenities = getSelectedBonuses<IAmenity>(amenities, amenitiesCheckboxes);
      
      let conditionsCheckboxes = methods.getValues("conditions");
      let selectedConditions = getSelectedBonuses<ICondition>(conditions, conditionsCheckboxes);
      
      let insurancesCheckboxes = methods.getValues("insurances");
      let selectedInsurances = getSelectedBonuses<IInsurance>(insurances, insurancesCheckboxes);

      let equipmentCheckboxes = methods.getValues("car_equipment");
      let selectedEquipment = getSelectedBonuses<ICarEquipment>(carEquipment, equipmentCheckboxes);

      let itemRequestObject: CarListingSubmissionRequestBody = {
        amenities: selectedAmenities,
        conditions: selectedConditions,
        insurances: selectedInsurances,
        equipment:  selectedEquipment,

        province_location: 1,
        canton_location: 1,
        district_location: 1,
        detail_location: methods.getValues("car_location"),
        latitude: "20.0000000", // Implement map on form
        longitude: "60.0000000", // Implement map on form

        id_item_type: 1,
        id_owner: 1,

        amount_item: methods.getValues("car_price"),
        available_item: true,
        brand_item: "Mercedes", // Need to record a task for this
        code_item: Math.floor(Math.random() * 100000).toString(),
        description: methods.getValues("car_description"),
        detail_item: methods.getValues("car_item_title"),
        tax_item: methods.getValues("car_price") * .13,
        year_item: methods.getValues("car_year"), // Must change for select

        doors_number: methods.getValues("car_doors"),
        external_color: methods.getValues("car_external_color"), 
        internal_color: methods.getValues("car_internal_color"),
        max_passengers: methods.getValues("car_max_passengers"), 
        mileage: methods.getValues("car_mileage"),
        transmission: methods.getValues('transmission'),
      }

      let response = await createItem(itemRequestObject);
      
      if (!response.error) {
          // Post Images
        let files: any = methods.getValues("car_picture");
        let imagesFormData = new FormData();

        imagesFormData.append('code', '1234');
        imagesFormData.append('id_item', '31');
        imagesFormData.append('images', files[0]);

        let imageUploadRequest = await storeItemImages(imagesFormData)

        if(imageUploadRequest.message === "Done"){
            setCurrentStep(index + 1);
        }
      }  

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormSectionWrapper
      currentStep={currentStep}
      index={index}
      setCurrentStep={setCurrentStep}
      title={t(`car-details`)}
      active={active}
    >
      <form onSubmit={methods.handleSubmit(handleItemCreation)}>
        <FormControl
          id="car_picture"
          name="car_picture"
          label={t(`car-picture`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <ImageUploadPreview {...field} />}
        </FormControl>

        <FormControl
          id="license_plate_number"
          name="license_plate_number"
          label={t(`license-plate-number`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"text"} {...field} />}
        </FormControl>

        <FormControl
          id="car_external_color"
          name="car_external_color"
          label={t(`car-external-color`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"text"} {...field} />}
        </FormControl>

        <FormControl
          id="car_internal_color"
          name="car_internal_color"
          label={t(`car-internal-color`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"text"} {...field} />}
        </FormControl>

        <FormControl
          id="car_price"
          name="car_price"
          label={t(`car-price`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"number"} {...field} />}
        </FormControl>
        
        <FormControl
          id="car_max_passengers"
          name="car_max_passengers"
          label={t(`car-max-passengers`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"number"} {...field} />}
        </FormControl>
        
        <FormControl
          id="car_mileage"
          name="car_mileage"
          label={t(`car-mileage`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"number"} {...field} />}
        </FormControl>

        <FormControl
          id="car_seats"
          name="car_seats"
          label={t(`car-seats-number`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"number"} {...field} />}
        </FormControl>

        <FormControl
          id="car_doors"
          name="car_doors"
          label={t(`car-doors-number`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"number"} {...field} />}
        </FormControl>

        <FormControl
          id="car_year"
          name="car_year"
          label={t(`car-year`)}
          className="mb-6"
          registerOptions={{
            required: requiredRule(t("requiredField")),
          }}
        >
          {(field: any) => <Input type={"number"} {...field} />}
        </FormControl>

        <div className="mb-6">
          <label htmlFor="">Country</label>
          <Select className="mt-2" name="country" defaultValue={"1"}>
            {countries?.map((country) => (
              <option
                key={`list-country-${country.id_pais}`}
                value={country.id_pais}
              >
                {country.nombre_pais}
              </option>
            ))}
          </Select>
        </div>

        <FormControl
          id="car_description"
          name="car_description"
          label={t("car_description")}
          className="mb-6"
        >
          {(field: any) => (
            <Textarea
              {...field}
              placeholder={
                "Ex: Beautiful Car with amazing sound, clean and in perfect conditions for your tour!"
              }
            />
          )}
        </FormControl>

        <div className="grid grid-cols-3 mb-6">
          <div>
            <label className="font-bold" htmlFor="">
              Amenities
            </label>
            {amenities?.map((amenitie, index) => (
              <FormControl
                key={`list-your-car-amenitie-${index}`}
                id={`list-your-car-amenitie-${index}`}
                name={`amenities.${index}`} // Need to conver this text into underscore
              >
                {(field: any) => (
                  <Checkbox
                    onChange={console.log}
                    label={amenitie.detail_amenities}
                    {...field}
                  />
                )}
              </FormControl>
            ))}
          </div>
          <div>
            <label className="font-bold" htmlFor="">
              Conditions
            </label>
            {conditions?.map((condition, index) => (
              <FormControl
                key={`list-your-car-condition-${index}`}
                id={`list-your-car-condition-${index}`}
                name={`conditions.${index}`} // Need to conver this text into underscore
              >
                {(field: any) => (
                  <Checkbox label={condition.detail_conditions} {...field} />
                )}
              </FormControl>
            ))}
          </div>
          <div>
            <label className="font-bold" htmlFor="">
              Insurances
            </label>
            {insurances?.map((insurance, index) => (
              <FormControl
                key={`list-your-car-insurance-${index}`}
                id={`list-your-car-insurance-${index}`}
                name={`insurances.${index}`} // Need to conver this text into underscore
              >
                {(field: any) => (
                  <Checkbox label={insurance.detail_insurance} {...field} />
                )}
              </FormControl>
            ))}
          </div>
        </div>

        <label className="font-bold" htmlFor="">
          Car Equipment
        </label>
        <div className="grid grid-cols-3 mb-6">
          {carEquipment?.map((equipment, index) => (
            <FormControl
              key={`list-your-car-equipment-${index}`}
              id={`list-your-car-equipment-${index}`}
              name={`car_equipment.${index}`} // Need to conver this text into underscore
            >
              {(field: any) => (
                <Checkbox label={equipment.car_equipment_detail} {...field} />
              )}
            </FormControl>
          ))}
        </div>

        <ButtonPrimary type="submit">Next</ButtonPrimary>
      </form>
    </FormSectionWrapper>
  );
}
