import { useCarsState } from "hooks/useCarsState";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchItems } from "store/slices/carsSlice";

export const UseLoadListingCarPage = () => {
  const { fetchItemsStatus } = useCarsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchItemsStatus === 'IDLE') {
      dispatch(fetchItems())
    }
  }, [])
  
}

export const useLoadListingCarPage = () => {
  const { fetchItemsStatus } = useCarsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchItemsStatus === 'IDLE') {
      dispatch(fetchItems())
    }
  }, [])
  
}