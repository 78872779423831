import { CarColor } from "types/Item/Item";
import api from "./api";

const API_ENDPOINT = "/colors";

export interface ItemsResponse {
  message: string;
  error: boolean;
  colors?: CarColor[];
}


const getColors = async () => {
  return (await api.get<ItemsResponse>(API_ENDPOINT)).data;
};

export { getColors };
