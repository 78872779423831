import { FC, useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { LandingSection } from "components/LandingSection";
import SectionGridFilterCard from "./components/SectionGridFilterCard";
import { BackgroundSection } from "components/BackgroundSection";
import { SectionSlider } from "components/SectionSlider";
import { useTranslation } from "react-i18next";
import { SearchCarsProvider } from "./context/SearchCarsContext";
import { useLoadListingCarPage } from "./hooks/useLoadListingCarPage";
import { useAppSelector } from "store/hooks/useAppStore";
import { carsSelector } from "store/slices/carsSlice";
import { TaxonomyType } from "data/types";
import { env } from "env";
import moment from "moment";
import RecomendationPage from "containers/RecomendationsPage/RecomendationPage";

export interface ListingCarPageProps {
  className?: string;
}
const ListingCarPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState<any>(null);

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>Summer Rent A Car</title>
      </Helmet>

      <div className=" relative">
        <SearchCarsProvider>
          <LandingSection
            currentTab="landing-searchTab-multipleDays"
            className="pb-24 lg:pb-16 w-11/12"
            setSearchData={setSearchData}
          />

          <SectionGridFilterCard className="container pb-24" />
        </SearchCarsProvider>

        <div className="container relative py-12 mb-12 ">
          <RecomendationPage />
        </div>
      </div>
    </div>
  );
};

export default ListingCarPage;
