import { CarModel } from "types/Item/Item";
import api from "./api";

const API_ENDPOINT = "/models";

export interface ItemsResponse {
  message: string;
  error: boolean;
  models?: CarModel[];
}


const getModels = async () => {
  return (await api.get<ItemsResponse>(API_ENDPOINT)).data;
};

export { getModels };
