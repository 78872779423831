import { FC, FormEventHandler, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout"
import Input from "shared/Input/Input";
import { t } from "i18next";
import { ToastContainer, toast } from "react-toastify";
import api from "api/api";
import { getCustomerData } from "../../api/customerApi";
import PaymentModal from "components/PaymentModal/PaymentModal";
import PaymentModalAlt from "components/PaymentModal/PaymentModalAlt";

export default function AccountRents(){
    const [customer, setCustomerData] = useState<any | null>(JSON.parse(localStorage.getItem('customer') || 'null'));
    const [loading, setLoading] = useState<boolean | null>(null);
    const [rents, setRents] = useState<any | null>(customer.reservations);
    const [idReservationEdit, setIdReservationEdit] = useState<number | null>();
    const [endDateReservation, setEndDateReservation] = useState<string>('');

    const [completedPayment, setCompletedPayment] = useState<boolean>(false);
    const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
    const [priceExtend, setPriceExtend] = useState<number>(0);
    
    const dateFormat = (date: string) => {
        const dateObj = new Date(date);
        const month = dateObj.getMonth() + 1;
        const day   = dateObj.getDate();
        return `${dateObj.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;  
    }

    const handleDateInput = (e: any) => {
        setEndDateReservation(e.target.value);
    }

    const handleEdit = (id: number, endDate: string) : void => {
        setIdReservationEdit(id);
        setEndDateReservation(dateFormat(endDate));
    }

    const handleSuccess = async () => {
        try {
            setLoading(true);
            
            const response = await api.post(`/reservation-extend-price`, {
                id_reservation: idReservationEdit,
                end_date: endDateReservation
            });

            const data_ : any = response.data;
            if(data_.error)
                throw new Error(data_?.message);
            else{
                const new_total_reservation = parseFloat(data_.new_total_reservation);
                setPriceExtend(new_total_reservation);
                setOpenPaymentModal(true);
            }
        } catch (error: any) {
          toast.error('Error: ' + error.message);
          setOpenPaymentModal(false);
        } finally {
            setLoading(false);
        }
    }

    const extendRent = async () => {
        
        try {
            setLoading(true);
            const response = await api.post(`/reservation-extend`, {
                id_reservation: idReservationEdit,
                end_date: endDateReservation,
                new_total_reservation: priceExtend
            });
            const data_ : any = response.data;
            if(data_.error)
                throw new Error(data_?.message);
            else
                toast.success(data_?.message);

            getCustomer();
            setOpenPaymentModal(true);
        } catch (error: any) {
          toast.error('Error: ' + error.message);
        } finally {
            setOpenPaymentModal(false);
            setCompletedPayment(false);
            setIdReservationEdit(null);
            setLoading(false);
        }
    }

    const handleCancel = () : void => {
        setIdReservationEdit(null);
    }

    const getCustomer = async () => {
        setLoading(true);
        const data = await getCustomerData(customer.id_customer);
        const customer_ = data?.customer || null;
        setRents(customer_?.reservations || []);
        setLoading(false);
    }

    useEffect(() => {
        if(completedPayment == true){
            extendRent();
        }
    }, [completedPayment]);

    useEffect(() => {
        getCustomer();
    }, []);

    return (
        <>
            <CommonLayout title="My Rents">
                <table className="table-auto w-full">
                    <thead>
                        <tr>
                        <th className="w-1/6 text-center align-middle">Fecha de inicio</th>
                        <th className="w-1/6 text-center align-middle">Fecha de fin</th>
                        <th className="w-1/6 text-center align-middle">Pasajeros</th>
                        <th className="w-1/6 text-center align-middle">Total</th>
                        <th className="w-1/6 text-center align-middle">Vehículo</th>
                        <th className="w-1/6 text-center align-middle">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        loading == true ?
                            <tr><td colSpan={6} className="text-center align-middle"> Cargando ...</td></tr> 
                        : (rents || []).map( (reservation : any) => (
                        <tr key={reservation.id_reservation}>
                            <td className="w-1/6 text-center align-middle">{dateFormat(reservation.init_reservation)}</td>
                            <td className="w-1/6 text-center align-middle">
                                {
                                    reservation.id_reservation == idReservationEdit 
                                        ? <Input type="date" onChange={handleDateInput} value={endDateReservation}/>
                                        : dateFormat(reservation.end_reservation)
                                    
                                }
                            </td>
                            <td className="w-1/6 text-center align-middle">{reservation.passengers_reservation}</td>
                            <td className="w-1/6 text-center align-middle">${reservation.total_all_reservation}</td>
                            <td className="w-1/6 text-center align-middle">{reservation.vehicle}</td>
                            <td className="w-1/6 text-center align-middle">
                                {
                                    idReservationEdit == reservation.id_reservation
                                        ?
                                        <>
                                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={() => {handleSuccess();}}>
                                                &#10003;
                                            </button>
                                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 ml-1 rounded" onClick={() => {handleCancel();}}>
                                                &#10005;
                                            </button>
                                        </>
                                        :
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleEdit(reservation.id_reservation, reservation.end_reservation)}>
                                            Extender
                                        </button>
                                }
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </CommonLayout>

            <PaymentModalAlt
                amount={priceExtend}
                isOpen={openPaymentModal}
                onCloseModal={() => setOpenPaymentModal(false)}
                country={33}
                setCompletedPayment={setCompletedPayment}
            />
        </>
    )
}