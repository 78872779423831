import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Select, { MultiValue } from 'react-select';
import { Option } from 'containers/ListingCarPages/context/SearchCarsContext';
import { useLoadListingBrands } from 'containers/ListingCarPages/hooks/useLoadBrandListing';
import { useAppSelector } from 'store/hooks/useAppStore';
import { brandsSelector } from 'store/slices/brandSlice';
import { useLoadListingModels } from 'containers/ListingCarPages/hooks/useLoadModelListing';
import { modelSelector } from 'store/slices/modelSlice';
import { CarModel } from 'types/Item/Item';

interface Props {}

const BrandSelector = (props: Props) => {
  useLoadListingBrands();
  useLoadListingModels();
  const data = useAppSelector(brandsSelector.selectAll);
  const dataModels = useAppSelector(modelSelector.selectAll);
  const { t } = useTranslation();
  const { setSearchFilters } = useSearchCarsContext();

  const brands = () => {
    let brands = (data || []).map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));

    brands.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    return brands;
  };

  const models = () => {
    let models = (dataModels || []).map((model) => ({
      value: model.id,
      label: model.name,
      brand: model.id_brand,
    }));

    models.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    return models;
  };

  const [error, setError] = useState(false);

  const [localModels, setLocalModels] = useState<MultiValue<Option>>([]);
  const [localBrands, setLocalBrands] = useState<MultiValue<Option>>([]);
  const [localModelsFilter, setLocalModelsFilter] = useState<any[]>([]);

  const applyBrandSelection = () => {
    setError(false);
    setSearchFilters((prev) => ({
      ...prev,
      brands: localBrands,
      models: localModels,
    }));
  };

  useEffect(() => {
    setLocalModelsFilter(
      models().filter((model) =>
        localBrands.some((brand) => parseInt(brand.value) == model.brand)
      )
    );
  }, [localBrands]);

  return (
    <>
      <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">{t('searchFilterModal-brand')}</span>

            <Select
              options={brands()}
              isMulti
              name="cities"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(brands: any) => setLocalBrands(brands)}
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">{t('searchFilterModal-model')}</span>

            <Select
              options={localModelsFilter}
              isMulti
              name="cities"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(models: any) => setLocalModels(models)}
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">
              {t('invalid-search')}
            </strong>
          )}
          <ButtonPrimary
            onClick={applyBrandSelection}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t('apply')}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default BrandSelector;
