//Creame un componente que devuelva un hola mundo en html
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLoadPointDeliveryCantons } from './hooks/useLoadPointDeliveryCantons';
import { useAppSelector } from 'store/hooks/useAppStore';
import { IPointDeliveryCanton } from 'types/PointDeliveryCanton';
import { useTranslation } from 'react-i18next';

interface DeliveryPointSelectorProps {
  setDeliveryPointCanton: Dispatch<
    SetStateAction<IPointDeliveryCanton | null | undefined>
  >;
}

const DeliveryPointSelector = ({
  setDeliveryPointCanton,
}: DeliveryPointSelectorProps) => {
  const { t } = useTranslation();
  const start_provinces = [{ id: 2, name: 'Alajuela' }];

  const start_cantons = [{ id: 1, name: 'Alajuela', province_id: 2 }];

  const [startIdProvinceSelected, setStartIdProvinceSelected] = useState(2);
  const [startIdCantonSelected, setStartIdCantonSelected] = useState(1);
  const [localDeliveryPointCanton, setLocalDeliveryPointCanton] =
    useState<IPointDeliveryCanton | null>();

  const handleStartProvinceChange = (event: { target: { value: any } }) => {
    const idProvinceSelected = event.target.value;
    setStartIdProvinceSelected(idProvinceSelected);
  };

  const handleStartCantonChange = (event: { target: { value: any } }) => {
    const idCantonSelected = event.target.value;
    setStartIdCantonSelected(idCantonSelected);
  };

  useLoadPointDeliveryCantons();
  const { placeDeliveries = [] } = useAppSelector(
    (state) => state.pointDeliveryCantons
  );

  useEffect(() => {
    if (placeDeliveries.length > 0) {
      setLocalDeliveryPointCanton(
        placeDeliveries.find((place) => place.canton_name == 'Oficina') ??
          placeDeliveries[0]
      );
      setDeliveryPointCanton(
        placeDeliveries.find((place) => place.canton_name == 'Oficina') ??
          placeDeliveries[0]
      );
    }
  }, [placeDeliveries]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2">
        <label className="block font-medium mb-1">
          {t('delivery-location')}
        </label>
        <select
          className="form-select w-full"
          onChange={(event: { target: { value: any } }) => {
            const idCantonSelected = event.target.value;
            const pointDeliveryCanton = placeDeliveries.find(
              (placeDeliverie) =>
                placeDeliverie.canton_id === parseInt(idCantonSelected)
            );
            setLocalDeliveryPointCanton(pointDeliveryCanton);
            setDeliveryPointCanton(pointDeliveryCanton);
          }}
        >
          {placeDeliveries.map((placeDeliverie) => {
            return (
              <option
                key={placeDeliverie.canton_id}
                value={placeDeliverie.canton_id}
                selected={
                  (localDeliveryPointCanton?.canton_id || 0) ===
                  placeDeliverie.canton_id
                }
              >{`${placeDeliverie.province_name}/${placeDeliverie.canton_name}($${placeDeliverie.price})`}</option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default DeliveryPointSelector;
