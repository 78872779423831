import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcModal from 'shared/NcModal/NcModal';

interface Props {}
const FilterTerms = (props: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <NcModal
      contentPaddingClass="pb-12 pt-6 px-8"
      contentClass="flex flex-col gap-12"
      modalTitle={t('TermsAndConditions')}
      onCloseModal={() => setIsOpen(false)}
      isOpenProp={isOpen}
      renderTrigger={() => (
        <ButtonPrimary
          type="button"
          onClick={() => setIsOpen(true)}
          sizeClass="px-4 py-2 sm:px-5"
        >
          {t('TermsAndConditions')}
        </ButtonPrimary>
      )}
    >
      <section className="w-full h-full flex flex-col gap-4 ">
        <div className=" selection-area">
          <span className="text-neutral-500 sm:text-sm justify-tex">
            <p>
              <b>Política de Uso de la Página Web de Alquiler de Autos</b>
            </p>
            <p className="justify-text">
              Bienvenido/a a nuestra página web de alquiler de autos
              www.rentacarsummer.com. Para garantizar una experiencia segura,
              confiable y satisfactoria para todos nuestros usuarios, hemos
              establecido las siguientes políticas de uso. Al acceder y utilizar
              nuestro sitio web, aceptas cumplir con estas normas. Te
              recomendamos leerlas detenidamente antes de continuar:
            </p>
            <ol>
              <li className="justify-text">
                {' '}
                1. Uso adecuado: Nuestra página web está destinada únicamente
                para el alquiler de autos y servicios relacionados. No se
                permite utilizar el sitio para otros fines o actividades
                ilícitas.
              </li>
              <li className="justify-text">
                {' '}
                2. Veracidad de la información: Al proporcionar datos
                personales, asegúrate de que sean precisos, actualizados y
                veraces. No está permitido el uso de información falsa o
                fraudulenta.
              </li>
              <li className="justify-text">
                {' '}
                3. Responsabilidad del usuario: Eres responsable de mantener la
                confidencialidad de tu cuenta y contraseña. Cualquier actividad
                que ocurra bajo tu cuenta será tu responsabilidad.
              </li>
              <li className="justify-text">
                {' '}
                4. Uso de contenido: El contenido de nuestra página web,
                incluyendo imágenes, textos y logotipos, está protegido por
                derechos de autor y propiedad intelectual. No está permitido
                copiar, distribuir, modificar o utilizar dicho contenido sin
                permiso explícito.
              </li>
              <li className="justify-text">
                {' '}
                5. Comentarios y valoraciones: Los comentarios y valoraciones
                que dejes en nuestra plataforma deben ser constructivos,
                respetuosos y basados en experiencias reales. Nos reservamos el
                derecho de eliminar o moderar cualquier contenido inapropiado.{' '}
              </li>
              <li className="justify-text">
                {' '}
                6. Prohibición de actividades ilegales: No se permite el uso de
                nuestra página web para llevar a cabo actividades ilegales, como
                la promoción de conductas fraudulentas, difamatorias, ofensivas
                o que infrinjan los derechos de terceros.
              </li>
              <li className="justify-text">
                {' '}
                7. Seguridad informática: Está prohibido intentar acceder de
                forma no autorizada a nuestros sistemas o realizar acciones que
                puedan afectar la seguridad o integridad del sitio web.
              </li>
              <li className="justify-text">
                {' '}
                8. Respeto a la privacidad: Respeta la privacidad de otros
                usuarios y no compartas información personal sin su
                consentimiento.{' '}
              </li>
              <li className="justify-text">
                {' '}
                9. Política de cancelación: Si deseas cancelar un alquiler,
                sigue las pautas y plazos establecidos en nuestro sitio web. Se
                pueden aplicar cargos por cancelaciones tardías.
              </li>
              <li className="justify-text">
                {' '}
                10. Las cancelaciones de las rentas de autos tiene costos
                asociados a comisiones de bancos y otros por lo que la
                devolución del dinero no será el mismo monto cancelad, tendrá
                una devolución de %20 menos del monto cancelado.{' '}
              </li>
              <li className="justify-text">
                {' '}
                11. En los casos de cancelación con menos de una semana no habrá
                reembolso en ninguno de los casos
              </li>
              <li className="justify-text">
                {' '}
                12. Respeto a los términos y condiciones: Cumple con los
                términos y condiciones generales de uso de la página web, así
                como con cualquier política específica relacionada con el
                alquiler de autos.
              </li>
              <li className="justify-text">
                {' '}
                13. Modificaciones en las políticas: Nos reservamos el derecho
                de modificar estas políticas en cualquier momento. Te
                recomendamos revisarlas periódicamente para estar al tanto de
                cualquier cambio.
              </li>
              <li className="justify-text">
                {' '}
                14. Uso responsable de los vehículos: Al alquilar un auto a
                través de nuestra plataforma, se espera que lo utilices de
                manera responsable, cumpliendo con las normas de tránsito y
                seguridad vial.
              </li>
              <li className="justify-text">
                {' '}
                15. Asistencia y soporte: Si necesitas ayuda o tienes alguna
                pregunta, no dudes en comunicarte con nuestro equipo de soporte.
                Estaremos encantados de ayudarte.
              </li>
            </ol>
            <p className="justify-text">
              El incumplimiento de estas políticas podría dar lugar a la
              suspensión o cancelación de tu cuenta, así como a otras medidas
              legales según corresponda. Gracias por utilizar nuestra página
              www.rentacarsummer.com. Esperamos que disfrutes de nuestros
              servicios y tengas una experiencia satisfactoria.
            </p>
            <br />
            <p>
              <b>
                Política de Privacidad de la Página Web de Alquiler de Autos
              </b>
            </p>
            <p className="justify-text">
              En Summer Rent a CAr, valoramos y respetamos tu privacidad. Esta
              política de privacidad describe cómo recopilamos, utilizamos,
              almacenamos y protegemos la información personal que nos
              proporcionas al utilizar nuestra página web
              www.rentacarsummer.com. Al acceder y utilizar nuestros servicios,
              aceptas las prácticas descritas en esta política de privacidad. Te
              recomendamos leerla detenidamente antes de continuar:
            </p>

            <ol>
              <li className="justify-text">1. Información que recopilamos:</li>
              <p>
                • Información de contacto: Recopilamos tu nombre, dirección de
                correo electrónico, número de teléfono y otra información de
                contacto similar cuando te registras en nuestro sitio web o
                realizas una reserva
              </p>
              <p>
                • Información del vehículo: Para procesar tu solicitud de
                alquiler, podemos solicitar información sobre la categoría o
                características del vehículo que deseas alquilar.
              </p>
              <p>
                • Información de pago: Cuando realices una reserva,
                recopilaremos la información necesaria para procesar el pago,
                como datos de tarjetas de crédito o de otros métodos de pago.
              </p>
              <p>
                • Información del dispositivo y navegación: Podemos recopilar
                información sobre tu dispositivo, dirección IP, tipo de
                navegador, páginas visitadas y otros datos de navegación
                mientras utilizas nuestra página web.
              </p>
              <li className="justify-text">
                {' '}
                2. Uso de la información: Utilizamos la información recopilada
                para los siguientes propósitos:
              </li>
              <p>• Procesar y confirmar tus reservas de alquiler de autos.</p>
              <p>
                • Facilitar la comunicación contigo respecto a tus reservas y
                proporcionarte asistencia al cliente.
              </p>
              <p>
                • Mejorar y personalizar tu experiencia en nuestro sitio web.
              </p>
              <p>
                • Enviar correos electrónicos promocionales y comunicaciones de
                marketing si has dado tu consentimiento para recibirlos.
              </p>
              <p>
                • Cumplir con las leyes y regulaciones aplicables y proteger
                nuestros derechos legales.
              </p>
              <li className="justify-text"> 3. Compartir información</li>
              <p>
                • No vendemos, alquilamos ni compartimos tu información personal
                con terceros no afiliados, excepto cuando sea necesario para
                proporcionar nuestros servicios o cuando lo exijan las leyes y
                regulaciones aplicables.
              </p>
              <p>
                • Podemos compartir tu información con empresas asociadas,
                proveedores de servicios y socios de negocios que trabajen en
                nuestro nombre para ofrecerte nuestros servicios. Estas empresas
                solo tienen acceso a la información necesaria para llevar a cabo
                sus funciones y deben mantener la confidencialidad de los datos.
              </p>
              <p>
                • Siempre que sea requerido por la ley o si creemos de buena fe
                que dicha divulgación es necesaria para proteger nuestros
                derechos, seguridad o propiedad, cooperaremos con las
                autoridades competentes.
              </p>
              <li className="justify-text">
                4. Seguridad de la información: Implementamos medidas de
                seguridad para proteger tu información personal contra el acceso
                no autorizado, la divulgación o modificación. Sin embargo,
                ningún método de transmisión por Internet o sistema de
                almacenamiento es completamente seguro. No podemos garantizar la
                seguridad absoluta de tus datos personales.
              </li>
              <li className="justify-text">
                5. Cookies y tecnologías similares: Utilizamos cookies y
                tecnologías similares para mejorar la funcionalidad de nuestro
                sitio web, recopilar datos estadísticos y recordar tus
                preferencias. Puedes configurar tu navegador para rechazar
                cookies, pero esto puede afectar la funcionalidad del sitio.
              </li>
              <li className="justify-text">
                6. Menores de edad: Nuestros servicios no están dirigidos a
                menores de 18 años. No recopilamos de manera consciente
                información personal de menores. Si eres padre/madre o tutor y
                crees que un menor nos ha proporcionado información personal,
                contáctanos para eliminarla de nuestros registros.
              </li>
              <li className="justify-text">
                7. Enlaces a otros sitios web: Nuestro sitio web puede contener
                enlaces a sitios web de terceros. No somos responsables de las
                prácticas de privacidad de estos sitios y te recomendamos
                revisar sus políticas de privacidad antes de proporcionarles
                información personal.
              </li>
              <li className="justify-text">
                8. Cambios en la política de privacidad: Nos reservamos el
                derecho de modificar esta política de privacidad en cualquier
                momento. Cualquier cambio será efectivo desde el momento de su
                publicación en nuestro sitio web.
              </li>
            </ol>

            <p className="justify-text">
              Si tienes alguna pregunta o inquietud sobre nuestra política de
              privacidad, no dudes en contactarnos a través de los canales de
              soporte proporcionados en el sitio web. Fecha de última
              actualización: 20/07/2023
            </p>
            <br />
            <p>
              <b>Política de Devolución de Dinero por Renta de Vehículos</b>
            </p>
            <p className="justify-text">
              En Summer Rent a car, nos esforzamos por brindar un servicio de
              alquiler de vehículos de alta calidad y satisfacción para nuestros
              clientes. Sin embargo, comprendemos que pueden surgir situaciones
              en las que necesites cancelar una reserva y solicitar un
              reembolso. A continuación, te presentamos nuestra política de
              devolución de dinero:
            </p>

            <ol>
              <li className="justify-text">
                1. Cancelación y reembolso anticipado: a. Si decides cancelar tu
                reserva antes de la fecha y hora de inicio del alquiler
                especificadas en la confirmación de la reserva, tendrás derecho
                a un reembolso parcial del importe pagado. b. Para solicitar un
                reembolso anticipado, comunica tu cancelación a través de
                nuestro sitio web o poniéndote en contacto con nuestro equipo de
                atención al cliente antes de la fecha de inicio del alquiler.:
              </li>
              <li className="justify-text">
                2. Cancelación y reembolso durante el alquiler: a. Si decides
                devolver el vehículo antes de la fecha y hora de finalización
                del alquiler especificadas en la confirmación de la reserva, no
                se realizará ningún reembolso por el tiempo no utilizado. b. En
                caso de que se presenten problemas con el vehículo que afecten
                gravemente su uso, comunica inmediatamente el problema a nuestro
                equipo de atención al cliente para buscar una solución adecuada.
              </li>
              <li className="justify-text">
                3. Cancelación y reembolso por parte de Summer Rent a car Nos
                reservamos el derecho de cancelar una reserva en caso de
                incumplimiento de nuestras políticas o si detectamos un uso
                indebido de nuestros servicios. b. En el caso de que cancelemos
                una reserva por nuestra parte, te reembolsaremos el importe
                total pagado menos los gastos de comisión bancarios.
              </li>
              <li className="justify-text">
                4. Procedimiento de reembolso: a. Los reembolsos se procesarán
                utilizando el mismo método de pago que utilizaste para realizar
                la reserva, a menos que se acuerde lo contrario. b. Dependiendo
                del método de pago y la entidad financiera involucrada, el
                tiempo de procesamiento del reembolso puede variar. En general,
                los reembolsos suelen demorar entre 5 y 15 días hábiles en
                reflejarse en tu cuenta.
              </li>
              <li className="justify-text">
                5. Gastos no reembolsables: a. Algunos servicios adicionales o
                cargos pueden ser no reembolsables, como tarifas de gestión,
                seguros, o gastos administrativos. Estos detalles se indicarán
                claramente en la confirmación de la reserva.
              </li>
              <li className="justify-text">
                6. Cambios en la reserva: a. Si necesitas modificar tu reserva,
                te recomendamos comunicarte con nuestro equipo de atención al
                cliente info@rentacarsummer.com para verificar la disponibilidad
                y las condiciones relacionadas con el cambio. Los cambios pueden
                estar sujetos a cargos adicionales.
              </li>
            </ol>
            <p className="justify-text">
              Nuestra política de devolución de dinero tiene como objetivo
              proporcionarte una experiencia justa y transparente. Si tienes
              alguna pregunta o necesitas asistencia, no dudes en ponerte en
              contacto con nuestro equipo de soporte.
            </p>
            <br />
            <p>
              <b>Términos y Condiciones del Alquiler de Autos</b>
            </p>
            <p className="justify-text">
              Los siguientes términos y condiciones rigen el alquiler de
              vehículos a través de Summer Rent a Car, en adelante "Nosotros" o
              "la Compañía". Al realizar una reserva y alquilar un vehículo a
              través de nuestro sitio web, aceptas cumplir con estos términos y
              condiciones. Te recomendamos leerlos detenidamente antes de
              continuar:
            </p>
            <ol>
              <li className="justify-text">
                1. Conductor: a. El conductor principal debe tener al menos 21
                años y poseer un permiso de conducir válido y vigente al momento
                de recoger el vehículo. Otros conductores adicionales también
                deben cumplir con estos requisitos. b. La presentación de un
                documento de identidad válido y un permiso de conducir válido es
                obligatoria al recoger el vehículo. c. Debe de portar su
                pasaporte o Id para realizar su reserva.
              </li>
              <li className="justify-text">
                2. Reserva: a. La reserva del vehículo se realiza a través de
                nuestro sitio web o por teléfono. Al realizar la reserva,
                proporciona información precisa y veraz, incluidos los datos
                personales y la información de pago. b. La disponibilidad de los
                vehículos está sujeta a confirmación y puede variar según la
                demanda y la ubicación.
              </li>
              <li className="justify-text">
                3. Tarifas y Pagos: a. Las tarifas de alquiler se basan en la
                duración del alquiler, el tipo de vehículo y los servicios
                adicionales seleccionados. b. Los pagos se realizarán en el
                momento de la reserva, y se pueden aplicar cargos adicionales si
                se agregan servicios extra durante el proceso de alquiler. c.
                Los gastos adicionales, como multas de tráfico, peajes o daños
                en el vehículo, serán responsabilidad del conductor y se
                cobrarán según corresponda.
              </li>
              <li className="justify-text">
                4. Seguros: a. Todos nuestros vehículos incluyen un seguro
                básico obligatorio para cubrir responsabilidad civil y daños a
                terceros de acuerdo con las leyes aplicables. b. Ofrecemos
                opciones de seguros adicionales para cubrir daños al vehículo y
                protección contra robos. La aceptación de estos seguros es
                opcional, pero te recomendamos revisar los detalles y las
                coberturas antes de tomar una decisión.
              </li>
              <li className="justify-text">
                5. Uso del Vehículo: a. El vehículo alquilado solo puede ser
                utilizado para fines legales y no debe ser utilizado en
                actividades prohibidas por la ley. b. No está permitido conducir
                el vehículo bajo los efectos del alcohol, drogas u otras
                sustancias que afecten la capacidad de conducción.
              </li>
              <li className="justify-text">
                6. Devolución del Vehículo: a. El vehículo debe devolverse en el
                lugar y en la fecha y hora acordados en la reserva. Cualquier
                extensión del tiempo de alquiler debe ser acordada previamente
                con la Compañía. b. El vehículo debe ser devuelto en las mismas
                condiciones en que fue entregado, con el nivel de combustible
                igual al momento de la recogida.
              </li>
              <li className="justify-text">
                7. Cancelaciones: a. Las cancelaciones están sujetas a las
                políticas de cancelación establecidas en la política de
                devolución de dinero. b. Podemos cancelar una reserva en caso de
                incumplimiento de estos términos y condiciones o si consideramos
                que el alquiler puede poner en riesgo la seguridad del vehículo
                o de terceros.
              </li>
              <li className="justify-text">
                8. Responsabilidad del Cliente: a. El cliente es responsable de
                los daños o pérdidas sufridas por el vehículo durante el período
                de alquiler, a menos que se hayan contratado seguros adicionales
                que cubran estos eventos.
              </li>
              <li className="justify-text">
                9. Modificaciones: Nos reservamos el derecho de modificar estos
                términos y condiciones en cualquier momento. Cualquier cambio
                será efectivo desde el momento de su publicación en nuestro
                sitio web.
              </li>
            </ol>
            <p className="justify-text">
              Al alquilar un vehículo a través de Summer Rent a Cat, aceptas
              cumplir con estos términos y condiciones. Si tienes alguna
              pregunta o inquietud, no dudes en ponerte en contacto con nuestro
              equipo de atención al cliente. Fecha de última actualización:
              20/07/2023
            </p>
            <br />
          </span>
        </div>
      </section>
    </NcModal>
  );
};
export default FilterTerms;
