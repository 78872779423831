import {useEffect, useState} from "react"
import CommonLayout from "./CommonLayout";
import Input from "shared/Input/Input";
import { FormControl } from "components/FormControl";
import { FormProvider, useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useCustomerState } from "hooks/useCustomerState";
import FormGroupInput from "containers/ListYourCarPage/FormGroupInput";
import { useTranslation } from "react-i18next";
import { requiredRule } from "helpers/formRulesHelper";
import AccountSettingsFormType from "./types/AccountSettingsForm";
import Select from "shared/Select/Select";
import { useCountriesState } from "hooks/useCountriesState";
import api from "api/api";
import { ToastContainer, toast } from "react-toastify";
// Customer Form Type


export default function AccountSettings() {
  return (
    <CommonLayout title="Account Settings">
        <AccountSettingsForm />
    </CommonLayout>
  );
}

function AccountSettingsForm(){
  let { countries } = useCountriesState();
  const { customer, fetchCustomerStatus, error} = useCustomerState();

  const [loading, setLoading] = useState<boolean | null>(null);

  const methods = useForm<AccountSettingsFormType>();
  
  const { t } = useTranslation();

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const onSubmit = async (data: any) => {
    try{
      setLoading(true);

      var license_picture   = data?.license_picture ? data.license_picture[0] : null;
      var passport_picture  = data?.passport_picture ? data.passport_picture[0] : null;

      if(license_picture){
        var license_picture_base64 = await toBase64(license_picture);
        data.license_picture = {
          name: license_picture.name,
          type: license_picture.type,
          base64: license_picture_base64,
          ext: license_picture.name.split('.').pop()
        };
      }

      if(passport_picture){
        var passport_picture_base64 = await toBase64(passport_picture);
        data.passport_picture  = {
          name: passport_picture.name,
          type: passport_picture.type,
          base64: passport_picture_base64,
          ext: passport_picture.name.split('.').pop()
        };
      }

      const response = await api.put(`/profile/${customer?.id_customer}`, data);
      const data_ : any = response.data;
      if(data_.error)
        throw new Error(data_?.message);
      else{
        toast.success(data_?.message);
        window.location.reload();
      }
        
      setLoading(false);
    }catch(e: any){
      setLoading(false);
      toast.error('Error: ' + e.messag);
      console.log(e)
    }
  }

  useEffect(()=>{
    // Set values in form
    methods.setValue('name_customer', (customer?.name_customer || ""));
    methods.setValue('email', (customer?.email || ""));
    methods.setValue('phone_customer', (customer?.phone_customer || ""));
    methods.setValue('birthdate', (customer?.birthdate || ""));
    methods.setValue('license_number', (customer?.license_number || ""));
    methods.setValue('license_expiration_date', (customer?.license_expiration_date || ""));
    methods.setValue('passport_number', (customer?.passport_number || ""));
    methods.setValue('passport_expiration_date', (customer?.passport_expiration_date || ""));
    methods.setValue('id_pais', (customer?.id_pais || ""));
  }, [customer])
  

  return(
    <>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4 mb-4">
            <FormControl name="name_customer" className="mb-4" label={t('names')} id="name_customer" registerOptions={{}}>
              {(field: any) => <Input type="text" placeholder={t('names')} {...field} required/>}
            </FormControl>

            <FormControl name="email" className="mb-4" label={t('email')} id="email" registerOptions={{}}>
              {(field: any) => <Input type="text" placeholder={t('email')} {...field} required/>}
            </FormControl>

            <FormControl name="phone_customer" className="mb-4" label={t('phone')} id="phone_customer" registerOptions={{}}>
              {(field: any) => <Input type="text" placeholder={t('phone')} {...field}/>}
            </FormControl>

            <FormControl name="birthdate" className="mb-4" label={t('birthdate')} id="birthdate" registerOptions={{}}>
              {(field: any) => <Input type="date" placeholder={t('birthdate')} {...field}/>}
            </FormControl>

            <FormControl name="license_number" className="mb-4" label={t('license_number')} id="license_number" registerOptions={{}}>
            {(field: any) => <Input type="text" placeholder={t('license_number')} {...field}/>}
            </FormControl>

            <FormControl name="license_expiration_date" className="mb-4" label={t('license_expiration_date')} id="license_expiration_date" registerOptions={{}}>
            {(field: any) => <Input type="date" placeholder={t('license_expiration_date')} {...field}/>}
            </FormControl>

            <FormControl name="passport_number" className="mb-4" label={t('passport_number')} id="passport_number" registerOptions={{}}>
            {(field: any) => <Input type="text" placeholder={t('passport_number')} {...field}/>}
            </FormControl>

            <FormControl name="passport_expiration_date" className="mb-4" label={t('passport_expiration_date')} id="passport_expiration_date" registerOptions={{}}>
            {(field: any) => <Input type="date" placeholder={t('passport_expiration_date')} {...field}/>}
            </FormControl>

            <FormControl name="id_pais" className="mb-4" label={t('country')} id="id_pais" registerOptions={{}}>
            {
            (field: any) =>  
              <Select className="mt-2" name="country" {...field}>
                {countries?.map((country) => (
                  <option
                    key={`list-country-${country.id_pais}`}
                    value={country.id_pais}
                  >
                    {country.nombre_pais}
                  </option>
                ))}
             </Select>
           }
            </FormControl>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
           <FormControl name="license_picture" className="mb-4" label={t('license_picture')} id="license_picture" registerOptions={{}}>
            {(field: any) => 
              <>
                <Input type="file" placeholder={t('license_picture')} {...field} accept="image/*"/>
                <img src={customer?.license_url} alt="" />
              </>
            }
            </FormControl>

            <FormControl name="passport_picture" className="mb-4" label={t('passport_picture')} id="passport_picture" registerOptions={{}}>
            {(field: any) => 
              <>
                <Input type="file" placeholder={t('passport_picture')} {...field} accept="image/*"/>
                <img src={customer?.passport_url} alt="" />
              </>
            }
            </FormControl>
        </div>

        <div className="text-right">
          <ButtonPrimary disabled={loading ? true: false}>
              {loading ? t("loading") : t("save")}
          </ButtonPrimary>
        </div>
      </form>
      </FormProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </>
  )
}
