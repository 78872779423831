import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountries } from "api/countriesApi";
import { API_STATUSES } from "api/types";
import { ICountry } from "types/Country";

export interface ICountriesState {
  error: boolean,
  fetchCountriesStatus: API_STATUSES,
  countries: ICountry[] | undefined
}

export const fetchCountries = createAsyncThunk('countries/fetchCountries', getCountries)

const initialState: ICountriesState = {
  error: false,
  fetchCountriesStatus: 'IDLE',
  countries: []
}

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state, action) => {
      state.fetchCountriesStatus = 'PENDING'
    })

    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.fetchCountriesStatus = 'DONE'
      state.countries = action.payload.countries
    })

    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.error = true
      state.fetchCountriesStatus = 'ERROR'
    })
  }
})

const countriesReducer = countriesSlice.reducer

export { countriesReducer }