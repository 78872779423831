import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import moment from "moment";
import { IReservation } from "types/Reservation";

function getRange(startDate: Date, endDate: Date, type: any) {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, type));
  }
  return range;
}

const checkIfIsReserved = (
  targetDates: [Date, Date?],
  reservationsArr: IReservation[] = []
) => {
  let isReserved;
  const [startDate, endDate] = targetDates;
  const adaptedReservationsDates = reservationsArr.map(
    ({ init_reservation, end_reservation }) => ({
      startDate: moment(init_reservation),
      endDate: moment(end_reservation),
    })
  );

  // If only is searching by one specific date
  if (!endDate) {
    const adaptedTargetDate = moment(startDate);
    isReserved = adaptedReservationsDates.some(({ startDate, endDate }) =>
      adaptedTargetDate.isBetween(startDate, endDate, "days", "[]")
    );
  } else {
    // If is searching by date range
    // First get all car reservations and map them into array's which contains every day date between that reservation date range
    const adaptedTargetDatesRange = reservationsArr.map(
      ({ init_reservation, end_reservation }) =>
        getRange(init_reservation as any, end_reservation as any, "days")
    );

    // Compare if some date from every date range is between searched dates
    isReserved = adaptedTargetDatesRange.some((_dateRange) =>
      _dateRange.some((_date) =>
        _date.isBetween(startDate, endDate, "days", "[]")
      )
    );
  }

  return isReserved;
};

const checkIfIsReserved_ = (
  dateRangeValue: DateRage,
  reservationsArr: IReservation[] = []
) => {
  let isReserved;
  const {startDate, endDate} = dateRangeValue;
  return reservationsArr.some(({ init_reservation, end_reservation }) => {
    const initReservation = moment(init_reservation);
    const endReservation = moment(end_reservation);

    return (
      initReservation.isBetween(startDate, endDate, "days", "[]") ||
      endReservation.isBetween(startDate, endDate, "days", "[]")
    );
  });
};

export { checkIfIsReserved, checkIfIsReserved_ };
