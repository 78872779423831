import classNames from "classnames";
import { SearchFiltersIcon } from "./ItemTypeNav";

interface ItemTypeButtonProps {
  onSelect: (value: string) => void;
  itemData: SearchFiltersIcon;
  isSelected: boolean;
}
const ItemTypeButton = ({
  onSelect,
  itemData,
  isSelected,
}: ItemTypeButtonProps) => {
  const { id_item_type, detail_item_type, srcIcon } = itemData;
  return (
    <div
      onClick={() => onSelect(detail_item_type)}
      className={classNames(
        "px-4 py-2 cursor-pointer border-b-2 border-transparent hover:scale-110 transition-all flex flex-col items-center",
        {
          "border-neutral-600 text-neutral-600": isSelected,
        }
      )}
      key={id_item_type}
    >
      <img
        className={classNames("w-12 opacity-25 transition-all", {
          "opacity-75": isSelected,
        })}
        src={srcIcon}
        alt={detail_item_type}
      />
      <h4
        className={classNames("text-center text-sm text-neutral-400", {
          "text-neutral-600": isSelected,
        })}
      >
        {detail_item_type}
      </h4>
    </div>
  );
};
export default ItemTypeButton;
