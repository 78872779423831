import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';

type Props = {};

const WhatsappButton = (props: Props) => {
  return (
    <a
      href={'https://wa.me/message/AS6ZZCXPSPSQO1'}
      target="_blank"
      rel="noopener noreferrer"
      title={'Whatsapp'}
    >
      <div className="text-white dark:text-green-400 bg-green-500 dark:bg-neutral-700 fixed right-4 sm:right-10 bottom-20 z-10 p-4 flex items-center rounded-md">
        <BsWhatsapp size={25} />
      </div>
    </a>
  );
};

export default WhatsappButton;
