import { useEffect, useRef, useState } from "react";
import { Feature, Map as OLMap, View } from "ol";
import { MapOptions } from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import { Coordinates } from "types/common/Coordinates";
import { Item } from "types/Item/Item";
import { Point } from "ol/geom";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import VectorLayer from "ol/layer/Vector";

interface CarsMapProps {
  coordinates: Coordinates;
  cars: Item[];
}

const CarsMap = ({ coordinates, cars }: CarsMapProps) => {
  const formattedCars = cars.map((item) => ({
    ...item,
    srcIcon: `icons/searchFilters/${item.type?.detail_item_type.toLowerCase()}.svg`,
  }));

  const mapRef = useRef<any>();
  const [map, setMap] = useState<OLMap>();
  const { latitude, longitude } = coordinates;

  useEffect(() => {
    const options: MapOptions = {
      target: mapRef.current,
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        zoom: 9,
        center: fromLonLat([longitude, latitude]),
      }),
    };

    const mapObject = new OLMap(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => {
      mapObject.setTarget(undefined);
      setMap(undefined);
    };
  }, [latitude, longitude]);

  useEffect(() => {
    if (typeof map !== "undefined") {
      if (formattedCars.length > 0) {
        formattedCars.forEach(({ lat_item, long_item, srcIcon }) => {
          const carMarker = new Feature({
            geometry: new Point(fromLonLat([long_item, lat_item])),
            name: "car",
          });

          const carIconLayer = new VectorLayer({
            className: "carIconLayer",
            source: new VectorSource({ features: [carMarker] }),
            style: new Style({
              image: new Icon({
                anchor: [0.5, 50],
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                src: srcIcon,
                scale: 0.05,
              }),
            }),
          });

          map.addLayer(carIconLayer);
        });
      }
    }
  }, [map, cars]);

  return <div className="h-full w-full" ref={mapRef} />;
};

export { CarsMap };
