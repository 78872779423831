const PROVINCES = [
  {
    text: "All",
    value: "0",
  },
  {
    text: "San José",
    value: "1",
  },
  {
    text: "Alajuela",
    value: "2",
  },
  {
    text: "Cartago",
    value: "3",
  },
  {
    text: "Heredia",
    value: "4",
  },
  {
    text: "Guanacaste",
    value: "5",
  },
  {
    text: "Puntarenas",
    value: "6",
  },
  {
    text: "Limón",
    value: "7",
  },
];

export { PROVINCES };
