import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcModal from 'shared/NcModal/NcModal';
import { BrandSelector } from '../BrandSelector';
import ColorSelector from '../BrandSelector/ColorSelector';
import TypeSelector from '../BrandSelector/TypeSelector';
import { PriceRangeSelector } from '../PriceRangeSelector';
import { ProvinceSelector } from '../ProvinceSelector';
import SeatsRangeSelector from '../SeatsRangeSelector/SeatsRangeSelector';
import { TransmissionSelector } from '../TransmissionSelector';
import YearSelector from '../YearSelector/YearSelector';

interface Props {}
const FiltersModal = (props: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <NcModal
      contentPaddingClass="pb-12 pt-6 px-8"
      contentClass="flex flex-col gap-12"
      modalTitle={t('searchFilterModal-title')}
      onCloseModal={() => setIsOpen(false)}
      isOpenProp={isOpen}
      renderTrigger={() => (
        <ButtonSecondary
          onClick={() => setIsOpen(true)}
          className="rounded-md "
        >
          {t('searchFilter')}
        </ButtonSecondary>
      )}
    >
      <section className="w-full h-full flex flex-col gap-4">
        <PriceRangeSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <BrandSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <YearSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <SeatsRangeSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <TransmissionSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <ColorSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <TypeSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4">
        <ProvinceSelector />
      </section>
      <section className="w-full h-full flex flex-col gap-4"></section>
      <section className="w-full h-full flex flex-col gap-4"></section>
      <section className="w-full h-full flex flex-col gap-4"></section>
      <section className="w-full h-full flex flex-col gap-4"></section>
    </NcModal>
  );
};
export default FiltersModal;
