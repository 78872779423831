import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getColors } from "api/colorsApi";
import { CarColor } from "types/Item/Item";

export const fetchColors = createAsyncThunk('colors/fetchColors', getColors);

const modelAdapter = createEntityAdapter<CarColor>({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.id - a.id
})

export const colorsSlice = createSlice({
  name: 'colors',
  initialState: modelAdapter.getInitialState({
    error: false,
    fetchItemsStatus: 'IDLE'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchColors.pending, (state, action) => {
      state.fetchItemsStatus = 'PENDING'
    })

    builder.addCase(fetchColors.fulfilled, (state, action) => {
      state.fetchItemsStatus = 'DONE'
      modelAdapter.setAll(state, action.payload.colors as CarColor[])
    })

    builder.addCase(fetchColors.rejected, (state, action) => {
      state.error = true
      state.fetchItemsStatus = 'ERROR'
    })
  }}
)

export const colorSelector = modelAdapter.getSelectors((state: any) => state.colors)
export const colorsReducer = colorsSlice.reducer