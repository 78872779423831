import { configureStore } from "@reduxjs/toolkit";

// REDUCERS
import { carsReducer } from "./slices/carsSlice";
import { countriesReducer } from "./slices/countriesSlice";
import { amenitiesReducer } from "./slices/amenitiesSlice";
import { conditionsReducer } from "./slices/conditionsSlice";
import { insurancesReducer } from "./slices/insurancesSlice";
import { customerReducer } from "./slices/customerSlice";
import { itemTypesReducer } from "./slices/itemsTypeSlice";
import { carEquipmentReducer } from "./slices/carEquipmentSlice";
import { brandsReducer } from "./slices/brandSlice";
import { modelReducer } from "./slices/modelSlice";
import { colorsReducer } from "./slices/CarColorSlice";
import { pointDeliveryCantonsReducer } from "./slices/pointDeliveryCantons";

export const store = configureStore({
  reducer: {
    cars: carsReducer,
    countries: countriesReducer,
    amenities: amenitiesReducer,
    conditions: conditionsReducer,
    insurances: insurancesReducer,
    customer: customerReducer,
    itemTypes: itemTypesReducer,
    carEquipment: carEquipmentReducer,
    brands: brandsReducer,
    models: modelReducer,
    colors: colorsReducer,
    pointDeliveryCantons: pointDeliveryCantonsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
