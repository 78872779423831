import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";
import CheckBoxCard from "shared/Checkbox/CheckBoxCard";
import { amenitieAssignment, Item } from "types/Item/Item";

interface AmenitiesSectionProps {
  currentItem?: Item;
  setSelectedAmenities: Dispatch<SetStateAction<amenitieAssignment[]>>;
  selectedAmenities: amenitieAssignment[];
  differenceDays: number | undefined;
}

const AmenitiesSection = ({
  currentItem,
  setSelectedAmenities,
  selectedAmenities,
  differenceDays
}: AmenitiesSectionProps) => {
  const { t } = useTranslation();
  const { amenitie_assignments } = currentItem ?? {};

  const onSelectCheckbox = (id: number) => {
    const amenitieObject = amenitie_assignments?.find(
      ({ id_amenitie }) => id_amenitie === id
    );

    if (amenitieObject) {
      const isSelected = selectedAmenities.find(
        ({ id_amenitie }) => amenitieObject.id_amenitie === id_amenitie
      );

      if (isSelected) {
        setSelectedAmenities((prev) =>
          prev.filter(
            (amenitie) => amenitie.id_amenitie !== amenitieObject.id_amenitie
          )
        );

        return;
      }

      setSelectedAmenities((prev) => [...prev, amenitieObject]);
    }
  };


  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t("amenities")}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("carDetail-amenities-description")}
        </span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
        {amenitie_assignments?.map(({ amenitie, price, IS_REQUIRED, type }) => (
          <CheckBoxCard
            image={amenitie.public_route_image || 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png'}
            title={amenitie.detail_amenities}
            description={`For ${differenceDays} days`}
            price={price * ( type == "DAILY" ? (differenceDays ?? 1) : 1)}
            isSelected={false}
            onChange={() => onSelectCheckbox(amenitie.id_amenitie)}
            isDefaultChecked={IS_REQUIRED}
            disabled={IS_REQUIRED}
        />
        ))}
      </div>
    </div>
  );
};

export default AmenitiesSection;
