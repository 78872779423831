import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getConditions } from "api/conditionsApi";
import { API_STATUSES } from "api/types";
import { ICondition } from "types/Condition";

interface IConditionsState {
  error: boolean,
  fetchConditionsStatus: API_STATUSES,
  conditions: ICondition[]
}

export const fetchConditions = createAsyncThunk('conditions/fetchConditions', getConditions)

const initialState: IConditionsState = {
  error: false,
  fetchConditionsStatus: "IDLE",
  conditions: []
}

export const conditionsSlice = createSlice({
  name: 'conditions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConditions.pending, (state, action) => {
      state.fetchConditionsStatus = 'PENDING'
    })

    builder.addCase(fetchConditions.fulfilled, (state, action) => {
      state.fetchConditionsStatus = 'DONE'
      state.conditions = action.payload.conditions
    })

    builder.addCase(fetchConditions.rejected, (state, action) => {
      state.error = true
      state.fetchConditionsStatus = 'ERROR'
    })
  }
})

const conditionsReducer = conditionsSlice.reducer

export { conditionsReducer }