
import { ReservationResponse } from "containers/PayPage/types/ReservationRespose";
import CheckoutStripe from "containers/StripeSuccesfulPage/CheckoutFormPage";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import BacCheckoutForm from "../BacPaymentModal/BacCheckoutForm";
import { env } from "env";

interface PaymentModalProps {
  isOpen: boolean;
  country: number;
  onCloseModal: Dispatch<SetStateAction<boolean>>;
  reservationResponse: ReservationResponse | null;
  registerStatus: number;
  setResgisterStatus: Dispatch<SetStateAction<number>>;
}

interface PaymentForm {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  email: string;
  cardNumber: string;
  cardCvv: string;
  cardHolderName: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
}

const PaymentModal = ({
  isOpen,
  onCloseModal,
  country,
  reservationResponse,
  registerStatus,
  setResgisterStatus,
}: PaymentModalProps) => {
  const { t } = useTranslation();
  const { ...methods } = useForm<PaymentForm>();
  const [submitPayment, setSubmitPayment] = useState<boolean | null>(null);
  const domain = env;

  const [customer, setCustomerData] = useState<any | null>(JSON.parse(localStorage.getItem('customer') || 'null'));
  const id_agent = customer?.id_agent || 1;

  const closeModal = () => {
    methods.reset();
    onCloseModal(false);
  };
  const onSubmitPayment = async () => {
    setSubmitPayment(!submitPayment);
  };

  useEffect(() => {
    setSubmitPayment(null);
  }, [country]);

  // useEffect(()=>{
  //   setResgisterStatus(id_agent ? 1 :0);
  // }, [isOpen]);



  return (
    <NcModal
      isOpenProp={isOpen}
      onCloseModal={closeModal}
      modalTitle={t("paymentModal-title")}
      contentPaddingClass="py-8 px-12"
      contentExtraClass="max-w-7xl"
      renderTrigger={() => <></>}
    >
      {
        (registerStatus == 1 || id_agent || true) ?
          <>
            <div className={`listingSectionSidebar__wrap shadow-lg p-8 bg-slate-100`}>
              <h1 className="font-bold text-center"> Reservation created successfully</h1>

              <div className="grid grid-cols-2">
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={2}><h1 className="font-bold"> Reservation info</h1></td>
                      </tr>
                      <tr>
                        <td><b>Vehicle: </b></td>
                        <td>{reservationResponse?.reservation?.item.detail_item}</td>
                      </tr>
                      <tr>
                        <td><b>Init Date: </b></td>
                        <td>{(reservationResponse?.reservation?.init_reservation || '').substring(0, 10)}</td>
                      </tr>
                      <tr>
                        <td><b>End Date: </b></td>
                        <td>{(reservationResponse?.reservation?.end_reservation || '').substring(0, 10)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={2}><h1 className="font-bold">Contact</h1></td>
                      </tr>
                      <tr>
                        <td><b>Names: </b></td>
                        <td>{reservationResponse?.reservation?.customer.name_customer}</td>
                      </tr>
                      <tr>
                        <td><b>Email: </b></td>
                        <td>{reservationResponse?.reservation?.customer.email}</td>
                      </tr>
                      <tr>
                        <td><b>Phone: </b></td>
                        <td>{reservationResponse?.reservation?.customer.phone_customer}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <div className="flex justify-center ...">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDi1eWXtRdJDzKTu1N--ca34RVAFVehf3AzWCtQn2GcljcN0rJoirA8Vy81gjavQAOwdg&usqp=CAU" alt="success" width="50%" />
                  </div>
                  <p className="text-gray-500 text-center">Your reservation number is: <span className="font-bold">{reservationResponse?.reservation?.id_reservation}</span></p>
                  <br />
                  <div className="flex justify-center ...">
                    <a type="button" href={`${domain}/api/reservations/pdf/${reservationResponse?.reservation?.id_reservation}`} target="_blank" className="round text-white bg-blue-700 px-6 py-3.5">Download PDF</a>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <>
            {country == 53
              ? <BacCheckoutForm submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservationResponse}></BacCheckoutForm>
              :
              <CheckoutStripe amount={20} submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservationResponse}></CheckoutStripe>
            }

            <div className="mt-4">
              <ButtonPrimary onClick={onSubmitPayment}>Pay</ButtonPrimary>
            </div>
          </>
      }
    </NcModal>
  );
};

export type { PaymentModalProps };
export default PaymentModal;
