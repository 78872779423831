import { FiltersModal } from "./components/FiltersModal";
import { ItemTypeNav } from "./components/ItemTypeNav";

interface FiltersProps {}

const Filters = (props: FiltersProps) => {
  return (
    <>
      <div className="flex flex-col sm:flex-row items-center mt-20">
        <ItemTypeNav />
        <FiltersModal />
      </div>
    </>
  );
};
export default Filters;
