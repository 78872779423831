import { createContext, Dispatch, SetStateAction, useState } from "react";
import ProvincesNames from "types/common/ProvincesNames";
import { MultiValue } from "react-select";

interface SearchFiltersState {
  itemType?: string;
  pricesRange: [number, number];
  seatsRange: [number, number];
  location: ProvincesNames;
  brands: any;
  models: any;
  colors: any;
  types: any;
  provinces: any;
  // years: MultiValue<Option>;
  transmissions: MultiValue<Option>;
  others?: any;
}

export interface Option {
  label: string,
  value: string
}

interface SearchCarsContextState {
  searchInitReservationDate: any;
  setSearchInitReservationDate: any;
  searchEndReservationDate: any;
  setSearchEndReservationDate: any;
  searchFilters: SearchFiltersState;
  setSearchFilters: Dispatch<SetStateAction<SearchFiltersState>>;
}

const SearchCarsContext = createContext<SearchCarsContextState | null>(null);

interface SearchCarsProviderProps {
  children: JSX.Element | JSX.Element[];
}

const initialSearchFiltersState: SearchFiltersState = {
  itemType: undefined,
  pricesRange: [0, 10000],
  seatsRange: [1, 7],
  brands: [],
  models: [],
  colors: [],
  types: [],
  provinces: [],
  location: "limon",
  transmissions: [
    { label: "AUTOMATIC", value: "AUTOMATIC" }, 
    { label: "MANUAL", value: "MANUAL" }, 
  ]
};

const SearchCarsProvider = ({ children }: SearchCarsProviderProps) => {
  const [searchInitReservationDate, setSearchInitReservationDate] = useState(
    new Date()
  );
  const [searchEndReservationDate, setSearchEndReservationDate] = useState();

  const [searchFilters, setSearchFilters] = useState<SearchFiltersState>(
    initialSearchFiltersState
  );

  return (
    <SearchCarsContext.Provider
      value={{
        searchInitReservationDate,
        searchEndReservationDate,
        setSearchInitReservationDate,
        setSearchEndReservationDate,
        searchFilters,
        setSearchFilters,
      }}
    >
      {children}
    </SearchCarsContext.Provider>
  );
};

export { SearchCarsProvider, SearchCarsContext };
