import { FC } from "react";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { Item } from "types/Item/Item";
import { useTranslation } from "react-i18next";
import { env } from "env";
import { getDayPrice } from "helpers/reservations/calculatePrices";
import moment from "moment";

export interface CarCardProps {
  className?: string;
  data?: Item;
  size?: "default" | "small";
  isReserved?: boolean;
  targetDate?: any;
  endDate?: any;
}

const CarCard: FC<CarCardProps> = ({
  size = "default",
  className = "",
  isReserved,
  targetDate,
  endDate,
  data,
}) => {
  const { amount_item, detail_item, id_item, images, owner } = data ?? {};

  const currentDate = moment(new Date());
  const currentPrice = getDayPrice(data, currentDate);

  const { t } = useTranslation();

  const renderSliderGallery = () => {
    let images_ = images?.map(function (image, index) {
      return {
        order: image.order,
        url_item_image: image.url_item_image,
      }
    }) || [];

    let imagerOrder = images_.sort((a, b) => a.order - b.order)
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <div className="aspect-w-16 aspect-h-9 ">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="w-full"
            src={`${env}/storage/${imagerOrder[0]?.url_item_image}`}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        className={`${size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"
          } ${isReserved && "bg-gray-100y"}`}
      >
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {Boolean(!isReserved) ? (
              <Badge name={t("available")} color="green" />
            ) : (
              <Badge name={t("reserved")} color="red" />
            )}
            <h2
              className={`  capitalize ${size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
                }`}
            >
              <span className="line-clamp-1">{detail_item}</span>
            </h2>
          </div>
          <div className="flex items-center flex-c text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <p>
              <span className="font-bold">{t("owner")}: </span>
              {owner?.name}
            </p>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            ${currentPrice || 0}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                / {t("day").toLocaleLowerCase()}
              </span>
            )}
          </span>
          <StartRating reviewCount={5} point={5} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 ${className} ${isReserved && "border-2 border-red-800 bg-gray-50"
        }`}
      data-nc-id="CarCard"
    >
      <Link
        to={`listing-car-detail?id_item=${id_item}&target_date=${targetDate}${endDate ? `&end_date=${endDate}` : ""
          }`}
        className="flex flex-col"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CarCard;
