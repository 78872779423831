import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Select, { MultiValue } from 'react-select';
import { Option } from 'containers/ListingCarPages/context/SearchCarsContext';

interface Props {}

const TransmissionSelector = (props: Props) => {
  const { t } = useTranslation();
  const { setSearchFilters } = useSearchCarsContext();

  const years = [
    { label: '2000', value: '2000' },
    { label: '2001', value: '2001' },
    { label: '2002', value: '2002' },
    { label: '2003', value: '2003' },
    { label: '2004', value: '2004' },
    { label: '2005', value: '2005' },
    { label: '2006', value: '2006' },
    { label: '2007', value: '2007' },
    { label: '2008', value: '2008' },
    { label: '2009', value: '2009' },
    { label: '2010', value: '2010' },
    { label: '2011', value: '2011' },
    { label: '2012', value: '2012' },
    { label: '2013', value: '2013' },
    { label: '2014', value: '2014' },
    { label: '2015', value: '2015' },
    { label: '2016', value: '2016' },
    { label: '2017', value: '2017' },
    { label: '2018', value: '2018' },
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
  ];

  const [error, setError] = useState(false);

  const [localYears, setLocalYears] = useState<MultiValue<Option>>([]);

  const applyBrandSelection = () => {
    setError(false);
    setSearchFilters((prev) => ({ ...prev, transmissions: localYears }));
  };

  return (
    <>
      <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">
              {/* {t("searchFilterModal-pricePerDay")} */}
              {t('searchFilterModal-years')}
            </span>

            <Select
              options={years}
              defaultValue={years}
              isMulti
              name="years"
              className="basic-multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              onChange={(years: MultiValue<Option>) => setLocalYears(years)}
            />
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">
              {t('invalid-search')}
            </strong>
          )}
          <ButtonPrimary
            onClick={applyBrandSelection}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t('apply')}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default TransmissionSelector;
