import React, { useState, useEffect, FC, SetStateAction, Dispatch } from "react";
import { loadStripe, StripeElementsOptions, Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../../components/BacPaymentModal/CheckoutForm"
import "./App.css";
import { StripeFormResponse } from "types/Payment";
import { createPaymentFormApi } from "api/paymentApi";
import { CheckoutProps } from "./types/CheckoutProps";
import { ReservationResponse } from "containers/PayPage/types/ReservationRespose";

const stripePromise = loadStripe("pk_test_51HLQSUDLclFE40YHBMP0C6lVnzRtzc06LS9ONLaxGmo57dWEm2r10hhmvx2b5J0fPGL2HhMM9vH9zcclm3E9EV3A005WrTZ5hz");

const CheckoutStripe = ({
  amount = 1,
  submitPayment,
  setResgisterStatus,
  reservation
}: {
  amount?: number;
  submitPayment: boolean | null;
  setResgisterStatus: Dispatch<SetStateAction<number>>;
  reservation: ReservationResponse | null;
}) => {
  const [clientSecretStripe, setClientSecretStripe] = useState("");
  const [appearance, setAppearance] = useState<Appearance>({
    theme: 'stripe',
  });
  const [options, setOptions] = useState<StripeElementsOptions>({
    clientSecret: clientSecretStripe,
    appearance,
  });

  useEffect(() => {
    createPaymentForm();
  }, []);

  const createPaymentForm = async () => {
    try {
      const { clientSecret } = (await createPaymentFormApi({})) as StripeFormResponse;
      setOptions({ ...options });
      //setOptions({ ...options, clientSecret: clientSecret });
      setClientSecretStripe(clientSecret);
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="App">
      {clientSecretStripe && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm amount={amount} submitPayment={submitPayment} setResgisterStatus={setResgisterStatus} reservation={reservation} />
        </Elements>
      )}
    </div>
  );
};

export default CheckoutStripe;