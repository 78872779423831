import { ICondition } from "types/Condition";
import api from "./api";

export interface IConditionsResponse {
  message: string,
  error: boolean,
  conditions: ICondition[],
}

const API_ENDPOINT = '/data/conditions'

const getConditions = async () => ((await api.get<IConditionsResponse>(API_ENDPOINT)).data)

export { getConditions }