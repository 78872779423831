import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';


export const CreditCard = ({cvc, expiry, focused, name, number}) => {
  return (
    <Cards
    cvc={cvc}
    expiry={expiry}
    focused={focused}
    name={name}
    number={number}
  />
  )
}
