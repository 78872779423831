import { t } from "i18next";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks/useAppStore";
import { IInsurance } from "types/Insurance";
import { InsurancesTypes, Item } from "types/Item/Item";

type TableProps = {
    currentItem?: Item;
    setSelectedInsurances: Dispatch<SetStateAction<InsurancesTypes | undefined>>;
    selectedInsurances: InsurancesTypes | undefined;
  };
  
const InsurancesTableSection = (
    {currentItem, setSelectedInsurances, selectedInsurances }: TableProps
) => {
  const { insurance_assignments = [], insurances_types = [] } = currentItem ?? {};

  const containInGroup = (insurance: any, insurances: string) => {
    const insurances_ = (insurances || '').split(",");
    return insurances_.includes(insurance.id_insurance.toString());
  }

  const insurances: IInsurance[] = useAppSelector((state) => state.insurances.insurances);

  const handleTypeInsuranceSelected = (type: InsurancesTypes, index: number) => () => {
    setSelectedInsurances(type);
    setIndexSelected(index);
  }

  useEffect(() => {
    if (insurances_types.length > 0) {
      setSelectedInsurances(insurances_types[0]);
    }
  }, [insurances_types]);

  const [indexSelected, setIndexSelected] = useState<number>(0);

  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t("insurances")}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("carDetail-insurances-description")}
        </span>
      </div>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="border p-2"></th>
            {
              insurances_types.map((type, index) => (
                <th colSpan={3} className={`border p-2 ${ index == indexSelected ? 'selected_column' : '' }`}>
                  {type.name}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              insurances.map((insurance) => {
                return (
                  <tr>
                    <td className="border p-2">
                      <div className="bg-gray-800 text-white p-2 rounded-md text-sm dialog-box w-full">
                        {insurance.detail_insurance || '-'}
                      </div>
                    </td>
                    {
                      insurances_types.map((type, index) => (
                        <td colSpan={3} className={`border p-2 ${containInGroup(insurance, type.insurances) ? 'check' : 'cross' } ${ index == indexSelected ? 'selected_column' : '' }`}>{containInGroup(insurance, type.insurances) ? "✓" : "X"}</td>
                      ))
                    }
                  </tr>
                );
              })
            }
          <tr>
            <td className="border p-2"></td>
            {
              insurances_types.map((type, index) => (
                <td colSpan={3} className="border p-2 price">
                  <button onClick={handleTypeInsuranceSelected(type, index)} className="bg-blue-800 text-white px-4 py-2">
                    ${type.price}
                  </button>
                </td>
              ))
            }
          </tr>
        </tbody>
      </table>
    </div>
);}

export default InsurancesTableSection;