import { useFormContext, SubmitHandler } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FormControl } from "components/FormControl";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import { useTranslation } from "react-i18next";
import FormGroupInput from "../FormGroupInput";
import FormSectionWrapper from "../FormSectionWrapper";
import {
  compareRule,
  maxLengthRule,
  patternRule,
  requiredRule,
  trimInputRule,
} from "helpers/formRulesHelper";

interface IListYourCarForm {}

interface YourCarProps {
  active?: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  index: number;
}

export function YourCar({ active, setCurrentStep, currentStep, index }: YourCarProps) {
  let methods = useFormContext();

  let { t } = useTranslation();

  const onSubmit = (data: any) => {
    // next();
    setCurrentStep(index + 1);
  };

  return (
    <>
      <FormSectionWrapper
        currentStep={currentStep}
        index={index}
        setCurrentStep={setCurrentStep}
        active={active}
        title={t(`your-car`)}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormGroupInput
            id="car_location"
            name="car_location"
            label="Where is your car located?"
            type="text"
            placeholder="Aeropost, Miami FL, 33122"
            registerOptions={{
              required: requiredRule(t("requiredField")),
            }}
          />

          <FormGroupInput
            id="car_item_title"
            name="car_item_title"
            label="Which car do you have?"
            type="text"
            placeholder="Toyota Corolla XEI 2011"
            registerOptions={{
              required: requiredRule(t("requiredField")),
            }}
          />

          <FormControl
            id="car_model"
            name="car_model"
            className="mb-6"
            registerOptions={{
              required: requiredRule(t("requiredField")),
            }}
          >
            {(field: any) => (
              <Checkbox
                defaultChecked
                label={"My model is 1981 or later"}
                {...field}
              />
            )}
          </FormControl>

          <div className="mb-6">
            <label htmlFor="" className="font-medium">
              Odometer
            </label>
            <Select className="mt-2" name="odometer" defaultValue={"40-50"}>
              <option value="40-50">40-50k miles</option>
            </Select>
          </div>

          <div className="mb-6">
            <h2 className="font-medium mb-2">Transmission</h2>

            <div>
              <label>
                <input
                  type="radio"
                  value="AUTOMATIC"
                  checked
                  {...methods.register("transmission")}
                />{" "}
                Automatic
              </label>
            </div>

            <div>
              <label>
                <input
                  type="radio"
                  value="MANUAL"
                  {...methods.register("transmission")}
                />{" "}
                Manual
              </label>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="" className="font-medium">
              Trim
            </label>
            <Select className="mt-2" name="car_trim" defaultValue={"LE"}>
              <option value="LE">LE</option>
            </Select>
          </div>

          <div className="mb-6">
            <label htmlFor="" className="font-medium">
              Style
            </label>
            <Select className="mt-2" name="car_style" defaultValue={"Sedan"}>
              <option value="SEDAN">4dr Sedan (1.8 4cyl CVT)</option>
            </Select>
          </div>

          <div className="mb-6">
            <label htmlFor="" className="font-medium">
              I Certify I paid applicable sales or related taxes on the purchase
              of this vehicle.
            </label>
            <Select className="mt-2" name="car_taxes" defaultValue={""}>
              <option value="" disabled>
                Select an option
              </option>
            </Select>
          </div>

          <FormControl
            id="car_branded"
            name="car_branded"
            className="mb-6"
            registerOptions={{
              required: requiredRule(t("requiredField")),
            }}
          >
            {(field: any) => (
              <Checkbox
                label={"My car has never had a branded or salvage title."}
                {...field}
              />
            )}
          </FormControl>

          <ButtonPrimary type="submit">Next</ButtonPrimary>
        </form>
      </FormSectionWrapper>
    </>
  );
}
