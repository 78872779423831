import { IInsurance } from "types/Insurance";
import api from "./api";

export interface IInsurancesResponse {
  message: string,
  error: boolean,
  insurances: IInsurance[],
}

const API_ENDPOINT = '/data/insurances'

const getInsurances = async () => ((await api.get<IInsurancesResponse>(API_ENDPOINT)).data)

export { getInsurances }