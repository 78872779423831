import { ICreateCustomer, ICustomer, ILoginCustomer } from "types/Customer";
import api from "./api";

const API_ENDPOINT = "/customers";
const API_LOGIN_ENDPOINT = "/api-login";
const API_LOGOUT_ENDPOINT = "/logout";

const API_CUSTOMERS_ENDPOINT =  "/v1/customers";

interface ICustomerLoginResponse {
  message: string;
  token: string;
  error: boolean;
  customer: ICustomer;
}

interface ICustomerLogoutResponse {
  error: boolean;
  message: string;
}

interface ICustomerResponse {
  message: string;
  error: boolean;
  customer?: ICustomer;
}


const getCustomerData = async (id_customer: number | string) =>
  (await api.get<ICustomerResponse>(`${API_ENDPOINT}/${id_customer}`)).data;

const createCustomer = async (new_customer_data: ICreateCustomer) =>
  (await api.post<ICustomerResponse>(API_CUSTOMERS_ENDPOINT, new_customer_data)).data;

const loginAsCustomer = async (login_customer_data: ILoginCustomer) =>
  (
    await api.post<ICustomerLoginResponse>(
      API_LOGIN_ENDPOINT,
      login_customer_data
    )
  ).data;

const logoutAsCustomer = async (customer_token: string) =>
  (
    await api.post<ICustomerLogoutResponse>(
      API_LOGOUT_ENDPOINT,
      customer_token,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  ).data;

export {
  createCustomer,
  loginAsCustomer,
  getCustomerData,
  logoutAsCustomer
};
