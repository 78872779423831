import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ES_TRANSLATION } from './translations/es/translation'
import { EN_TRANSLATION } from './translations/en/translation'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: EN_TRANSLATION
      },

      es: {
        translation: ES_TRANSLATION
      }
    }
  })

i18n.changeLanguage('en')

export default i18n
