import React, { FC, useEffect, useState, useMemo } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import CarCardH from "components/CarCardH/CarCardH";
import { useCarsState } from "hooks/useCarsState";
import { useLoadListingCarPage } from "../hooks/useLoadListingCarPage";
import { CarDataType } from "data/types";
import { useAppSelector } from "store/hooks/useAppStore";
import { carsSelector } from "store/slices/carsSlice";
import { useTranslation } from "react-i18next";
import { CarsMap } from "./CarsMap";
import { Coordinates } from "types/common/Coordinates";
import { provincesCoordinatesMap } from "constants/provincesCoordinates";
import { useSearchCarsContext } from "../hooks/useSearchCarsContext";

export interface SectionGridHasMapProps {
  data?: CarDataType[];
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  useLoadListingCarPage();

  const { searchInitReservationDate, searchEndReservationDate, searchFilters } = useSearchCarsContext();
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState<Coordinates>(
    provincesCoordinatesMap[searchFilters.location]
  );

  useEffect(() => {
    setMapCoordinates(provincesCoordinatesMap[searchFilters.location]);
  }, [searchFilters.location]);

  const data = useAppSelector(carsSelector.selectAll);
  const { fetchItemsStatus } = useCarsState() ?? {};
  const { t } = useTranslation();

  const filterByType = (carType: string | undefined) =>
    searchFilters.itemType ? searchFilters.itemType === carType : true;

  const filterByPriceRange = (price: number) =>
    price >= searchFilters.pricesRange[0] &&
    price <= searchFilters.pricesRange[1];

    const filteredCars = useMemo(
      () =>
        data
          ?.filter((car) => Boolean(filterByType(car.type?.detail_item_type)))
          .filter((car) => Boolean(filterByPriceRange(car.amount_item))),
      [data, searchFilters]
    );


  if (fetchItemsStatus === "PENDING") {
    return (
      <div className="flex justify-center mb-16">
        <h4 className="text-5xl animate-pulse">
          Cargando carros y ofertas asombrosas...
        </h4>
      </div>
    );
  }

  if (fetchItemsStatus === "ERROR") {
    return (
      <div className="flex gap-6 flex-col items-center justify-center mb-16">
        <h4 className="text-5xl  font-bold text-red-500">
          {t("itemList-error-title")}
        </h4>
        <h4 className="text-2xl text-red-500">
          {t("itemList-error-subtitle")}
        </h4>
      </div>
    );
  }

  return (
    <div>
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={t("gridHasMap-title")}
            subHeading={
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                {data?.length === 1
                  ? t("carWithCount", { count: 1 })
                  : t("pluralCarWithCount", { count: data?.length })}

                <span className="mx-2">·</span>
                {t("today")}
              </span>
            }
          />

          <div className="grid grid-cols-1 gap-8">
            {
              filteredCars.length ? (
                filteredCars.map((car) => {
                  return (
                    <div key={car.id_item}>
                      <CarCardH 
                        targetDate={searchInitReservationDate}
                        endDate={searchEndReservationDate}
                        data={car} 
                        />
                    </div>
                  )
                })
              ) : (
                <div>
                  <h4 className="font-bold text-red-400">{t("no-cars-found")}</h4>
                </div>
              )
            }
            {/* {(data || []).map((car) => (
              <div key={car.id_item}>
                <CarCardH data={car} />
              </div>
            ))} */}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
        </div>

        <div
          className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
          onClick={() => setShowFullMapFixed(true)}
        >
          <i className="text-lg las la-map"></i>
          <span>Show map</span>
        </div>

        <div
          className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
            }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <CarsMap coordinates={mapCoordinates} cars={filteredCars} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
