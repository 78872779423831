type Props = {};

const SidebarDetailSection = (props: Props) => {
  return (
    <div className="listingSection__wrap lg:shadow-xl">
      <span className="text-2xl font-semibold block">Itinerario</span>
      <div className="mt-8 flex">
        <div className="flex-shrink-0 flex flex-col items-center py-2">
          <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
          <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
        </div>
        <div className="ml-4 space-y-14 text-sm">
          <div className="flex flex-col space-y-2">
            <span className=" text-neutral-500 dark:text-neutral-400">
              Lunes, Mayo 2 · 10:00
            </span>
            <span className=" font-semibold">Aeropuerto Juan Santamaría</span>
          </div>
          <div className="flex flex-col space-y-2">
            <span className=" text-neutral-500 dark:text-neutral-400">
              Viernes, Mayo 6 · 10:00
            </span>
            <span className=" font-semibold">Naranjo Centro</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarDetailSection;
