import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Select, { MultiValue } from 'react-select';
import { Option } from 'containers/ListingCarPages/context/SearchCarsContext';
import { useAppSelector } from 'store/hooks/useAppStore';
import { useLoadListingColors } from 'containers/ListingCarPages/hooks/useLoadColorsListing';
import { colorSelector } from 'store/slices/CarColorSlice';

interface Props {}

const ColorSelector = (props: Props) => {
  useLoadListingColors();
  const data = useAppSelector(colorSelector.selectAll);
  console.log('colorsdata', data);
  const { t } = useTranslation();
  const { setSearchFilters } = useSearchCarsContext();

  let colors = (data || []).map((color) => ({
    value: color.id,
    label: t(color.name.toLowerCase().replaceAll(' ', '-')),
  }));

  const [error, setError] = useState(false);

  const [localColors, setLocalColors] = useState<MultiValue<Option>>([]);

  const applyBrandSelection = () => {
    setError(false);
    setSearchFilters((prev) => ({ ...prev, colors: localColors }));
  };

  return (
    <>
      <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">{t('searchFilterModal-color')}</span>

            <Select
              options={colors}
              isMulti
              name="cities"
              className="basic-multi-select dark:text-black"
              classNamePrefix="select"
              onChange={(colors: any) => setLocalColors(colors)}
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">
              {t('invalid-search')}
            </strong>
          )}
          <ButtonPrimary
            onClick={applyBrandSelection}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t('apply')}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default ColorSelector;
