import AuthUser from 'HOC/AuthUser/AuthUser';
import {
  FC,
  FormEventHandler,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useAppSelector } from 'store/hooks/useAppStore';
import { carsSelector } from 'store/slices/carsSlice';
import { Item } from 'types/Item/Item';
import CheckBoxPayment from 'shared/Checkbox/CheckBoxPayment';
import { BacPaymentResponse, BacPaymentData } from 'types/Payment';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import {
  requiredRule,
  trimInputRule,
  maxLengthRule,
  patternRule,
} from 'helpers/formRulesHelper';
import { FormControl } from 'components/FormControl';
import { createPayment, createPaymentBacApiPaymentGateway } from 'api/paymentApi';
import Input from 'shared/Input/Input';
import { UseLoadListingCarPage } from 'containers/ListingCarPages/hooks/useLoadListingCarPage';
import ImageUploadPreview from 'components/ImagePreview/ImagePreview';
import Select from 'shared/Select/Select';
import { UserIcon } from '@heroicons/react/solid';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQueryParams } from 'hooks/useQueryParams';
import { env } from 'env';
import PaymentModal from 'components/PaymentModal/PaymentModal';
import { IReservation } from 'types/Reservation';
import api from 'api/api';
import { ReservationResponse } from 'containers/PayPage/types/ReservationRespose';
import { ToastContainer, toast } from 'react-toastify';
import { useCustomerState } from 'hooks/useCustomerState';
import moment from 'moment';
import { useCountriesState } from 'hooks/useCountriesState';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Filters from './Filters';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import LocationInput from 'components/HeroSearchForm/LocationInput';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { getDayPrice } from 'helpers/reservations/calculatePrices';
import Calendar from 'react-calendar';
import DatePicker from 'react-date-picker';
import 'styles/DatePicker.scss';
import { TiDeleteOutline } from 'react-icons/ti';
import { AiOutlineCalendar, AiOutlineClose } from 'react-icons/ai';
import { BsPencilSquare } from 'react-icons/bs';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Button from 'shared/Button/Button';
import React from 'react';
import { CreditCard } from 'components/CreditCard/CreditCard';
import NcModal from 'shared/NcModal/NcModal';
import PaymentFailedIconCreditCard from "../../images/credit-card.png";

interface CheckOutPageProps {
  className?: string;
}

type Inputs = {
  names: string;
  email: string;
  phone: string;
  license_number: string;
  passport_number: string;
  id_pais: string;
  document_expiration_date: string;
  entrance_date: string;
  deliverySpecs: string;
  paymentType: number;
  requiredDriver: boolean;
  namesD: string;
  emailD: string;
  phoneD: string;
  license_numberD: string;
  passport_numberD: string;
  customPrice?: string;
};

type Driver = {
  fullname: string;
  email: string;
  license_number: string;
  national_foreign: string;
  driver_license_picture: File;
  personal_identification_picture: File;
  origin_country: string;
  date_of_arrival: string;
  personal_document_expiration_date: string;
  flight_number: string;
};

type ReservationData = {
  hour: string;
  subtotal: number;
  total: number;
  taxes: number;
  dayPrice: number;
  days: number;
  startDate: string;
  endDate: string;
  reservationData: IReservation;
};

interface PaymentModalProps {
  setResgisterStatus: Dispatch<SetStateAction<number>>;
  submitPayment: boolean | null;
  reservation: ReservationResponse | null;
}

interface PaymentForm {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  email: string;
  cardNumber: string;
  cardCvv: string;
  cardHolderName: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
}


interface PaymentModalProps {
  //   reservationData: IReservation;
  setResgisterStatus: Dispatch<SetStateAction<number>>;
  submitPayment: boolean | null;
  reservation: ReservationResponse | null;
}


const ReservationCheckout: FC<CheckOutPageProps> = ({ className = '' }) => {
  /** REDUCERS */
  UseLoadListingCarPage();
  const queryParams = useQueryParams();
  let history = useHistory();
  const myForm = useRef();

  /** STATES */
  const { state: reservationData }: { state: ReservationData } = useLocation();
  const [isOpenBacPaymentModal, setIsOpenBacPaymentModal] = useState(false);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [country, setCountry] = useState<number>(53);
  const [reservationResponse, setReservationResponse] =
    useState<ReservationResponse | null>(null);
  const [registerStatus, setResgisterStatus] = useState<number>(0);
  const [requiredDocuments, setRequiredDocuments] = useState<boolean>(false);
  const [requiredDriver, setRequiredDriver] = useState<boolean>(false);
  const [cardOption, setCardOption] = useState<boolean>(false);
  const [paypalOption, setPaypalOption] = useState<boolean>(false);
  const [venmoOption, setVenmoOption] = useState<boolean>(false);
  const [zelleOption, setZelleOption] = useState<boolean>(false);
  const [counterOption, setCounterOption] = useState<boolean>(false);
  const [disablePaypal, setDisablePaypal] = useState<boolean>(false);
  const [disableCard, setDisableCard] = useState<boolean>(false);
  const [disableVenmo, setDisableVenmo] = useState<boolean>(false);
  const [disableZelle, setDisableZelle] = useState<boolean>(false);
  const [disableCounter, setDisableCounter] = useState<boolean>(false);
  const [requireTermsConditions, setTermsConditions] = useState<boolean>(false);
  const [done, setDone] = useState<Boolean | string>(false);
  const [isEditingPrice, setIsEditingPrice] = useState<boolean>(false);
  const [customPrice, setCustomPrice] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [phone, setPhone] = useState<any>();
  const [phoneD, setPhoneD] = useState<any>();
  const [paymentType, setPaymentType] = useState<any>();
  const currentItemId = Number(queryParams.get('vehicle_id'));
  const methods = useForm<Inputs>();
  const { t } = useTranslation();
  let { countries } = useCountriesState();
  const { customer, fetchCustomerStatus, error } = useCustomerState();
  const id_agent = customer?.id_agent || 1;
  const vehicle: Item | undefined = useAppSelector((state) =>
    carsSelector.selectById(state, reservationData.reservationData.id_item)
  );
  const [isLoading, setIsLoading] = useState(false);
  const initialOptions = {
    clientId:
      'Adu_H1qsg8asUs-J9vwpuNER__pQrUtWkaorB-jpWjNeanpQlDHQCUd9JI_6LTGAMuvUFHwtv50bzFYV',
    currency: 'USD',
    intent: 'capture',
    //'disable-funding': 'card',
  };

  const onSelectCheckbox = (id: number) => {
    if (id == 1) {
      setPaypalOption(false);
      setDisablePaypal(false);
      setDisableCard(true);
      setCardOption(true);
      setDisableVenmo(false);
      setVenmoOption(false);
      setDisableZelle(false);
      setDisableCounter(false);
      setZelleOption(false);
      setCounterOption(false);

    }
    if (id == 2) {
      setPaypalOption(true);
      setDisablePaypal(true);
      setDisableCard(false);
      setCardOption(false);
      setDisableVenmo(false);
      setVenmoOption(false);
      setDisableZelle(false);
      setDisableCounter(false);
      setZelleOption(false);
      setCounterOption(false);
    }
    if (id == 3) {
      setPaypalOption(false);
      setDisablePaypal(false);
      setDisableCard(false);
      setCardOption(false);
      setDisableVenmo(true);
      setVenmoOption(true);
      setDisableZelle(false);
      setDisableCounter(false);
      setZelleOption(false);
      setCounterOption(false);
    }
    if (id == 4) {
      setPaypalOption(false);
      setDisablePaypal(false);
      setDisableCard(false);
      setCardOption(false);
      setDisableVenmo(false);
      setVenmoOption(false);
      setDisableZelle(true);
      setDisableCounter(false);
      setZelleOption(true);
      setCounterOption(false);
    }
    if (id == 5) {
      setPaypalOption(false);
      setDisablePaypal(false);
      setDisableCard(false);
      setCardOption(false);
      setDisableVenmo(false);
      setVenmoOption(false);
      setDisableZelle(false);
      setDisableCounter(true);
      setZelleOption(false);
      setCounterOption(true);
    }
  };


  /** FUNCTIONS */
  const closeModal = () => {
    setOpenModal(false);
  };

  const taxes = React.useMemo<number | null>(() => {
    if (customPrice) {
      return customPrice * 0.13;
    }
    return null;
  }, [customPrice]);

  const total = React.useMemo<number | null>(() => {
    if (customPrice && taxes) {
      return customPrice;
    }
    return null;
  }, [customPrice, taxes]);


  const handleInputChangeBac = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredDocuments(event.target.checked);
  };
  const handleDriverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredDriver(event.target.checked);
  };
  const handleTermsConditions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsConditions(event.target.checked);
  };

  useEffect(() => {
    if (!isOpenBacPaymentModal && registerStatus == 1) {
      history.push('/');
    }
  }, [isOpenBacPaymentModal]);

  const handleDeliverySpecsInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    methods.setValue('deliverySpecs', event.target.value);
  };

  const [state, setState] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focus: ""
  });
  const handleInputChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })

  }

  const handleFocusChange = (e: any) => {
    setState({
      ...state,
      focus: e.target.name
    })
  }

  const getAmount = () => {
    /*if (!reservationData) return '0';
    const temp = reservationData.subtotal + reservationData.taxes;
    return temp.toFixed(2);*/
    if (!reservationData) return '0';
    if (customPrice === null || total === null) {
      const temp = reservationData.subtotal + reservationData.taxes;
      return temp.toFixed(2);
    } else {
      const temp = total;
      return temp.toFixed(2);
    }
  };

  const createReservation = async () => {
    const data = methods.getValues();
    const { deliverySpecs } = data;
    const body = {
      id_agent,
      contact: {
        ...data,
        lastname: '',
        expiry: `${state.expiry.substring(0, 2)}/${state.expiry.substring(2)}`,
        amount: getAmount(),
        cvc: state.cvc,
        cardholderName: state.name,
        cardNumber: state.number,

      },
      ...reservationData.reservationData,
      init_time_reservation: moment(
        reservationData.reservationData.init_time_reservation,
        'hh:mm A'
      ).format('HH:mm'),
      end_time_reservation: moment(
        reservationData.reservationData.end_time_reservation,
        'hh:mm A'
      ).format('HH:mm'),
      deliverySpecs,
    };

    try {
      const response = await api.post('/createReservation', body);
      setLoading(false);
      const reservationResponse_ = response.data as ReservationResponse;
      if (!reservationResponse_.error) {
        setReservationResponse(reservationResponse_);
        setIsOpenBacPaymentModal(true);
        setResgisterStatus(1);
      } else {
        toast.error(t('reservationError'), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(t('reservationError'), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const onSubmitBac = async () => {
    const newPaymentDataAdapter: BacPaymentData = {
      amount: Number(getAmount()),
      cardExpiration: `${state.expiry.substring(0, 2)}/${state.expiry.substring(2)}`,
      cardholderName: state.name,
      cardPan: state.number,
      cardCvv: state.cvc,
    };

    if (!newPaymentDataAdapter.cardExpiration || !newPaymentDataAdapter.cardholderName || !newPaymentDataAdapter.cardPan || !newPaymentDataAdapter.cardCvv) {
      setOpenModal(true)
      setLoading(false);
    }
    const payToGateway = (await createPaymentBacApiPaymentGateway(
      newPaymentDataAdapter
    ));
    console.log('request del pago al bac', payToGateway);
    if (payToGateway.status === 200) {
      await createReservation();
    }
    if (payToGateway.status !== 200) {
      setLoading(false);
      setOpenModal(true)
    }
  };

  const handleSubmit = async () => {
    // Just not working example
    methods.setValue('phone', phone);
    if (phoneD != null) {
      methods.setValue('phoneD', phoneD);
    } else {
      methods.setValue('phoneD', phone);
    }
    methods.setValue('paymentType', paymentType);

    methods.setValue('requiredDriver', requiredDriver);

    if (customPrice && taxes) {
      reservationData.reservationData.discount_reservation =
        reservationData.subtotal - customPrice;
      reservationData.reservationData.subtotal_reservation = customPrice / 1.13;
      reservationData.reservationData.total_reservation = customPrice;
      reservationData.reservationData.taxes_reservation = customPrice / 1.13 * 0.13;
    }
    setLoading(true);

    if (cardOption === true) {
      await onSubmitBac();
    } else {
      await createReservation();
    }
    return true;
  };

  useEffect(() => {
    //if (customer?.id_agent) return;
    var idPais = (0).toString()
    if (customer != null) {
      idPais = (customer?.id_pais || 0).toString() || '';
      methods.setValue('names', customer?.name_customer || '');
      methods.setValue('email', customer?.email || '');
      methods.setValue('license_number', customer?.license_number || '');
      methods.setValue('requiredDriver', false);
      methods.setValue('phone', customer?.phone_customer || '');
      methods.setValue(
        'document_expiration_date',
        customer?.license_expiration_date || ''
      );
      methods.setValue('id_pais', '69');
      methods.setValue('paymentType', 1);
    } else {
      methods.setValue('names', '');
      methods.setValue('email', '');
      methods.setValue('license_number', '');
      methods.setValue('requiredDriver', false);
      methods.setValue('phone', '');
      methods.setValue('document_expiration_date', '');
      methods.setValue('id_pais', '69');
      methods.setValue('paymentType', 1);
    }

    //setRequiredDocuments(!customer?.license_url);
  }, [customer]);

  useEffect(() => {
    localStorage.setItem(
      'reservation_data',
      JSON.stringify({
        hour: reservationData.hour,
        subtotal: reservationData.subtotal,
        taxes: reservationData.taxes,
        dayPrice: reservationData.dayPrice,
        days: reservationData.days,
        startDate: reservationData.startDate,
        endDate: reservationData.endDate,
        reservationData: reservationData.reservationData,
      })
    );
  }, []);

  return (
    <AuthUser>
      <NcModal
        isOpenProp={openModal}
        onCloseModal={() => closeModal()}
        modalTitle={t("Payment failed")}
        contentPaddingClass="p-4"
        triggerText={null}
      >
        <div className="flex justify-center">
          <div className="w-full h-64 overflow-y-auto rounded-lg border-gray-200 p-2 text-justify">
            <span className="block text-sm text-slate-500">
              <div className="flex justify-center sm:(min-width: 380px) mt-3">
                <img src={PaymentFailedIconCreditCard} alt="success" width="15%" />
              </div>
              <div className="flex justify-center sm:(min-width: 380px) mt-3">
                <p className="text-2xl font-bold text-red-600">{t("Error payment processing")}</p>
              </div>
              <div className="flex justify-center sm:(min-width: 380px) mt-3">
                <p className="text-sm font-bold text-slate-600">{t("This error may be caused by any causes, try again or if you still have problems contact us.")}</p>
              </div>
            </span>
          </div>
        </div>
      </NcModal>
      <main className="relative">
        <div className="nc-ListingStayDetailPage w-full lg:w-4/5 mx-auto my-4 py-4 grid sm:grid-cols-3 grid-rpws-2">
          <div className="sm:col-span-2 p-12 shadow-md m-4">
            <h2 className="text-4xl font-semibold mb-4">
              {t('Driver Information')}
            </h2>
            <PayPalScriptProvider options={initialOptions}>
              <FormProvider {...methods}>
                <form action="" onSubmit={methods.handleSubmit(handleSubmit)}>
                  <FormControl
                    id="names"
                    name={`names`}
                    label={t('DriverName')}
                    className={'mb-4'}
                    registerOptions={{
                      required: requiredRule(t('requiredField')),
                      validate: trimInputRule(t('invalidField')),
                      maxLength: maxLengthRule(128, t('invalidField')),
                    }}
                  >
                    {(field: any) => (
                      <Input
                        type="text"
                        placeholder="Passenger Name"
                        {...field}
                      />
                    )}
                  </FormControl>
                  <FormControl
                    id="passenger-email"
                    name={`email`}
                    label={t('email')}
                    className={'mb-4'}
                    registerOptions={{
                      required: requiredRule(t('requiredField')),
                      validate: trimInputRule(t('invalidField')),
                      maxLength: maxLengthRule(128, t('invalidField')),
                    }}
                  >
                    {(field: any) => (
                      <Input
                        type="text"
                        placeholder="Passenger Email"
                        {...field}
                      />
                    )}
                  </FormControl>
                  <FormControl
                    id="phone"
                    name={`phone`}
                    label={t('phoneNumber')}
                  >
                    {(field: any) => (
                      <PhoneInput
                        placeholder={t('EnterPhone')}
                        required={true}
                        value={phone}
                        className="dark:bg-neutral-900"
                        onChange={(value) => {
                          setPhone(value);
                        }}
                      />
                      // <Input
                      //   type="text"
                      //   placeholder="Ex: +1 201 555 0123"
                      //   {...field}
                      // />
                    )}
                  </FormControl>
                  <FormControl
                    id="license_number"
                    name={`license_number`}
                    label={t('LicenseNumber')}
                    className={'mb-4'}
                    registerOptions={{
                      required: requiredRule(t('requiredField')),
                      validate: trimInputRule(t('invalidField')),
                      maxLength: maxLengthRule(128, t('invalidField')),
                    }}
                  >
                    {(field: any) => (
                      <Input
                        type="text"
                        placeholder="Driving License Number"
                        {...field}
                      />
                    )}
                  </FormControl>
                  <FormControl
                    id="passport_number"
                    name={`passport_number`}
                    label={t('PassportNumber')}
                    className={'mb-4'}
                    registerOptions={{
                      required: requiredRule(t('requiredField')),
                      validate: trimInputRule(t('invalidField')),
                      maxLength: maxLengthRule(128, t('invalidField')),
                    }}
                  >
                    {(field: any) => (
                      <Input type="text" placeholder="000" {...field} />
                    )}
                  </FormControl>
                  <br />
                  <div className="flex items-center">
                    <Input
                      id="required-documents-checkbox"
                      type="checkbox"
                      onChange={handleDriverChange}
                      checked={requiredDriver}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="required-documents-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {t('addDriverQuestion')}
                    </label>
                  </div>

                  <br />
                  {requiredDriver && (
                    <FormControl
                      id="namesD"
                      name={`namesD`}
                      label={t('DriverNameD')}
                      className={'mb-4'}
                      registerOptions={{
                        required: requiredRule(t('requiredField')),
                        validate: trimInputRule(t('invalidField')),
                        maxLength: maxLengthRule(128, t('invalidField')),
                      }}
                    >
                      {(field: any) => (
                        <Input
                          type="text"
                          placeholder="Passenger Name"
                          {...field}
                        />
                      )}
                    </FormControl>
                  )}
                  {requiredDriver && (
                    <FormControl
                      id="passenger-emailD"
                      name={`emailD`}
                      label={t('emailD')}
                      className={'mb-4'}
                      registerOptions={{
                        required: requiredRule(t('requiredField')),
                        validate: trimInputRule(t('invalidField')),
                        maxLength: maxLengthRule(128, t('invalidField')),
                      }}
                    >
                      {(field: any) => (
                        <Input
                          type="text"
                          placeholder="Passenger Email"
                          {...field}
                        />
                      )}
                    </FormControl>
                  )}
                  {requiredDriver && (
                    <FormControl
                      id="phoneD"
                      name={`phoneD`}
                      label={t('phoneNumberD')}
                    >
                      {(field: any) => (
                        <PhoneInput
                          placeholder={t('EnterPhone')}
                          required={false}
                          value={phoneD}
                          className="dark:bg-neutral-900"
                          onChange={(value) => {
                            setPhoneD(value);
                          }}
                        />
                        // <Input
                        //   type="text"
                        //   placeholder="Ex: +1 201 555 0123"
                        //   {...field}
                        // />
                      )}
                    </FormControl>
                  )}
                  {requiredDriver && (
                    <FormControl
                      id="license_numberD"
                      name={`license_numberD`}
                      label={t('LicenseNumberD')}
                      className={'mb-4'}
                      registerOptions={{
                        required: requiredRule(t('requiredField')),
                        validate: trimInputRule(t('invalidField')),
                        maxLength: maxLengthRule(128, t('invalidField')),
                      }}
                    >
                      {(field: any) => (
                        <Input
                          type="text"
                          placeholder="Driver's License Number"
                          {...field}
                        />
                      )}
                    </FormControl>
                  )}
                  {requiredDriver && (
                    <FormControl
                      id="passport_numberD"
                      name={`passport_numberD`}
                      label={t('PassportNumberD')}
                      className={'mb-4'}
                      registerOptions={{
                        required: requiredRule(t('requiredField')),
                        validate: trimInputRule(t('invalidField')),
                        maxLength: maxLengthRule(128, t('invalidField')),
                      }}
                    >
                      {(field: any) => (
                        <Input type="text" placeholder="000" {...field} />
                      )}
                    </FormControl>
                  )}
                  <br />
                  <div className="flex items-center">
                    <Input
                      id="required-documents-checkbox"
                      type="checkbox"
                      onChange={handleInputChange}
                      checked={requiredDocuments}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="required-documents-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {t('addIDQuestion')}
                    </label>
                  </div>
                  <br />
                  {requiredDocuments && (
                    <FormControl
                      id="passenger-national-foreign"
                      name={`national-foreign`}
                      label={t('NationalOrForeign')}
                      className={'mb-4'}
                      registerOptions={{
                        required: requiredRule(t('requiredField')),
                      }}
                    >
                      {(field: any) => (
                        <>
                          <div className="flex items-center mb-4">
                            <Input
                              {...field}
                              defaultChecked
                              id="default-radio-1"
                              type="radio"
                              value={'national'}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="default-radio-1"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {t('NationalIdentification')}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <Input
                              {...field}
                              id="default-radio-2"
                              type="radio"
                              value={'foreign'}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="default-radio-2"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {t('Passport')}
                            </label>
                          </div>
                        </>
                      )}
                    </FormControl>
                  )}

                  {requiredDocuments && (
                    <>
                      <FormControl
                        id="passenger-full-name"
                        name={`licencia-de-conducir`}
                        label={t('DriverLicensePicture')}
                        className={'mb-4'}
                        registerOptions={{
                          required: requiredRule(t('requiredField')),
                        }}
                      >
                        {(field: any) => <ImageUploadPreview {...field} />}
                      </FormControl>
                      <FormControl
                        id="passenger-full-name"
                        name={`personal_document`}
                        label={t('PersonalDocumentPicture')}
                        className={'mb-4'}
                        registerOptions={{
                          required: requiredRule(t('requiredField')),
                        }}
                      >
                        {(field: any) => <ImageUploadPreview {...field} />}
                      </FormControl>
                    </>
                  )}
                  <FormControl
                    id="id_pais"
                    name={`id_pais`}
                    label={t('OriginContry')}
                    className={'mb-4 text-red'}
                    registerOptions={{
                      required: requiredRule(t('requiredField')),
                      validate: trimInputRule(t('invalidField')),
                      maxLength: maxLengthRule(128, t('invalidField')),
                    }}
                  >


                    {(field: any) => (
                      <Select className="mt-2" name="country" {...field}>
                        {countries?.map((country) => (
                          <option
                            key={`list-country-${country.id_pais}`}
                            value={country.id_pais}
                          >
                            {country.nombre_pais}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                  {/* <FormControl
                    id="passenger-full-name"
                    name={`entrance_date`}
                    label={t('DateOfArrival')}
                    className={'mb-4'}
                    registerOptions={{
                      required: requiredRule(t('requiredField')),
                      validate: trimInputRule(t('invalidField')),
                      maxLength: maxLengthRule(128, t('invalidField')),
                    }}
                  >
                    {(field: any) => <Input type={'date'} {...field} />}
                  </FormControl> */}
                  <Controller
                    control={methods.control}
                    name={`entrance_date`}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 mb-4">
                        <label htmlFor="passenger-full-name">
                          {t('DateOfArrival')}
                        </label>
                        <DatePicker
                          onChange={(date) => field.onChange(date)}
                          defaultValue={new Date()}
                          value={field.value}
                          required={true}
                          calendarClassName="text-base bg-neutral-50 w-96 max-w-full dark:bg-neutral-700 border-neutral-200 dark:border-neutral-500 leading-5 p-5"
                          clearIcon={<TiDeleteOutline size={20} />}
                          calendarIcon={<AiOutlineCalendar size={20} />}
                          className="w-full rounded-2xl bg-neutral-100 dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-700 dark:text-neutral-300"
                        />
                      </div>
                    )}
                  />
                  {/* <Controller
                    control={methods.control}
                    name={`document_expiration_date`}
                    render={({ field }) => (
                      <Calendar
                        onChange={(date) => field.onChange(date)}
                        defaultValue={new Date()}
                        value={field.value}
                      />
                    )}
                  /> */}
                  <Controller
                    control={methods.control}
                    name={`document_expiration_date`}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 mb-4">
                        <label htmlFor="passenger-full-name">
                          {t('PersonalDocumentExpirationDate')}
                        </label>
                        <DatePicker
                          onChange={(date) => field.onChange(date)}
                          defaultValue={new Date()}
                          value={field.value}
                          required={true}
                          calendarClassName="text-base bg-neutral-50 w-96 max-w-full dark:bg-neutral-700 border-neutral-200 dark:border-neutral-500 leading-5 p-5"
                          clearIcon={<TiDeleteOutline size={20} />}
                          calendarIcon={<AiOutlineCalendar size={20} />}
                          className="w-full rounded-2xl bg-neutral-100 dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-700 dark:text-neutral-300"
                        />
                      </div>
                    )}
                  />
                  <FormControl
                    id="passenger-full-name"
                    name={`flight_number`}
                    label={t('FlightNumber')}
                    className={'mb-4'}
                  >
                    {(field: any) => (
                      <Input
                        type="text"
                        placeholder={t(`FlightNumber`)}
                        {...field}
                      />
                    )}
                  </FormControl>
                  <FormControl
                    id="paymentType"
                    name={`paymentType`}
                    className={'mb-4'}
                  >
                    {(field: any) => <Input type="hidden" value="1" />}
                  </FormControl>
                  <div className="flex items-center justify-around">
                    <div className="flex">
                      <Input
                        id="terms-checkbox"
                        type="checkbox"
                        onChange={handleTermsConditions}
                        checked={requireTermsConditions}
                        required={true}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="terms-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        style={{ marginRight: '.5rem' }}
                      >
                        {t(`TermsConditions`)}
                      </label>
                    </div>
                    <Filters />
                  </div>
                  <br />

                  {/* <ButtonPrimary disabled={loading ? true: false}>
                                    {loading ? t("loading") + '...' : 'Confirm' ?? (id_agent ? 'Confirm' : 'Confirm and Pay')}
                                </ButtonPrimary> */}
                  <div className="grid grid-cols-2 xl:grid-cols-4 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
                    {/* <CheckBoxPayment
                      image={
                        'https://i.ibb.co/jkwYfGq/Captura-de-Pantalla-2023-07-24-a-la-s-14-53-51.png'
                      }
                      title={'Pay with'}
                      description={'Card'}
                      isSelected={disableCard}
                      onChange={() => onSelectCheckbox(1)}
                      disabled={false}
                    />*/}
                    <CheckBoxPayment
                      image={'https://i.ibb.co/6DknQCz/paypal.png'}
                      title={'Pay with'}
                      description={'Paypal'}
                      isSelected={disablePaypal}
                      onChange={() => onSelectCheckbox(2)}
                      disabled={false}
                    />
                    <CheckBoxPayment
                      image={'https://i.ibb.co/1QmZ0nR/venmo.png'}
                      title={'Pay with'}
                      description={'Venmo'}
                      isSelected={disableVenmo}
                      onChange={() => onSelectCheckbox(3)}
                      disabled={false}
                    />
                    <CheckBoxPayment
                      image={'https://i.ibb.co/kD7VyFP/zelle.png'}
                      title={'Pay with'}
                      description={'Zelle'}
                      isSelected={disableZelle}
                      onChange={() => onSelectCheckbox(4)}
                      disabled={false}
                    />
                    <CheckBoxPayment
                      image={'https://i.ibb.co/BwT5Dwq/1727567.png'}
                      title={'Pay at'}
                      description={'Counter'}
                      isSelected={disableCounter}
                      onChange={() => onSelectCheckbox(5)}
                      disabled={false}
                    />
                  </div>
                  <br />
                  {paypalOption && (
                    <div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h2 className="text-4xl font-semibold mb-4">
                          {t('Paypal Payment')}
                        </h2>
                      </div>
                      <br />
                      <div
                        id="paypaldiv"
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <PayPalButtons
                          style={{
                            layout: 'horizontal',
                            shape: 'pill',
                            height: 50,
                            tagline: false,
                          }}
                          onClick={async (data, actions) => {
                            const isValidForm = await methods.trigger();
                            if (!isValidForm) {
                              toast.error('Complete the required fields.');
                              return actions.reject();
                            } else {
                              if (!requireTermsConditions) {
                                toast.error('Accept terms and conditions');
                                return actions.reject();
                              } else {
                                return actions.resolve();
                              }
                            }
                          }}
                          createOrder={(data, actions) => {
                            const amount = getAmount();
                            const description = 'rent a car';
                            return actions.order.create({
                              purchase_units: [
                                {
                                  description: description,
                                  amount: {
                                    value: amount,
                                  },
                                },
                              ],
                            });
                          }}
                          onApprove={(data, actions) => {
                            return actions.order
                              ? actions.order
                                .capture()
                                .then(async (details) => {
                                  setPaymentType(2);
                                  await handleSubmit();
                                })
                              : new Promise((resolve, reject) => reject());
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {cardOption && (
                    <div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h2 className="text-4xl font-semibold mb-4">
                          {t('Credit/Debit Payment')}
                        </h2>
                      </div>
                      <br />
                      <div
                        id="paypaldiv"
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FormProvider {...methods}>
                          <form
                            className="flex flex-col gap-4"
                            onSubmit={methods.handleSubmit(handleSubmit)}
                          >

                            <div className="w-full px-0 sm:px-8 lg:col-span-9 xl:col-span-6">
                              <CreditCard
                                number={state.number}
                                name={state.name}
                                expiry={state.expiry}
                                cvc={state.cvc}
                                focused={state.focus}
                              />
                            </div>
                            <div className="w-full flex justify-center">
                              <form>
                                <div className="w-full flex gap-2">
                                  <FormControl
                                    id="payment-modal-cardNumber"
                                    name="number"
                                    label={t("paymentModal-cardNumber")}
                                    registerOptions={{
                                      required: requiredRule(t("requiredField")),
                                      pattern: patternRule(/^[0-9]+$/),
                                      validate: trimInputRule(t("invalidField")),
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        autoFocus
                                        className="form-control"
                                        onChange={handleInputChange}
                                        onFocus={handleFocusChange}
                                        type="text"
                                        maxLength={16}
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        {...field}
                                      />
                                    )}
                                  </FormControl>

                                  <FormControl
                                    id="payment-modal-cardCvv"
                                    name="cvc"
                                    label="CVC"
                                    registerOptions={{
                                      required: requiredRule(t("requiredField")),
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: t("invalidField")
                                      },
                                      validate: trimInputRule(t("invalidField"))
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        className="form-control"
                                        type="number"
                                        onKeyDown={(e) => {
                                          if (state.cvc.length >= 3 && e.key !== "Backspace") {
                                            e.preventDefault();
                                            return false;
                                          }
                                        }}
                                        placeholder="XXX"
                                        onChange={handleInputChange}
                                        onFocus={handleFocusChange}
                                        {...field}
                                      />
                                    )}
                                  </FormControl>

                                </div>

                                <div className="w-full flex gap-2 items-end">
                                  <FormControl
                                    id="payment-modal-cardHolderName"
                                    name="name"
                                    label={t("paymentModal-cardHolderName")}
                                    registerOptions={{
                                      required: requiredRule(t("requiredField")),
                                      validate: trimInputRule(t("invalidField")),
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        type="text"
                                        className="form-control"
                                        maxLength={20}
                                        onChange={handleInputChange}
                                        onFocus={handleFocusChange}
                                        placeholder="John Doe" {...field} />
                                    )}
                                  </FormControl>

                                  <FormControl
                                    id="payment-modal-cardExpiration"
                                    name="expiry"
                                    className="w-1/2"
                                    label={t("paymentModal-cardExpiration")}
                                    registerOptions={{
                                      required: requiredRule(t("requiredField")),
                                      validate: trimInputRule(t("invalidField")),
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        required
                                        type="number"
                                        className="form-control"
                                        onKeyDown={(e) => {
                                          if (state.expiry.length >= 4 && e.key !== "Backspace") {
                                            e.preventDefault();
                                            return false;
                                          }
                                        }}
                                        placeholder={t("xxxx")}
                                        onChange={handleInputChangeBac}
                                        onFocus={handleFocusChange}
                                        {...field}
                                      />
                                    )}
                                  </FormControl>
                                </div>

                                {/* <button onClick={onSubmit} type="button" className="btn btn-success btn-block btn-lg">Pagar</button> */}
                              </form>
                            </div>
                            {/* <section>
                              <div className="flex flex-col gap-2">
                                <div className="w-full flex gap-2">
                                  <FormControl
                                    className="w-2/3"
                                    id="payment-modal-cardNumber"
                                    name="cardNumber"
                                    label={t('paymentModal-cardNumber')}
                                    registerOptions={{
                                      required: requiredRule(
                                        t('requiredField')
                                      ),
                                      pattern: patternRule(
                                        /^[0-9]{15,16}$/,
                                        t('invalidCardNumber')
                                      ),
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        type="number"
                                        maxLength={12}
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        {...field}
                                      />
                                    )}
                                  </FormControl>

                                  <FormControl
                                    id="payment-modal-cardCvv"
                                    name="cardCvv"
                                    className="w-1/3"
                                    label="CVV"
                                    registerOptions={{
                                      required: requiredRule(
                                        t('requiredField')
                                      ),
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        type="number"
                                        maxLength={3}
                                        placeholder="XXX"
                                        {...field}
                                      />
                                    )}
                                  </FormControl>
                                </div>

                                <div className="w-full flex gap-2 items-end">
                                  <FormControl
                                    id="payment-modal-cardHolderName"
                                    name="cardHolderName"
                                    label={t('paymentModal-cardHolderName')}
                                    className="w-2/3"
                                    registerOptions={{
                                      required: requiredRule(
                                        t('requiredField')
                                      ),
                                    }}
                                  >
                                    {(field: any) => (
                                      <Input
                                        placeholder="John Doe"
                                        {...field}
                                      />
                                    )}
                                  </FormControl>

                                  <div className="w-1/3 flex flex-col">
                                    <h6>{t('paymentModal-cardExpiration')}</h6>
                                    <div className="flex gap-2">
                                      <FormControl
                                        id="payment-modal-cardExpiration"
                                        name="cardExpirationMonth"
                                        className="w-1/2"
                                        registerOptions={{
                                          required: requiredRule(
                                            t('requiredField')
                                          ),
                                        }}
                                      >
                                        {(field: any) => (
                                          <Input
                                            type="number"
                                            maxLength={4}
                                            placeholder={t('month')}
                                            {...field}
                                          />
                                        )}
                                      </FormControl>

                                      <FormControl
                                        id="payment-modal-cardExpiration"
                                        name="cardExpirationYear"
                                        className="w-1/2"
                                        registerOptions={{
                                          required: requiredRule(
                                            t('requiredField')
                                          ),
                                        }}
                                      >
                                        {(field: any) => (
                                          <Input
                                            type="number"
                                            maxLength={4}
                                            placeholder={t('year')}
                                            {...field}
                                          />
                                        )}
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section> */}

                            <ButtonPrimary onClick={() => setPaymentType(1)}>
                              {t('Pay Now')}
                            </ButtonPrimary>
                          </form>
                        </FormProvider>
                      </div>
                    </div>
                  )}

                  {venmoOption && (
                    <div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h2 className="text-4xl font-semibold mb-4">
                          {t('Venmo Payment')}
                        </h2>
                      </div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <p className="justify-text">
                          You can make the payment to <b>@darroyoch009 </b>and
                          sending the receipt to the email:
                          info@rentacarsummer.com. Remember that you have 24
                          hours to make the payment, after that time, if the
                          payment has not been received, the rent is
                          automatically deleted
                        </p>
                      </div>

                      <br />
                      <div
                        id="paypaldiv"
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ButtonPrimary onClick={() => setPaymentType(3)}>
                          {t('Rent Now')}
                        </ButtonPrimary>
                      </div>
                    </div>
                  )}

                  {zelleOption && (
                    <div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h2 className="text-4xl font-semibold mb-4">
                          {t('Zelle Payment')}
                        </h2>
                      </div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <p className="justify-text">
                          You can make the payment to{' '}
                          <b>diegoa10r@hotmail.com </b>and sending the receipt
                          to the email: info@rentacarsummer.com. Remember that
                          you have 24 hours to make the payment, after that
                          time, if the payment has not been received, the rent
                          is automatically deleted
                        </p>
                      </div>
                      <br />
                      <div
                        id="paypaldiv"
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ButtonPrimary onClick={() => setPaymentType(4)}>
                          {t('Rent Now')}
                        </ButtonPrimary>
                      </div>
                    </div>
                  )}
                  {counterOption && (
                    <div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h2 className="text-4xl font-semibold mb-4">
                          {t('Pay at Counter')}
                        </h2>
                      </div>
                      <div
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <p className="justify-text">
                          You can book without paying, and you will be contacted by our agents to coordinate payment and rental details.
                        </p>
                      </div>
                      <br />
                      <div
                        id="paypaldiv"
                        className="w-full"
                        style={{
                          display: 'flex',
                          margin: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ButtonPrimary onClick={() => setPaymentType(5)}>
                          {t('Rent Now')}
                        </ButtonPrimary>
                      </div>
                    </div>
                  )}
                </form>
              </FormProvider>
            </PayPalScriptProvider>
          </div>
          <div className="w-full relative order-first px-8 sm:px-0 sm:order-last">
            <div className="shadow-md p-8 md:md-0 sticky top-32">
              <h2 className="text-3xl mb-2 font-semibold">
                {vehicle?.detail_item}
              </h2>
              {vehicle?.images && vehicle?.images.length > 0 && (
                <img
                  className="mb-2"
                  src={`${env}/${vehicle?.images[0].url_item_image}`}
                  alt=""
                />
              )}
              <div>
                <div className="flex items-center">
                  <UserIcon width={15} height={15} className="mr-2" />
                  <p>
                    {t('startDate')}: {reservationData.startDate} -{' '}
                    {reservationData.reservationData.init_time_reservation}
                  </p>
                </div>
                <div className="flex items-center">
                  <UserIcon width={15} height={15} className="mr-2" />
                  <p>
                    {t('endDate')}: {reservationData.endDate} -{' '}
                    {reservationData.reservationData.end_time_reservation}
                  </p>
                </div>
              </div>
              {/* PRICE */}
              <div className="flex justify-between" style={{ marginTop: 10 }}>
                <span className="">
                  ${reservationData.dayPrice} * {reservationData.days}{' '}
                  {t('pluralDay').toLowerCase()}
                </span>
              </div>
              <div
                className="flex justify-between mb-2"
                style={{ marginTop: 10 }}
              >
                <span className="text-3xl font-semibold">
                  $
                  {customPrice === null || total === null
                    ? reservationData.total.toFixed(2)
                    : total.toFixed(2)}
                  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                    Total
                  </span>
                </span>
                {(customer?.id_agent !== null && customer?.id_agent !== undefined) ? (
                  <ButtonPrimary
                    onClick={() => setIsEditingPrice(!isEditingPrice)}
                  >
                    <BsPencilSquare />
                  </ButtonPrimary>
                ) : (
                  ''
                )}
              </div>
              {isEditingPrice ? (
                <div className="flex w-full justify-between items-center mt-4">
                  <FormProvider {...methods}>
                    <form
                      action=""
                      onSubmit={methods.handleSubmit(handleSubmit)}
                    >
                      <FormControl
                        id="customPrice"
                        name="customPrice"
                        className="w-36"
                        label="Set custom price"
                      >
                        {(field: any) => (
                          <Input
                            fontClass="text-lg dark:text-neutral-100 font-normal"
                            type="number"
                            maxLength={10}
                            placeholder="0.00"
                            step={0.01}
                            {...field}
                          />
                        )}
                      </FormControl>
                    </form>
                  </FormProvider>

                  <div className="flex flex-col space-y-2">
                    <Button
                      className="border-2 border-red-500 text-red-500 self-center rounded-md"
                      onClick={() => setCustomPrice(null)}
                      sizeClass="px-4 py-1 sm:px-4"
                    >
                      <AiOutlineClose size={15} />
                    </Button>
                    <ButtonPrimary
                      fontSize="text-xs"
                      onClick={() => {
                        const temp = methods.getValues('customPrice');
                        if (temp !== undefined && temp !== null) {
                          setCustomPrice(parseFloat(temp));
                        }
                      }}
                    >
                      {t('apply').toLowerCase()}
                    </ButtonPrimary>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="py-4">
                <label
                  htmlFor="deliverySpecs"
                  className="block text-lg font-medium text-gray-700 dark:text-gray-100"
                >
                  {t('deliverySpecifications')}
                </label>
                <input
                  id="deliverySpecs"
                  name="deliverySpecs"
                  type="text"
                  onChange={handleDeliverySpecsInputChange}
                  className="mt-2 p-2 border border-gray-300 dark:bg-neutral-900 dark:border-neutral-500 dark:text-white rounded-md w-full"
                  placeholder={t('deliverySpecificationsPlaceholder')}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <PaymentModal
        isOpen={isOpenBacPaymentModal}
        onCloseModal={setIsOpenBacPaymentModal}
        country={country}
        reservationResponse={reservationResponse}
        registerStatus={registerStatus}
        setResgisterStatus={setResgisterStatus}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AuthUser>
  );
};

export default ReservationCheckout;
