import React, { useState } from 'react';

interface CheckBoxProps {
    image: string;
    title: string;
    description: string;
    isSelected: boolean;
    disabled?: boolean;
    onChange: (isChecked: boolean) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
    image,
    title,
    description,
    isSelected,
    disabled = false,
    onChange,
}) => {
    const [backgroundColor, setBackgroundColor] = useState<string>(isSelected ? 'bg-blue-100' : 'bg-white');
    const [localSelected, setLocalSelected] = useState<boolean>(isSelected);
    const handleClick = () => {
        if (disabled) {
            return;
        }
        setLocalSelected(!localSelected);
        onChange(localSelected);
        //setBackgroundColor(!localSelected ? 'bg-blue-100' : 'bg-white');

    };

    return (
        <div
            className={`border rounded-lg p-4 cursor-pointer h-full ${disabled ? 'opacity-50 pointer-events-none' : ''} ${backgroundColor}`}
            onClick={handleClick}
        >
            <div className="h-full flex items-center">
                <img className="w-1/3 h-full object-cover rounded-md mr-4" src={image} alt={title} />
                <div className="flex-1 flex flex-col justify-center">
                    <h2 className="text-m font-medium">{title}</h2>
                    <p className="font-bold text-xl">{description}</p>
                </div>
            </div>
            <input
                type="checkbox"
                className="hidden"
                onChange={() => { }}
                disabled={disabled}
            />
        </div>
    );
};

export default CheckBox;
