import React, { FC, useEffect, useState } from 'react';
import Logo from 'shared/Logo/Logo';
import Navigation from 'shared/Navigation/Navigation';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import MenuBar from 'shared/MenuBar/MenuBar';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { useCustomerState } from 'hooks/useCustomerState';
import { useTranslation } from 'react-i18next';
import NcDropDown from 'shared/NcDropDown/NcDropDown';
import { useAppDispatch } from 'store/hooks/useAppStore';
import { customerActions } from 'store/slices/customerSlice';
import { useHistory } from 'react-router-dom';
import AccountDropdown from './AccountDropdown';
import {
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaWaze,
} from 'react-icons/fa';
import { SocialType } from 'shared/SocialsShare/SocialsShare';

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const [customer, setCustomerData] = useState<any | null>(
    JSON.parse(localStorage.getItem('customer') || 'null')
  );

  const customerData = useCustomerState();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const logout = async () => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('customer');
    localStorage.removeItem('id_customer');
    setCustomerData(null);
    dispatch(customerActions.resetCustomerData());
    history.push('/');
  };

  useEffect(() => {
    setCustomerData(JSON.parse(localStorage.getItem('customer') || 'null'));
  }, [customerData]);

  return (
    <div className={`nc-MainNav1 relative z-10 notOnTop backdrop-filter`}>
      <div className="header flex justify-between items-center py-2 px-4 sm:px-12 bg-primary-500 text-white">
        <div className="contact-info flex items-center mr-1">
          <div className="phone flex items-center mr-3 sm:mr-6 md:mr-16">
            <FaPhone className="mr-1" />
            <span className="text-md">+506 64056463</span>
          </div>
          <div className="email flex items-center">
            <FaEnvelope className="mr-1" />
            <span className="text-md">info@rentacarsummer.com</span>
          </div>
        </div>
        <div className="social-icons flex">
          <a
            href="https://www.facebook.com/summerrentacarcr"
            className="social-icon mr-2"
          >
            <FaFacebook className="social-icon mr-1 sm:mr-2 md:mr-4" />
          </a>
          <a href="https://waze.com/ul/hd1u0uh8fu" className="social-icon mr-2">
            <FaWaze className="social-icon mr-1 sm:mr-2 md:mr-4" />
          </a>
          {/* <a
            href="https://wa.me/message/AS6ZZCXPSPSQO1"
            className="social-icon mr-2"
          >
            <FaWhatsapp className="social-icon mr-1 sm:mr-2 md:mr-4" />
          </a> */}
          <a
            href="https://instagram.com/summercostaricarentacar?igshid=YmMyMTA2M2Y="
            className="social-icon mr-2"
          >
            <FaInstagram className="social-icon" />
          </a>
        </div>
      </div>
      <div className="container py-5 mx-1 flex justify-between">
        <div className="flex justify-start">
          <Logo />
          <div className="px-2" />
          <Navigation />
        </div>
        <div className="flex justify-center  items-center">
          <div className="text-center">
            <h1 className="text-7xl font-bold text-primary-500">Summer</h1>
            <h2 className="text-2xl text-gray-500">
              <b>Rent A Car Costa Rica</b>
            </h2>
          </div>
        </div>

        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <NcDropDown
              title={t('selectLanguage')}
              data={[
                { name: t('spanish'), id: 'es' },
                { name: t('english'), id: 'en' },
              ]}
              onClick={({ id }) => i18n.changeLanguage(id)}
            />
            {/* <SearchDropdown /> */}
            <div className="px-1" />
            {!!customer ? (
              <>
                <AccountDropdown
                  username={customer.name_customer}
                  isAgent={!(customer.id_agent == null)}
                />
                <ButtonSecondary className="outline-none" onClick={logout}>
                  Logout
                </ButtonSecondary>
              </>
            ) : (
              <div className="flex gap-4">
                <ButtonPrimary href="/signup">{t('auth-signup')}</ButtonPrimary>
                <ButtonSecondary className="outline-none" href="/login">
                  {t('auth-login')}
                </ButtonSecondary>
              </div>
            )}
          </div>
          <div className="flex items-center xl:hidden">
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
