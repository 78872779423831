import { useSearchCarsContext } from "containers/ListingCarPages/hooks/useSearchCarsContext";
import { useItemTypesState } from "hooks/useItemTypesState";
import { ItemType } from "types/Item/ItemType";
import ItemTypeButton from "./ItemTypeButton";

export interface SearchFiltersIcon extends ItemType {
  srcIcon: string;
}

const ItemTypeNav = () => {
  const { itemTypes } = useItemTypesState(); // Sedan | Truck | Small | 4x4 | Racing
  const { searchFilters, setSearchFilters } = useSearchCarsContext(); // Filters used on item searching

  const selectItemType = (itemType: string) =>
    setSearchFilters((prev) => ({
      ...prev,
      itemType: itemType === prev.itemType ? undefined : itemType,
    }));

  const formattedItems: SearchFiltersIcon[] = itemTypes.map((item) => ({
    ...item,
    srcIcon: item.public_route_image || `icons/searchFilters/${item.detail_item_type.toLowerCase()}.svg`,
  }));

  return (
    <nav className="w-full flex justify-center">
      <div className="grid grid-flow-row grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-x-12">
      {formattedItems.map((filterItem) => (
        <ItemTypeButton
          itemData={filterItem}
          onSelect={selectItemType}
          isSelected={searchFilters.itemType === filterItem.detail_item_type}
          key={filterItem.id_item_type}
        />
      ))}
      </div>
    </nav>
  );
};
export default ItemTypeNav;
