import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getModels } from "api/modelsApi";
import { CarModel } from "types/Item/Item";

export const fetchModels = createAsyncThunk('models/fetchModels', getModels);

const modelAdapter = createEntityAdapter<CarModel>({
  selectId: (item) => item.id,
  sortComparer: (a, b) => b.id - a.id
})

export const modelsSlice = createSlice({
  name: 'models',
  initialState: modelAdapter.getInitialState({
    error: false,
    fetchItemsStatus: 'IDLE'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModels.pending, (state, action) => {
      state.fetchItemsStatus = 'PENDING'
    })

    builder.addCase(fetchModels.fulfilled, (state, action) => {
      state.fetchItemsStatus = 'DONE'
      modelAdapter.setAll(state, action.payload.models as CarModel[])
    })

    builder.addCase(fetchModels.rejected, (state, action) => {
      state.error = true
      state.fetchItemsStatus = 'ERROR'
    })
  }}
)

export const modelSelector = modelAdapter.getSelectors((state: any) => state.models)
export const modelReducer = modelsSlice.reducer