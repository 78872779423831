import { useFormContext, SubmitHandler } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FormControl } from "components/FormControl";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import { useTranslation } from "react-i18next";
import FormSectionWrapper from "../FormSectionWrapper";


interface IListYourGoalsForm {}

interface YourGoalsProps {
  active?: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  index: number;
  currentStep: number;
}

export function YourGoals({ active, currentStep, setCurrentStep, index }: YourGoalsProps) {
  let methods = useFormContext();
  let { t } = useTranslation();
  const onSubmit = (data: any) => {
    // next();
    setCurrentStep(index + 1)
  };

  return (
      <FormSectionWrapper
        currentStep={currentStep}
        index={index}
        setCurrentStep={setCurrentStep}
        active={active}
        title={t('your-goals')}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>

        <div className="mb-6">
          <label htmlFor="">Which is your primary financial goal?</label>
          <Select className="mt-2" name="primary_financial_goal" defaultValue={"COVER_CAR_PAYMENT"}>
            <option value="COVER_CAR_PAYMENT">Cover your car payment</option>
            <option value="40-50">Generate Side Income</option>
            <option value="40-50">Expand an existing business</option>
            <option value="40-50">Build a primary source of income</option>
            <option value="40-50">Not sure yet</option>
          </Select>
        </div>

        <div className="mb-6">
          <label htmlFor="">How often do you or your family currently use this car?</label>
          <Select className="mt-2" name="car_usage" defaultValue={"NEVER"}>
            <option value="NEVER">Never</option>
            <option value="NEVER">Rarely: once a week or less</option>
            <option value="SOMETIMES">Sometimes: a few days per week</option>
            <option value="NEVER">Often: most days per week</option>
            <option value="NEVER">Always: every day</option>
          </Select>
        </div>
          
        <div className="mb-6">
          <label htmlFor="">How often do you want to share your car?</label>
          <Select className="mt-2" name="car_shareability" defaultValue={"SOMETIMES"}>
            <option value="NEVER">Never</option>
            <option value="NEVER">Rarely: once a week or less</option>
            <option value="SOMETIMES">Sometimes: a few days per week</option>
            <option value="NEVER">Often: most days per week</option>
            <option value="NEVER">Always: every day</option>
          </Select>
        </div>

          <ButtonPrimary type="submit">Next</ButtonPrimary>
        </form>
      </FormSectionWrapper>
  );
}
