import React, { useEffect, useState, FC, Dispatch, SetStateAction } from "react";
import { env } from "env";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CheckoutProps } from "containers/StripeSuccesfulPage/types/CheckoutProps";
import { ReservationResponse } from "containers/PayPage/types/ReservationRespose";
import api from "api/api";

export type ConfirmResponse = {
  error: boolean;
  message: string;
}

const CheckoutFormAlt = ({
  amount = 1,
  submitPayment,
  setCompletedPayment
}:{
  amount?: number;
  submitPayment: boolean | null;
  setCompletedPayment: Dispatch<SetStateAction<boolean>>;
}) =>  {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<String>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(()=>{
    if(submitPayment != null){
      handleSubmit();
    }
  }, [submitPayment]);


  const handleSubmit = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const res = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${env}/api/confirmStripePayment`,
      },
      redirect: "if_required"
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (res.paymentIntent?.status === "succeeded") {
      setCompletedPayment(true);
      setMessage("Payment succeeded!");
    } else {
      setCompletedPayment(false);
      setMessage(`Payment failed ${res.error?.message}`);
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {/* <ButtonPrimary className="w-full" disabled={isLoading || !stripe || !elements} type="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </ButtonPrimary> */}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default CheckoutFormAlt;