import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInsurances } from "api/insurancesApi";
import { API_STATUSES } from "api/types";
import { IInsurance } from "types/Insurance";

interface IInsurancesState {
  error: boolean,
  fetchInsurancesStatus: API_STATUSES,
  insurances: IInsurance[]
}

export const fetchInsurances = createAsyncThunk('insurances/fetchInsurances', getInsurances)

const initialState: IInsurancesState = {
  error: false,
  fetchInsurancesStatus: "IDLE",
  insurances: []
}

export const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInsurances.pending, (state, action) => {
      state.fetchInsurancesStatus = 'PENDING'
    })

    builder.addCase(fetchInsurances.fulfilled, (state, action) => {
      state.fetchInsurancesStatus = 'DONE'
      state.insurances = action.payload.insurances
    })

    builder.addCase(fetchInsurances.rejected, (state, action) => {
      state.error = true
      state.fetchInsurancesStatus = 'ERROR'
    })
  }
})

const insurancesReducer = insurancesSlice.reducer

export { insurancesReducer }