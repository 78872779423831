import { ICarEquipment } from "types/CarEquipment";
import api from "./api";

export interface ICarEquipmentResponse {
  message: string,
  error: boolean,
  carEquipment: ICarEquipment[],
}

const API_ENDPOINT = '/data/equipments'

const getCarEquipment = async () => ((await api.get<ICarEquipmentResponse>(API_ENDPOINT)).data)

export { getCarEquipment }