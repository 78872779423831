import { useSearchCarsContext } from "containers/ListingCarPages/hooks/useSearchCarsContext";
import { Range } from "rc-slider";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface Props {}
const PriceRangeSelector = (props: Props) => {
  const { t } = useTranslation();
  const { searchFilters, setSearchFilters } = useSearchCarsContext();

  const [error, setError] = useState(false);
  const [localPricesRange, setLocalPricesRange] = useState(
    searchFilters.pricesRange
  );

  const applyPriceRange = () => {
    const [minPrice, maxPrice] = localPricesRange;

    if (
      minPrice > maxPrice ||
      maxPrice < minPrice ||
      minPrice < 0 ||
      maxPrice < 0 ||
      Number.isNaN(minPrice) ||
      Number.isNaN(maxPrice)
    ) {
      setError(true);
      return;
    }

    setError(false);
    setSearchFilters((prev) => ({ ...prev, pricesRange: localPricesRange }));
  };

  return (
    <>
      <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">
              {t("searchFilterModal-pricePerDay")}
            </span>
            <Range
              className="text-red-400"
              min={0}
              max={3000}
              defaultValue={localPricesRange}
              value={localPricesRange}
              allowCross={false}
              onChange={([minPrice, maxPrice]) =>
                setLocalPricesRange([minPrice, maxPrice])
              }
            />
          </div>

          <div className="flex justify-between space-x-5">
            <div>
              <label
                htmlFor="minPrice"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                {t("searchFilterModal-minPrice")}
              </label>
              <div className="mt-1 relative rounded-md">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-neutral-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="minPrice"
                  id="minPrice"
                  min={0}
                  max={localPricesRange[1]}
                  onChange={(e) =>
                    setLocalPricesRange([+e.target.value, localPricesRange[1]])
                  }
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                  value={localPricesRange[0]}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="maxPrice"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                {t("searchFilterModal-maxPrice")}
              </label>
              <div className="mt-1 relative rounded-md">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-neutral-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="maxPrice"
                  id="maxPrice"
                  min={localPricesRange[0]}
                  max={3000}
                  onChange={(e) =>
                    setLocalPricesRange([localPricesRange[0], +e.target.value])
                  }
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                  value={localPricesRange[1]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">Invalid search</strong>
          )}
          <ButtonPrimary
            onClick={applyPriceRange}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t("apply")}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default PriceRangeSelector;
