import { useState } from 'react';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { FaLocationArrow, FaVoicemail, FaPhone } from 'react-icons/fa';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Filters from 'containers/ReservationCheckout/Filters';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t, i18n } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);
    const sendEmail = (e: any) => {
        e.persist();
        e.preventDefault();
        setIsSubmitting(true);
        emailjs
            .sendForm(
                'service_7cyxo3q',
                'template_qovpfjy',
                e.target,
                'ub8kMeZjuosCjbsRr'
            )
            .then(
                (result) => {
                    //toast.success("Message sent!.");
                    alert("Message sent!");
                    //setStateMessage('Message sent!');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                },
                (error) => {
                    //toast.warning("Something went wrong, please try again later.");
                    alert("Something went wrong, please try again later");
                    //setStateMessage('Something went wrong, please try again later');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                }
            );

        // Clears the form after sending the email
        e.target.reset();
    };
    return (
        <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail} >
            <label className="block">
                <Label>{t('fullName')}</Label>

                <input
                    placeholder="Example Doe"
                    type="text"
                    className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                    name="from"
                ></input>
            </label>
            <label className="block">
                <Label>{t('email')}</Label>

                <input
                    type="email"
                    placeholder="example@example.com"
                    className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                    name="mail"
                ></input>
            </label>
            <label className="block">
                <Label>{t('message')}</Label>
                <textarea
                    className={`block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                    rows={4}
                    name="message"
                ></textarea>
            </label>
            <div>
                <ButtonPrimary type="submit">
                    {t('send-message')}
                </ButtonPrimary>
            </div>
            <div></div>
        </form>



        /*  <form onSubmit={sendEmail}>
              <label>Name</label>
              <input type="text" name="user_name" />
              <label>Email</label>
              <input type="email" name="user_email" />
              <label>Message</label>
              <textarea name="message" />
              <input type="submit" value="Send" disabled={isSubmitting} />
              {stateMessage && <p>{stateMessage}</p>}
          </form>*/
    );
};
export default ContactForm;