export const ES_TRANSLATION = {
  'navigation-home': 'Inicio',
  'navigation-map': 'Ver mapa',
  'navigation-contact': 'Contactanos',
  'auth-signup': 'Registrarse',
  'auth-signup-hasAccount': '¿Ya posees una cuenta?',
  'auth-login': 'Iniciar sesión',
  'auth-login-notAccountYet': '¿No tienes una cuenta aún?',
  'auth-login-createAccount': 'Crear una cuenta',
  'landing-title': 'Busca tu auto',
  'landing-subtitle': 'Más de 2000 autos',
  'landing-searchTab-oneDay': 'Un día',
  'landing-searchTab-multipleDays': 'Múltiples días',
  'landing-searchTab-permanent': 'Permanente',
  'landing-searchSubTab-samePlace': 'Entrega en el mismo lugar',
  'landing-searchSubTab-differentPlace': 'Entrega en otro lugar',
  'landing-search-provincePlaceholder': 'Provincia o ciudad',
  'landing-search-pickupPlaceholder': 'Recoger vehículo',
  'landing-search-dropoffPlaceholder': 'Entregar vehículo',
  // 'landing-search-startDatePlaceholder': 'Fecha de inicio',
  // 'landing-search-endDatePlaceholder': 'Fecha de finalización',
  'itemList-error-title': 'Oops! Ha ocurrido un error...',
  'itemList-error-subtitle': 'Intente más tarde',
  'itemRecommendations-title': 'Nuestras recomendaciones',
  'itemRecommendations-subtitle': 'Propietarios y vehículos destacados',
  'gridFilterSection-title': 'Autos en',
  'gridHasMap-title': 'Vehículos disponibles',
  'carDetail-showAllImages': 'Mostrar todas las fotos',
  'carDetail-equipment-description':
    'Este vehículo posee el siguiente equipamiento: ',
  'carDetail-conditions-description':
    'Al reservar el vehículo te comprometes a cumplir con las siguientes condiciones: ',
  'carDetail-amenities-description':
    'Este vehículo cuenta con las siguientes amenidades: ',
  'carDetail-insurances-description':
    'Este vehículo cuenta con los siguientes seguros: ',
  'carDetail-shouldKnownSection-title': 'Lo que deberías saber',
  'carDetail-shouldKnownSection-cancellationPolicy-title':
    'Política de cancelación',
  'carDetail-shouldKnownSection-cancellationPolicy-description':
    'Para realizar la cancelación de la reservación se deben tener en cuenta 4 días hábiles previos, asi como realizarse un descuento del 20% del monto atribuído.',

  'list-your-car': 'Enlista tu vehículo',
  'your-car': 'Tu carro',
  'your-goals': 'Tus objetivos',
  'car-availability': 'Disponibilidad del automóvil',
  'car-details': 'Detalles del vehículo',
  'where-is-your-car-located': '¿En dónde se encuentra tu vehículo?',
  'which-car-do-you-have': '¿Qué carro posees?',
  'license-plate-number': 'Ingresa la placa de tu vehículo',
  car_description: 'Descripción del vehículo',
  'safety-and-quality-standards': 'Estándares de seguridad y calidad',
  'car-picture': 'Fotografía del Vehículo',
  'car-price': 'Precio por día del alquiler de tu vehículo',
  'car-year': 'Año del vehículo',
  'car-seats-number': 'Número de asientos del vehículo',
  'car-doors-number': 'Número de puertas del vehículo',
  'car-external-color': 'Color Externo del vehículo',
  'car-internal-color': 'Color Interno del vehículo',
  'car-max-passengers': 'Cantidad máxima de pasajeros en el vehículo',
  'car-mileage': 'Cantidad de millas del vehículo',
  edit: 'Editar',

  startTime: 'Tiempo de inicio',
  endTime: 'Tiempo final',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  'show-charges': 'Ver cargos',
  'delivery-location': 'Lugar de Entrega (Cantón)',

  // Account
  'my-account': 'Mi cuenta',
  'my-vehicles': 'Mis vehículos',
  'my-rents': 'Mis Rentas',

  // Auth words translation
  fullName: 'Nombre completo',

  email: 'Correo electrónico',
  emailD: 'Correo Segundo conductor',
  phoneNumber: 'Número telefónico',
  phoneNumberD: 'Teléfono segundo conductor',
  country: 'País',
  address: 'Dirección',
  password: 'Contraseña',
  repeatPassword: 'Repetir contraseña',
  invalidEmail: 'Correo electrónico inválido',
  requiredField: 'Campo requerido',
  invalidField: 'Campo inválido',
  invalidComparePasswords: 'Las contraseñas son diferentes',
  identification: 'Identificación',

  // Payment modal
  'paymentModal-title': 'Pago en proceso',
  'paymentModal-personalInfo': 'Información personal',
  'paymentModal-cardInfo': 'Información de tarjeta',
  'paymentModal-cardNumber': 'Número de tarjeta',
  'paymentModal-cardExpiration': 'Fecha de expiración',
  'paymentModal-cardHolderName': 'Nombre del propietario name',

  firstName: 'Nombre',
  lastName: 'Apellido',
  city: 'Ciudad',
  state: 'Estado',
  zipCode: 'Código local',
  invalidCardNumber: 'Tarjeta inválida',

  // Checkout Modal
  'checkoutModal-title': 'Información de pagos',
  addPassenger: 'Añadir Pasajero',
  checkout: 'Pagar',

  '1st. Driver Information': 'Información del 1er Conductor',
  'Driver Information': 'Information del Conductor',
  PassengerName: 'Nombre del pasajero',
  LicenseNumber: 'Número de licencia',
  LicenseNumberD: 'Licencia segundo conductor',
  DriverName: 'Nombre conductor',
  DriverNameD: 'Nombre segundo conductor',
  PassportNumber: 'Número de pasaporte',
  PassportNumberD: 'Pasaporte segundo conductor',
  NationalOrForeign: 'Documento Nacional o Pasaporte',
  NationalIdentification: 'Cédula',
  Passport: 'Pasaporte',
  DriverLicensePicture: 'Foto de Licencia de Conducir',
  PersonalDocumentPicture:
    'Fotografía del documento nacional que elegiste previamente (cédula o pasaporte)',
  OriginContry: 'País de Orígen',
  DateOfArrival: 'Fecha de llegada al país',
  FlightNumber: 'Número de Vuelo',
  TermsConditions: 'Accepto Términos y condiciones ',
  TermsAndConditions: 'Términos y condiciones',
  Phone: 'Teléfono',
  EnterPhone: 'Ingrese su número de teléfono',
  // Reservations
  reservationSuccess: 'Reservación exitosa',
  reservationError: 'Error al reservar. Intenta más tarde',
  reservationErrorCustomerNotLoggedIn:
    'Debes registrarte o loguearte para poder rentar un automóvil',
  sucessfulPayment: 'Pago exitoso',
  goBack: 'Volver al inicio',

  addDriverQuestion: 'Quiere agregar otro conductor?',
  addIDQuestion: 'Quiere agregar una cédula o un pasaporte?',
  deliverySpecifications: 'Especificaciones de lugar de entrega de vehículo',
  deliverySpecificationsPlaceholder: 'Ingresá tus especificaciones',

  // Search filters
  searchFilter: 'Filtros',
  'no-cars-found': '0 vehículos encontrados',
  'searchFilterModal-title': 'Filtros de búsqueda',
  'searchFilterModal-priceRange': 'Rango de precios',
  'searchFilterModal-pricePerDay': 'Precio por día',
  'searchFilterModal-minPrice': 'Precio mínimo',
  'searchFilterModal-maxPrice': 'Precio máximo',
  'searchFilterModal-minSeats': 'Número mínimo de asientos',
  'searchFilterModal-workingOnMoreFilters': 'Trabajando en más filtros',

  'searchFilterModal-brand': 'Marcas',
  'searchFilterModal-model': 'Modelos',
  'searchFilterModal-years': 'Años',
  'searchFilterModal-seats': 'Asientos',
  'searchFilterModal-transmission': 'Transmisión',
  'searchFilterModal-color': 'Colores',
  'searchFilterModal-type': 'Tipo',

  // Select language
  spanish: 'Español',
  english: 'Inglés',
  selectLanguage: 'Seleccionar idioma',

  doors: 'Puertas',
  transmission: 'Transmisión',
  automatic: 'Automático',
  mileage: 'Kilometraje',
  manual: 'Manual',
  externalColor: 'Color externo',
  internalColor: 'Color interno',

  // Common words translation
  apply: 'Aplicar',
  continue: 'Continuar',
  car: 'Auto',
  pluralCar: 'Autos',
  carWithCount: '{{ count }} auto',
  pluralCarWithCount: '{{ count }} autos',
  today: 'Hoy',
  year: 'Año',
  month: 'Mes',
  available: 'Disponible',
  reserved: 'Reservado',
  owner: 'Dueño',
  day: 'Día',
  pluralDay: 'Días',
  share: 'Compartir',
  save: 'Guardar',
  conditions: 'Condiciones',
  amenities: 'Amenidades',
  insurances: 'Seguros',
  equipment: 'Equipamiento',
  availability: 'Disponibilidad',
  review: 'Reseña',
  pluralReview: 'Reseñas',
  seeMore: 'Ver más',
  location: 'Ubicación',
  rent: 'Rentar',
  taxes: 'Impuestos',
  'additional-charges': 'Cargos adicionales',
  message: 'Mensaje',
  'send-message': 'Enviar message',
  'invalid-search': 'Búsqueda no válida',
  provinces: 'Provincias',

  names: 'Nombres',
  phone: 'Teléfono',
  birthdate: 'Fecha de nacimiento',
  license_number: 'Número de licencia',
  license_expiration_date: 'Fecha de expiración de licencia',
  passport_number: 'Número de pasaporte',
  passport_expiration_date: 'Fecha de expiración de pasaporte',
  license_picture: 'Foto de licencia',
  passport_picture: 'Foto de pasaporte',

  // Colors:
  brown: 'Marrón',
  orange: 'Naranja',
  'sky-blue': 'Celeste',
  grey: 'Gris',
  wine: 'Vino',
  purple: 'Morado',
  beige: 'Beige',
  pink: 'Rosado',
  yellow: 'Amarillo',
  green: 'Verde',
  blue: 'Azul',
  red: 'Rojo',
  white: 'Blanco',
  black: 'Negro',
};
