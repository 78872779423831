import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPointDeliveryCantons } from "api/pointDeliveryCantons";
import { API_STATUSES } from "api/types";
import { IPointDeliveryCanton } from "types/PointDeliveryCanton";

interface IPointDeliveryCantonState {
  error: boolean,
  fetchPoinDeliveryCantonsStatus: API_STATUSES,
  placeDeliveries: IPointDeliveryCanton[]
}

export const fetchPoinDeliveryCantons = createAsyncThunk('conditions/fetchPointDeliveryCantons', getPointDeliveryCantons)

const initialState: IPointDeliveryCantonState = {
  error: false,
  fetchPoinDeliveryCantonsStatus: "IDLE",
  placeDeliveries: []
}

export const pointDeliveryCantonSlice = createSlice({
  name: 'placeDeliveries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPoinDeliveryCantons.pending, (state, action) => {
      state.fetchPoinDeliveryCantonsStatus = 'PENDING'
    })

    builder.addCase(fetchPoinDeliveryCantons.fulfilled, (state, action) => {
      state.fetchPoinDeliveryCantonsStatus = 'DONE'
      state.placeDeliveries = action.payload.placeDeliveries
    })

    builder.addCase(fetchPoinDeliveryCantons.rejected, (state, action) => {
      state.error = true
      state.fetchPoinDeliveryCantonsStatus = 'ERROR'
    })
  }
})

const pointDeliveryCantonsReducer = pointDeliveryCantonSlice.reducer

export { pointDeliveryCantonsReducer }