import { useTranslation } from "react-i18next";

interface FormSectionWrapperProps {
  active?: boolean;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  children: React.ReactNode | React.ReactNode[];
  title: string;
}

export default function FormSectionWrapper({ active, currentStep, setCurrentStep, index, children, title }: FormSectionWrapperProps) {
  let { t } = useTranslation();

  return (
    <>
      <div className={`flex flex-row justify-between items-center py-3 rounded-lg ${active ? "" : "px-6 bg-slate-100"}`}>
        <h2 className={`text-2xl ${active ? "font-bold text-indigo-700" : "text-slate-400"} ${(!active && (currentStep >= index)) ? "font-semibold text-gray-700" : ""}`}>{title}</h2>
        {(!active && (currentStep >= index) && currentStep !== 4) && (
          <span
            className="text-teal-800 hover:underline cursor-pointer"
            onClick={() => setCurrentStep(index)}
          >
            {t(`edit`)}
          </span>
        )}
      </div>
      {/* TODO: Brand, SubBrand selector */}
      {active && children}
    </>
  );
}
