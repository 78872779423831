import { usePointDeliveryCantonsState } from "hooks/usePointDeliveryCantons";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchPoinDeliveryCantons } from "store/slices/pointDeliveryCantons";

export const useLoadPointDeliveryCantons = () => {
  const { fetchPoinDeliveryCantonsStatus } = usePointDeliveryCantonsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchPoinDeliveryCantonsStatus === 'IDLE') {
      dispatch(fetchPoinDeliveryCantons())
    }
  }, [])
  
}