import { ArrowRightIcon } from "@heroicons/react/solid"
import CommentListing from "components/CommentListing/CommentListing"
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate"
import { useTranslation } from "react-i18next"
import ButtonCircle from "shared/Button/ButtonCircle"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import Input from "shared/Input/Input"

const RatingSection = () => {
  const { t } = useTranslation()

  return (
    <div className="listingSection__wrap">
    <h2 className="text-2xl font-semibold">{t('pluralReview')}</h2>

    <div className="space-y-5">
      <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
      <div className="relative">
        <Input
          fontClass=""
          sizeClass="h-16 px-4 py-3"
          rounded="rounded-3xl"
          placeholder="..."
        />
        <ButtonCircle
          className="absolute right-2 top-1/2 transform -translate-y-1/2"
          size=" w-12 h-12 "
        >
          <ArrowRightIcon className="w-5 h-5" />
        </ButtonCircle>
      </div>
    </div>

    <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
      <CommentListing className="py-8" />

      <div className="pt-8">
        <ButtonSecondary disabled>{t('seeMore')}</ButtonSecondary>
      </div>
    </div>
  </div>
  )
}

export default RatingSection