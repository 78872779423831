import React, { useState, useEffect, InputHTMLAttributes } from 'react';
import { RegisterOptions } from 'react-hook-form';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  registerRest?: any;
  registerOptions?: RegisterOptions;
}

const ImageUpload = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      registerRest,
      children,
      type = 'text',
      className = 'mb-2',
      sizeClass = 'h-11 px-4 py-3',
      fontClass = 'text-sm font-normal',
      rounded = 'rounded-2xl',
      ...args
    },
    ref
  ) => {
    const [selectedFile, setSelectedFile] = useState<File>();
    const [preview, setPreview] = useState<string>();

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
      if (!selectedFile) {
        setPreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined);
        return;
      }

      //   console.log(e.target.files);
      // I've kept this example simple by using the first image instead of multiple
      setSelectedFile(e.target.files[0]);
    };

    return (
      <div>
        <input
          className={className}
          type="file"
          multiple
          {...registerRest}
          onChange={onSelectFile}
          ref={(e) => {
            registerRest?.ref(e);
            return ref;
          }}
        />
        {selectedFile && <img width={300} src={preview} />}
      </div>
    );
  }
);

export default ImageUpload;
