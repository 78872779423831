import React, { FC } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { LandingSection } from "components/LandingSection";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./components/SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { SearchCarsProvider } from "./context/SearchCarsContext";
import RecomendationPage from "containers/RecomendationsPage/RecomendationPage";

export interface ListingCarMapPageProps {
  className?: string;
}

const ListingCarMapPage: FC<ListingCarMapPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`nc-ListingCarMapPage relative ${className}`}
      data-nc-id="ListingCarMapPage"
    >
      <Helmet>
        <title>Summer Rent a Car</title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <SearchCarsProvider>
        <div className=" pt-10 pb-24 lg:pt-16 ">
          <LandingSection
            className="pb-24 lg:pb-16 w-11/12"
            currentTab="landing-searchTab-oneDay"
            listingType={
              <>
                <i className="text-2xl las la-car"></i>
                <span className="ml-2.5">1512 cars</span>
              </>
            }
          />
        </div>

        {/* SECTION */}
        <div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
          <SectionGridHasMap />
        </div>
      </SearchCarsProvider>

      <div className="container overflow-hidden">
        {/* SECTION 1 */}
        <RecomendationPage />

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default ListingCarMapPage;
