import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { env } from "env";

const API_LAYER_CONFIG: AxiosRequestConfig = {
  baseURL: `${env}/api`,
};

const axiosInstance = axios.create(API_LAYER_CONFIG);

const api = (axios: AxiosInstance) => {
  return {
    get: <T>(
      url: string,
      body: unknown = {},
      config: AxiosRequestConfig = {}
    ) => {
      return axios.get<T>(url, config);
    },
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => {
      return axios.post<T>(url, body, config);
    },
    put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => {
      return axios.put<T>(url, body, config);
    },
    delete: <T>(url: string, config: AxiosRequestConfig = {}) => {
      return axios.delete<T>(url, config);
    },
  };
};

export default api(axiosInstance);
