import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getItems } from "api/itemsApi";
import { Item } from "types/Item/Item";

export const fetchItems = createAsyncThunk('items/fetchItems', getItems);

const carsAdapter = createEntityAdapter<Item>({
  selectId: (item) => item.id_item,
  sortComparer: (a, b) => b.id_item - a.id_item
})

export const carsSlice = createSlice({
  name: 'cars',
  initialState: carsAdapter.getInitialState({
    error: false,
    fetchItemsStatus: 'IDLE'
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchItems.pending, (state, action) => {
      state.fetchItemsStatus = 'PENDING'
    })

    builder.addCase(fetchItems.fulfilled, (state, action) => {
      state.fetchItemsStatus = 'DONE'
      carsAdapter.setAll(state, action.payload.items as Item[])
    })

    builder.addCase(fetchItems.rejected, (state, action) => {
      state.error = true
      state.fetchItemsStatus = 'ERROR'
    })
  }}
)

export const carsSelector = carsAdapter.getSelectors((state: any) => state.cars)
export const carsReducer = carsSlice.reducer