import { useState } from "react";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useCountriesState } from "hooks/useCountriesState";
import { useTranslation } from "react-i18next";
import { YourCar } from "./FormSections/YourCar";
import { YourGoals } from "./FormSections/YourGoals";
import { CarAvailability } from "./FormSections/CarAvailability";
import { CarDetails } from "./FormSections/CarDetails";
import { SafetyAndQualityStandards } from "./FormSections/SafetyAndQualityStandards";
import { useAmenitiesState } from "hooks/useAmenitiesState";
import { useConditionsState } from "hooks/useConditionsState";
import { useInsurancesState } from "hooks/useInsurancesState";
import { useCarEquipmentState } from "hooks/useCarsEquipmentState";
import RecomendationPage from "containers/RecomendationsPage/RecomendationPage";

const ListYourCarPage = () => {
  const { t } = useTranslation();
  const methods = useForm();

  let { countries } = useCountriesState();
  let { amenities } = useAmenitiesState();
  let { conditions } = useConditionsState();
  let { insurances } = useInsurancesState();
  let { carEquipment } = useCarEquipmentState();

  const [currentStep, setCurrentStep] = useState(0);
  const steps = [YourCar, YourGoals, CarAvailability, CarDetails, SafetyAndQualityStandards];
  // const steps = [CarDetails];

  return (
    <div className={`nc-PageSignUp`} data-nc-id="PageSignUp">
      <Helmet>
        <title>List Your Car || Rentacar</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("list-your-car")}
        </h2>
        <div className=" mx-auto space-y-6 ">
          <FormProvider {...methods}>
            {steps.map((Component, index) =>(
                  <Component
                    key={`list-your-car-form-step-${index}`}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    active={index == currentStep}
                    index={index}
                    amenities={Component === CarDetails ? amenities : undefined}
                    conditions={Component === CarDetails ? conditions : undefined}
                    insurances={Component === CarDetails ? insurances : undefined}
                    carEquipment={Component === CarDetails ? carEquipment : undefined}
                    countries={Component === CarDetails ? countries : undefined}
                  />
                )
            )}
          </FormProvider>
        </div>
        <br />
        <RecomendationPage />
      </div>
    </div>
  );
};

export default ListYourCarPage;
