import Input from "shared/Input/Input";
import { FormControl } from "components/FormControl";
import { RegisterOptions } from "react-hook-form";

type FormGroupInputProps = {
    label: string;
    name: string;
    id: string;
    type: string;
    placeholder?: string;
    required?: boolean;
    value?: string | number | boolean;
    registerOptions?: RegisterOptions
}

export default function FormGroupInput({ label, name, id, type, placeholder, required = false, registerOptions }: FormGroupInputProps) {
    return (
        <div className="mb-6">
            <FormControl
                id={id}
                name={name}
                label={label}
                registerOptions={registerOptions}

                >
                {(field: any) => (
                    <Input
                        type={type}
                        placeholder={placeholder}
                        {...field}
                    />
                )}
            </FormControl>
        </div>
    )
}
