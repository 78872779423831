import { createPayment, currentPaymentMethod } from "api/paymentApi";
import {
  APIStripePaymentData,
  GangWayTypes,
  StripePaymentData,
  StripePaymentResponse,
} from "types/Payment";
import { IReservation } from "types/Reservation";

interface PaymentHandlers {
  openBacPaymentModal: () => void;
  stripePaymentData: StripePaymentData;
}

type PaymentHandlersRecord = Record<GangWayTypes, () => void>;

const usePaymentHandlers = (
  paymentHandlers: PaymentHandlers,
  reservationData: IReservation
) => {
  const { stripePaymentData, openBacPaymentModal } = paymentHandlers;
  const PAYMENT_METHOD = currentPaymentMethod;

  const bacPaymentHandler = () => openBacPaymentModal(); // The payment process is delegated into the BAC PAYMENT MODAL

  const stripePaymentHandler = async () => {
    const paymentData: APIStripePaymentData = {
      ...stripePaymentData,
      reservationData,
    };
    const { url: stripePaymentUrl } = (await createPayment(
      paymentData
    )) as StripePaymentResponse;

    // Navigate into external URL
    window.location.replace(stripePaymentUrl);

    // Once stripe payment is successful / canceled, its must to redirect into application
  };

  const paymentHandlersMap: PaymentHandlersRecord = {
    bac: bacPaymentHandler,
    stripe: stripePaymentHandler,
    greenpay: () => console.log("hello from greenpay"),
  };

  const paymentHandlerAction =
    paymentHandlersMap[PAYMENT_METHOD as GangWayTypes];

  return paymentHandlerAction;
};

export { usePaymentHandlers };
