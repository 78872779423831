import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";
import { conditionAssignment, Item } from "types/Item/Item";
import CheckBoxCard from "shared/Checkbox/CheckBoxCard";

interface ConditionsSectionProps {
  currentItem?: Item;
  setSelectedConditions: Dispatch<SetStateAction<conditionAssignment[]>>;
  selectedConditions: conditionAssignment[];
  differenceDays: number | undefined;
}

const ConditionsSection = ({
  currentItem,
  setSelectedConditions,
  selectedConditions,
  differenceDays
}: ConditionsSectionProps) => {
  const { condition_assignments } = currentItem ?? {};
  const { t } = useTranslation();

  const onSelectCheckbox = (id: number) => {
    const conditionObject = condition_assignments?.find(
      ({ id_condition }) => id_condition === id
    );

    if (conditionObject) {
      const isSelected = selectedConditions.find(
        ({ id_condition }) => conditionObject.id_condition === id_condition
      );

      if (isSelected) {
        setSelectedConditions((prev) =>
          prev.filter(
            (condition) =>
              condition.id_condition !== conditionObject.id_condition
          )
        );

        return;
      }

      setSelectedConditions((prev) => [...prev, conditionObject]);
    }
  };

  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t("conditions")}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("carDetail-conditions-description")}
        </span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
        {condition_assignments?.map(({ condition, price, IS_REQUIRED, type }) => (
          <CheckBoxCard
            image={condition.public_route_image || 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png'}
            title={condition.detail_conditions}
            description={`For ${differenceDays} days`}
            price={price * (type == "DAILY" ? (differenceDays ?? 1) : 1)}
            isSelected={false}
            onChange={() => onSelectCheckbox(condition.id_condition)}
            isDefaultChecked={IS_REQUIRED}
            disabled={IS_REQUIRED}
          />
        ))}
      </div>
    </div>
  );
};

export default ConditionsSection;
