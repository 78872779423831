import { useTranslation } from "react-i18next";
import { Item } from "types/Item/Item";

interface EquipmentSectionProps {
  currentItem?: Item;
}

const EquipmentSection = ({ currentItem }: EquipmentSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className="listingSection__wrap">
      <div>
        <h2 className="text-2xl font-semibold">{t("equipment")}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {t("carDetail-equipment-description")}
        </span>
      </div>
      <ul className="grid grid-cols-3 lg:grid-cols-3 gap-y-6 gap-x-10 text-sm pl-4 text-neutral-700 dark:text-neutral-300 ">
        {currentItem?.equipment_assignments.map(({ equipment }) => (
          <li className="font-bold text-md list-disc">
            {equipment.car_equipment_detail}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default EquipmentSection;
