export const EN_TRANSLATION = {
  'navigation-home': 'Home',
  'navigation-map': 'View map',
  'navigation-contact': 'Contact us',
  'auth-signup': 'Sign up',
  'auth-signup-hasAccount': 'Already have account?',
  'auth-login': 'Log in',
  'auth-login-notAccountYet': 'Not account yet?',
  'auth-login-createAccount': 'Create account',
  'landing-title': 'Search your car',
  'landing-subtitle': '2000+ cars available',
  'landing-searchTab-oneDay': 'One day',
  'landing-searchTab-multipleDays': 'Multiple days',
  'landing-searchTab-permanent': 'Permanent',
  'landing-searchSubTab-samePlace': 'Delivery at same place',
  'landing-searchSubTab-differentPlace': 'Delivery at different place',
  'landing-search-provincePlaceholder': 'Province or city',
  'landing-search-pickupPlaceholder': 'Vehicle pick up',
  'landing-search-dropoffPlaceholder': 'Drop off vehicle',
  // 'landing-search-startDatePlaceholder': 'Start date',
  // 'landing-search-endDatePlaceholder': 'End date',
  'itemList-error-title': 'Oops! Something was wrong...',
  'itemList-error-subtitle': 'Try again',
  'itemRecommendations-title': 'Our recommendations',
  'itemRecommendations-subtitle': 'Owners and featured vehicles',
  'gridFilterSection-title': 'Cars in',
  'gridHasMap-title': 'Available vehicles',
  'carDetail-showAllImages': 'Show all images',
  'carDetail-equipment-description':
    'This vehicle has the following equipment: ',
  'carDetail-conditions-description':
    'When reserving the vehicle you agree to comply with the following conditions: ',
  'carDetail-amenities-description':
    'This vehicle has the following amenities: ',
  'carDetail-insurances-description':
    'This vehicle has the following insurances: ',
  'carDetail-shouldKnownSection-title': 'You should know',
  'carDetail-shouldKnownSection-cancellationPolicy-title':
    'Cancellation policy',
  'carDetail-shouldKnownSection-cancellationPolicy-description':
    'To cancel the reservation, 4 business days prior must be taken into account, as well as a discount of 20% of the amount attributed.',

  'list-your-car': 'List your car',
  'your-car': 'Your car',
  'your-goals': 'Your goals',
  'car-availability': 'Car Availability',
  'car-details': 'Car Details',
  'where-is-your-car-located': 'Where is your car located?',
  'which-car-do-you-have': 'Which car do you have?',

  'license-plate-number': 'Enter your license plate number',
  car_description: 'Car Description',
  'safety-and-quality-standards': 'Safety & Quality Standards',
  'car-picture': 'Car Picture',
  'car-price': 'Car Daily Price',
  'car-year': 'Car Year',
  'car-seats-number': 'Car Seats Number',
  'car-doors-number': 'Car Doors Number',
  'car-external-color': 'Car External Color',
  'car-internal-color': 'Car Internal Color',
  'car-max-passengers': 'Car Max Passengers',
  'car-mileage': 'Car Mileage',
  edit: 'Edit',

  startDate: 'Start Date',
  endDate: 'End Date',
  startTime: 'Start Time',
  endTime: 'End Time',
  'show-charges': 'view charges',
  'delivery-location': 'Delivery Location (Cantón)',

  // Account
  'my-account': 'My Account',
  'my-vehicles': 'My Vehicles',
  'my-rents': 'My Rents',

  // Auth words translation
  fullName: 'Full name',
  email: 'E-mail',
  emailD: 'E-mail Second Driver',
  phoneNumber: 'Phone number',
  phoneNumberD: 'Phone Second Driver',
  country: 'Country',
  address: 'Address',
  password: 'Password',
  repeatPassword: 'Repeat password',
  invalidEmail: 'Invalid email',
  requiredField: 'Required field',
  invalidField: 'Invalid field',
  invalidComparePasswords: 'Passwords are not the same',
  identification: 'Identification',

  // Reservations
  reservationSuccess: 'Successful reservation',
  reservationError: 'Reservation error. Try later',
  reservationErrorCustomerNotLoggedIn:
    'You need to sign up or log in to rent a car',
  sucessfulPayment: 'Sucessful Payment!',
  goBack: 'Go back',

  // Payment Modal
  'paymentModal-title': 'Payment proccess',
  'paymentModal-personalInfo': 'Personal Info',
  'paymentModal-cardInfo': 'Card Info',
  'paymentModal-cardNumber': 'Card number',
  'paymentModal-cardExpiration': 'Expiration date',
  'paymentModal-cardHolderName': 'Card holder name',
  firstName: 'First name',
  lastName: 'Last name',
  city: 'City',
  state: 'State',
  zipCode: 'Zip code',
  invalidCardNumber: 'Invalid card number',

  // Checkout Modal Title
  'checkoutModal-title': 'Checkout Information',
  checkout: 'Checkout',
  addPassenger: 'Add Passenger',

  '1st. Driver Information': '1st. Driver Information',
  'Driver Information': 'Driver Information',
  PassengerName: 'Passenger Name',
  LicenseNumber: 'License Number',
  LicenseNumberD: 'License Second Driver',
  DriverName: 'Driver Name',
  DriverNameD: 'Name Second Driver',
  PassportNumber: 'Passport Number',
  PassportNumberD: 'Passport Second Driver',
  NationalOrForeign: 'National Identification or Passport',
  NationalIdentification: 'National Identification',
  Passport: 'Passport',
  DriverLicensePicture: 'Driver License Picture',
  PersonalDocumentPicture:
    'Personal Identification Picture (national identification or passport if you did choose that previously)',
  OriginContry: 'Origin Country',
  DateOfArrival: 'Date of arrival',
  PersonalDocumentExpirationDate: 'Personal document expiration date',
  FlightNumber: 'Flight Number',
  TermsConditions: 'Accept Terms and Conditions',
  TermsAndConditions: 'Terms and Conditions',

  addDriverQuestion: 'Do you wnat to add a second driver?',
  addIDQuestion: 'Do you want to add a passport or license?',
  deliverySpecifications: 'Place of delivery specifications',
  deliverySpecificationsPlaceholder: 'Enter your delivery specifications',

  Phone: 'Phone',
  PhoneD: 'Phone Second Driver',
  EnterPhone: 'Enter your phone number',
  // Search filters
  searchFilter: 'Filter',
  'no-cars-found': '0 cars found',
  'searchFilterModal-title': 'Search filters',
  'searchFilterModal-priceRange': 'Price range',
  'searchFilterModal-pricePerDay': 'Price per day',
  'searchFilterModal-minPrice': 'Min price',
  'searchFilterModal-maxPrice': 'Max price',
  'searchFilterModal-minSeats': 'Minimum number of seats',
  'searchFilterModal-workingOnMoreFilters': 'Working on more filters',

  'searchFilterModal-brand': 'Brands',
  'searchFilterModal-model': 'Models',
  'searchFilterModal-years': 'Years',
  'searchFilterModal-seats': 'Number of seats',
  'searchFilterModal-transmission': 'Transmission',
  'searchFilterModal-color': 'Colors',
  'searchFilterModal-type': 'Type',

  // Select language
  spanish: 'Spanish',
  english: 'English',
  selectLanguage: 'Select language',

  doors: 'Doors number',
  transmission: 'Transmission',
  automatic: 'Automatic',
  mileage: 'Mileage',
  manual: 'Manual',
  externalColor: 'External color',
  internalColor: 'Internal color',

  // Common words translation
  apply: 'Apply',
  continue: 'Continue',
  car: 'Car',
  pluralCar: 'Cars',
  carWithCount: '{{ count }} car',
  pluralCarWithCount: '{{ count }} cars',
  today: 'Today',
  year: 'Year',
  month: 'Month',
  available: 'Available',
  reserved: 'Reserved',
  owner: 'Owner',
  day: 'Day',
  pluralDay: 'Days',
  share: 'Share',
  save: 'Save',
  conditions: 'Conditions',
  amenities: 'Amenities',
  insurances: 'Insurances',
  equipment: 'Equipment',
  availability: 'Availability',
  review: 'Review',
  pluralReview: 'Reviews',
  seeMore: 'See more',
  location: 'Location',
  rent: 'Rent',
  taxes: 'Taxes',
  'additional-charges': 'Additional charges',
  message: 'Message',
  'send-message': 'Send message',
  'invalid-search': 'Invalid search',
  provinces: 'Provinces',

  names: 'Names',
  phone: 'Phone',
  birthdate: 'Birthdate',
  license_number: 'License number',
  license_expiration_date: 'License expiration date',
  passport_number: 'Passport number',
  passport_expiration_date: 'Passport expiration date',
  license_picture: 'License picture',
  passport_picture: 'Passport picture',

  // Colors
  brown: 'Brown',
  orange: 'Orange',
  'sky-blue': 'Sky Blue',
  grey: 'Grey',
  wine: 'Wine',
  purple: 'Purple',
  beige: 'Beige',
  pink: 'Pink',
  yellow: 'Yellow',
  green: 'Green',
  blue: 'Blue',
  red: 'Red',
  white: 'White',
  black: 'Black',
};
