import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import FormSectionWrapper from "../FormSectionWrapper";
import { IAmenity } from "types/Amenity";
import { ICondition } from "types/Condition";
import { IInsurance } from "types/Insurance";
import { ICarEquipment } from "types/CarEquipment";
import { ICountry } from "types/Country";
import { useHistory } from "react-router";

interface SafetyAndQualityStandardsProps {
  active?: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  index: number;
  amenities?: IAmenity[];
  conditions?: ICondition[];
  insurances?: IInsurance[];
  carEquipment?: ICarEquipment[];
  countries?: ICountry[];
}

export function SafetyAndQualityStandards({
  active,
  currentStep,
  setCurrentStep,
  index,
}: SafetyAndQualityStandardsProps) {
  let { t } = useTranslation();
  let history = useHistory();

  const handleSubmit = () => {
    history.push("/");
  }

  return (
    <FormSectionWrapper
      currentStep={currentStep}
      index={index}
      setCurrentStep={setCurrentStep}
      title={t(`safety-and-quality-standards`)}
      active={active}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 mb-6">
        <div>
          <p className="mb-2 font-bold">Congratulations! Your car is already listed, but just a few things before you go.</p>
          <p className="mb-2">
            We strive to maintain a safe marketplace and reliable experience. As
            a host, you're expected to uphold these standards:
          </p>
          <ul className="list-disc ml-4 mb-4">
            <li className="mb-2">
              Keep your car well maintained so your guests stay safe on the
              road.
            </li>
            <li className="mb-2">
              Clean and refuel your car before every trip so your guests have an
              enjoyable experience.
            </li>
            <li className="mb-2">
              So that guests can count on a consistent experience, Turo
              prohibits you from listing the same car on other sharing
              platforms.
            </li>
          </ul>
        <ButtonPrimary onClick={handleSubmit} className="w-full">Agree and Continue</ButtonPrimary>
        </div>
        <div></div>
      </div>
    </FormSectionWrapper>
  );
}
