import { IPointDeliveryCanton } from "types/PointDeliveryCanton";
import api from "./api";

export interface IPointDeliveryCantonsResponse {
  message: string,
  error: boolean,
  placeDeliveries: IPointDeliveryCanton[],
}

const API_ENDPOINT = '/deliveries'

const getPointDeliveryCantons = async () => ((await api.get<IPointDeliveryCantonsResponse>(API_ENDPOINT)).data)

export { getPointDeliveryCantons }