export const requiredRule = (message = '* Campo requerido') => { 
  return {
    value: true,
    message
  }
}

export const maxLengthRule = (maxLength: number, message = '* Excede la cantidad de caracteres') => {
  return {
    value: maxLength,
    message
  }
}

export const trimInputRule = (message = '* Campo vacío') => {
  return (value: string | number) => String(value).trim().length > 0 || message
}

export const patternRule = (pattern: RegExp, message = '* Patrón inválido') => {
  return {
    value: pattern,
    message
  }
}

export const compareRule = (currentValue: any, targetValue: any, message = "* Campos diferentes") => {
  return currentValue === targetValue || message
}

