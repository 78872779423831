import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Select, { MultiValue } from 'react-select';
import { Option } from 'containers/ListingCarPages/context/SearchCarsContext';

interface Props {}

const TransmissionSelector = (props: Props) => {
  const { t } = useTranslation();
  const { setSearchFilters } = useSearchCarsContext();

  const transmissions = [
    { label: t('automatic').toUpperCase(), value: 'AUTOMATIC' },
    { label: t('manual').toUpperCase(), value: 'MANUAL' },
  ];

  const [error, setError] = useState(false);

  const [localTransmissions, setLocalTransmissions] = useState<
    MultiValue<Option>
  >([]);

  const applyBrandSelection = () => {
    setError(false);
    setSearchFilters((prev) => ({
      ...prev,
      transmissions: localTransmissions,
    }));
  };

  return (
    <>
      <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
        <div className="relative flex flex-col px-5 py-6 space-y-8">
          <div className="space-y-5">
            <span className="font-medium">
              {/* {t("searchFilterModal-pricePerDay")} */}
              {t('searchFilterModal-transmission')}
            </span>

            <Select
              options={transmissions}
              defaultValue={transmissions}
              isMulti
              name="years"
              className="basic-multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              onChange={(transmissions: MultiValue<Option>) =>
                setLocalTransmissions(transmissions)
              }
            />
          </div>
        </div>
        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
          {error && (
            <strong className="text-red-400 font-bold">
              {t('invalid-search')}
            </strong>
          )}
          <ButtonPrimary
            onClick={applyBrandSelection}
            sizeClass="px-4 py-2 ml-auto sm:px-5"
          >
            {t('apply')}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default TransmissionSelector;
