import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { TimeRage } from "components/HeroSearchForm/RentalCarSearchForm";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { useQueryParams } from "hooks/useQueryParams";
import { useAppSelector } from "store/hooks/useAppStore";
import { carsSelector } from "store/slices/carsSlice";
import {
  amenitieAssignment,
  conditionAssignment,
  InsurancesTypes,
  Item,
} from "types/Item/Item";
import NcImage from "shared/NcImage/NcImage";
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import useWindowSize from 'hooks/useWindowResize';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPhotos from "./ModalPhotos";
import { OverviewSection } from "./components/OverviewSection";
import { AmenitiesSection } from "./components/AmenitiesSection";
import { ConditionsSection } from "./components/ConditionsSection";
import { CheckInSection } from "./components/CheckInSection";
import { RatingSection } from "./components/RatingSection";
import { LocationSection } from "./components/LocationSection";
import { ShouldKnowSection } from "./components/ShouldKnowSection";
import { SidebarDetailSection } from "./components/SidebarDetailSection";
import { SidebarPriceSection } from "./components/SidebarPriceSection";
import { StickyFooterMobile } from "./components/SticyFooterMobile";
import { RecommendationsSection } from "./components/RecommendationsSection";
import { useCustomerState } from "hooks/useCustomerState";
import { IReservation } from "types/Reservation";
import { useTranslation } from "react-i18next";
import { env } from "env";
import { usePaymentHandlers } from "./hooks/usePaymentHandlers";
import { useLoadListingCarPage } from "containers/ListingCarPages/hooks/useLoadListingCarPage";
import { checkIfIsReserved } from "helpers/reservations/checkIfIsReserved";
import { EquipmentSection } from "./components/EquipmentSection";
import { useHistory } from "react-router-dom";
import { InsurancesTableSection } from "./components/InsurancesTableSection";
import { getDayPrice } from "helpers/reservations/calculatePrices";
import { IPointDeliveryCanton } from "types/PointDeliveryCanton";
import { BackgroundSection } from "components/BackgroundSection";
import { SectionSlider } from "components/SectionSlider";
import { TaxonomyType } from "data/types";

const ListingCarDetailPage = () => {
  useLoadListingCarPage();
  const { t } = useTranslation();
  const { customer } = useCustomerState();
  const history = useHistory();
  const queryParams = useQueryParams();
  const currentItemId = Number(queryParams.get("id_item"));
  const targetDate = queryParams.get("target_date") as string;
  const endDate = queryParams.get("end_date") as string;

  const currentItem: Item | undefined = useAppSelector((state) =>
    carsSelector.selectById(state, currentItemId)
  );
  const windowSize = useWindowSize();
  const [selectedConditions, setSelectedConditions] = useState<
    conditionAssignment[]
  >([]);
  const [selectedAmenities, setSelectedAmenities] = useState<
    amenitieAssignment[]
  >([]);
  const [selectedInsurances, setSelectedInsurances] = useState<
    InsurancesTypes
  >();

  const [deliveryPointCanton, setDeliveryPointCanton] = useState<IPointDeliveryCanton | null>();
  const [deliveryStartingPointCanton, setDeliveryStartingPointCanton] = useState<IPointDeliveryCanton | null>();

  const images_ = currentItem?.images?.map(function (image, index) {
    return {
      order: image.order,
      url_item_image: image.url_item_image,
    }
  }) || [];

  const imagerOrder = images_.sort((a, b) => a.order - b.order)

  const itemImages = imagerOrder.map(
    (_image) => `${env}/storage/${_image.url_item_image}`
  ) as string[];

  const [isOpenBacPaymentModal, setIsOpenBacPaymentModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);

  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: moment(targetDate),
    endDate: moment(endDate ?? targetDate).add(3, "days"),
  });

  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: "10:00 AM",
    endTime: "10:00 AM",
  });

  const isReserved = useMemo(() => {
    return checkIfIsReserved(
      [
        dateRangeValue.startDate?.toDate() as Date,
        dateRangeValue.endDate?.toDate(),
      ],
      currentItem?.reservations as IReservation[]
    );
  }, [dateRangeValue.startDate, dateRangeValue.endDate]);



  const differenceTime = useMemo(
    () => {
      const startTime = moment(timeRangeValue.startTime, "hh:mm A");
      const endTime = moment(timeRangeValue.endTime, "hh:mm A");
      return endTime.diff(startTime, "hours");
    },
    [timeRangeValue]
  );

  const differenceDays = useMemo(
    () => {
      return (dateRangeValue.endDate?.diff(dateRangeValue.startDate, "days") || 0) + (differenceTime > 0 ? 1 : 0);
    },
    [dateRangeValue, timeRangeValue]
  );

  const calculatePriceDays = (): number => {
    let price = 0;
    let currentDate = dateRangeValue.startDate?.clone();
    if (currentDate && dateRangeValue.endDate) {
      let endDate = dateRangeValue.endDate.clone();
      endDate.add(differenceTime > 0 ? 1 : 0, 'day');
      while (currentDate.isSameOrBefore(endDate)) {
        price = price + getDayPrice(currentItem, currentDate);
        currentDate.add(1, 'day');
        if (currentDate.isSame(endDate)) break;
      }
    }

    return price;
  };

  const calculateSubtotal = useMemo(() => {
    let subtotal = 0;
    let currentDate = dateRangeValue.startDate?.clone();
    if (currentDate && dateRangeValue.endDate) {
      let endDate = dateRangeValue.endDate.clone();
      endDate.add(differenceTime > 0 ? 1 : 0, 'day');

      while (currentDate.isSameOrBefore(endDate)) {
        subtotal = subtotal + getDayPrice(currentItem, currentDate);
        currentDate.add(1, 'day');
        if (currentDate.isSame(endDate)) break;
      }
    }

    const conditionsTotal = selectedConditions
      ?.map((condition) => {
        if (condition.IS_REQUIRED) {
          return 0;
        }
        else if (condition.type == "DAILY") {
          return condition.price * Number(differenceDays);
        } else {
          return condition.price;
        }
      })
      .reduce((prev, acc) => prev + acc, 0);

    const amenitiesTotal = selectedAmenities
      ?.map((amenitie) => {
        if (amenitie.IS_REQUIRED) {
          return 0;
        }
        else if (amenitie.type == "DAILY") {
          return amenitie.price * Number(differenceDays);
        } else {
          return amenitie.price;
        }
      })
      .reduce((prev, acc) => prev + acc, 0);

    const insurancesTotal = Number(selectedInsurances?.price || 0) * Number(differenceDays);

    const deliveryPointPrice = deliveryPointCanton?.price || 0;
    const deliveryStartingPointPrice = deliveryStartingPointCanton?.price || 0;

    return (
      subtotal +
      Number(conditionsTotal) +
      Number(amenitiesTotal) +
      Number(insurancesTotal) +
      Number(deliveryPointPrice) +
      Number(deliveryStartingPointPrice)
    );
  }, [
    differenceDays,
    currentItem?.amount_item,
    selectedAmenities,
    selectedConditions,
    selectedInsurances,
    deliveryPointCanton,
    deliveryStartingPointCanton
  ]);

  const taxes = useMemo(() => {
    return calculateSubtotal * .13;
  }, [calculateSubtotal]);

  const daysPrice = useMemo(() => {
    return calculatePriceDays();
  }, [differenceDays, timeRangeValue, currentItem?.amount_item]);

  const checkMinimum = useEffect(() => {
    if (differenceDays < 3 && differenceDays > 0 && dateRangeValue.endDate?.isAfter(dateRangeValue.startDate)) {
      //isMinimum = false;
      //isMinimum(true);
      //toast.warning("Minimum rental of three days.");
      //dateRangeValue.endDate = dateRangeValue.startDate.add(3, "days")

    }
  }, [differenceDays]);

  const isMinimum = useMemo(() => {
    if (differenceDays < 3 && differenceDays > 0 && dateRangeValue.endDate?.isAfter(dateRangeValue.startDate)) {
      toast.warning("Minimum rental of three days.");
      return true;
    } else {

      return false;
    }
  }, [dateRangeValue.startDate, dateRangeValue.endDate, differenceDays]);

  const reservationData: IReservation = useMemo(
    () => ({
      id_customer: customer?.id_customer as number,
      id_item: currentItemId,
      number_reservation: Math.round(Math.random() * 1000000),
      date_reservation: moment(new Date()).format("YYYY-MM-DD"),
      init_reservation: dateRangeValue.startDate?.format("YYYY-MM-DD"),
      end_reservation: dateRangeValue.endDate?.format("YYYY-MM-DD"),
      subtotal_reservation: calculateSubtotal,
      total_reservation: calculateSubtotal + taxes,
      taxes_reservation: taxes,
      total_all_reservation: calculateSubtotal + taxes,
      discount_reservation: 0,
      id_reservation_status: 0,
      amenities: selectedAmenities,
      conditions: selectedConditions,
      insurances: selectedInsurances,
      deliveryPointCantonId: deliveryPointCanton?.canton_id || 0,
      deliveryStartingPointCantonId: deliveryStartingPointCanton?.canton_id || 0,
      init_time_reservation: timeRangeValue.startTime,
      end_time_reservation: timeRangeValue.endTime,
    }),
    [
      customer,
      currentItemId,
      dateRangeValue.startDate,
      dateRangeValue.endDate,
      calculateSubtotal,
    ]
  );

  const paymentHandlerAction = usePaymentHandlers(
    {
      openBacPaymentModal: () => setIsOpenBacPaymentModal(true),
      stripePaymentData: {
        description: `${currentItem?.detail_item} / ${differenceDays} ${t(
          "days"
        )}`,
        amount: calculateSubtotal * 100, // Total amount must be multiplied by 100 for correct floating parsing in STRIPE payment
        urlCancel: window.location.href,
        urlSuccess: "http://localhost:3000/stripe/sucessful-payment",
      },
    },
    reservationData
  );


  const handleSubmitReservation = async () => {
    const vehicle_id = currentItem?.id_item;
    history.push(`/checkout?vehicle_id=${vehicle_id}`);
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const data = useAppSelector(carsSelector.selectAll);

  localStorage.setItem('rentPage', location.pathname + location.search);

  const categories: TaxonomyType[] = useMemo(() => (data.filter((item) => item.is_recommended == 1)).map((item) => {
    let images_ = item.images?.map(function (image, index) {
      return {
        order: image.order,
        url_item_image: image.url_item_image,
      }
    }) || [];

    let imagerOrder = images_.sort((a, b) => a.order - b.order)
    //CURRENT MOMENT DATE
    const date = moment().format('YYYY-MM-DD');
    return {
      id: item.id_item,
      href: `listing-car-detail?id_item=${item.id_item}&target_date=${date}${null ? `&end_date=${null}` : ""}`,
      name: item.detail_item,
      taxonomy: "category",
      count: 22,
      thumbnail: `${env}/storage/${imagerOrder[0]?.url_item_image}`
    }
  }), [data]);

  return (
    <div className="nc-ListingCarDetailPage" data-nc-id="ListingCarDetailPage">
      <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 h-96 sm:gap-2">
          <div
            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(0)}
          >
            <NcImage
              containerClassName="absolute inset-0"
              className="object-cover w-full h-full rounded-md sm:rounded-xl"
              src={itemImages?.[0]}
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          <div
            className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(1)}
          >
            <NcImage
              containerClassName="absolute inset-0"
              className="object-cover w-full h-full rounded-md sm:rounded-xl"
              src={itemImages?.[1]}
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {/*  */}
          {itemImages
            ?.filter((_, i) => i >= 2 && i < 4)
            ?.map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "block" : ""
                  }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 2)}
                />
              </div>
            ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={() => handleOpenModal(0)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              {t("carDetail-showAllImages")}
            </span>
          </div>
        </div>
      </header>
      {/* MODAL PHOTOS */}

      <ModalPhotos
        imgs={itemImages}
        isOpen={isOpen}
        onClose={handleCloseModal}
        initFocus={openFocusIndex}
        uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
      />

      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
        {/* PAGE SECTIONS */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          <CheckInSection
            currentItem={currentItem as Item}
            dateRangeValue={dateRangeValue}
            timeRangeValue={timeRangeValue}
            setDateRangeValue={setDateRangeValue}
          />
          <form className="flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl ">
            <RentalCarDatesRangeInput
              defaultDateValue={dateRangeValue}
              defaultTimeValue={timeRangeValue}
              numberOfMonths={1}
              fieldClassName="p-2 sm:p-1 md:p-3"
              wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
              className="RentalCarDetailPageDatesRangeInput"
              onChange={(data) => {
                setDateRangeValue(data.stateDate);
                setTimeRangeValue(data.stateTimeRage);
              }}
              anchorDirection={windowSize.width > 1400 ? 'left' : 'right'}
            />
          </form>



          {!isReserved && (
            <>
              <OverviewSection currentItem={currentItem} />
              <EquipmentSection currentItem={currentItem} />
              <ConditionsSection
                currentItem={currentItem}
                setSelectedConditions={setSelectedConditions}
                selectedConditions={selectedConditions}
                differenceDays={differenceDays}
              />
              <AmenitiesSection
                currentItem={currentItem}
                setSelectedAmenities={setSelectedAmenities}
                selectedAmenities={selectedAmenities}
                differenceDays={differenceDays}
              />
              {/* <InsurancesSection
                currentItem={currentItem}
                setSelectedInsurances={setSelectedInsurances}
                selectedInsurances={selectedInsurances}
                differenceDays={differenceDays}
              /> */}
              <InsurancesTableSection
                currentItem={currentItem}
                setSelectedInsurances={setSelectedInsurances}
                selectedInsurances={selectedInsurances}
              ></InsurancesTableSection>
              <div className="block lg:hidden">
                <SidebarPriceSection
                  isReserved={isReserved}
                  timeRangeValue={timeRangeValue}
                  setTimeRangeValue={setTimeRangeValue}
                  dateRangeValue={dateRangeValue}
                  setDateRangeValue={setDateRangeValue}
                  currentItem={currentItem}
                  subtotal={calculateSubtotal}
                  taxes={taxes}
                  daysPrice={daysPrice}
                  differenceDays={differenceDays as number}
                  handleCheckoutModal={setIsCheckoutModalOpen}
                  handleSubmitReservation={handleSubmitReservation}
                  reservationData={reservationData}
                  setDeliveryPointCanton={setDeliveryPointCanton}
                  setDeliveryStartingPointCanton={setDeliveryStartingPointCanton}
                  isMinimum={isMinimum}
                />
              </div>
              <RatingSection />
              <LocationSection />
              <ShouldKnowSection />
            </>
          )}

          <div className="block lg:hidden">
            <SidebarDetailSection />
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          {/* <SidebarDetailSection /> */}

          <div className="sticky top-24">
            <SidebarPriceSection
              isReserved={isReserved}
              timeRangeValue={timeRangeValue}
              setTimeRangeValue={setTimeRangeValue}
              dateRangeValue={dateRangeValue}
              setDateRangeValue={setDateRangeValue}
              currentItem={currentItem}
              subtotal={calculateSubtotal}
              taxes={taxes}
              daysPrice={daysPrice}
              differenceDays={differenceDays as number}
              handleCheckoutModal={setIsCheckoutModalOpen}
              handleSubmitReservation={handleSubmitReservation}
              reservationData={reservationData}
              setDeliveryPointCanton={setDeliveryPointCanton}
              setDeliveryStartingPointCanton={setDeliveryStartingPointCanton}
              isMinimum={isMinimum}
            />
          </div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <StickyFooterMobile totalPrice={calculateSubtotal} />

      {/* OTHER SECTION */}
      <div className="container relative py-12 mb-12 ">
        <BackgroundSection />
        {
          categories.length > 0 &&
          <SectionSlider
            heading={t("itemRecommendations-title")}
            subHeading={t("itemRecommendations-subtitle")}
            categoryCardType="card5"
            itemPerRow={4}
            categories={categories}
            uniqueClassName="ListingCarMapPage"
          />
        }

      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ListingCarDetailPage;
