import { FilterTerms } from "./FilterTerms/";

interface FiltersProps { }

const Filters = (props: FiltersProps) => {
    return (
        <>

            <FilterTerms />
        </>
    );
};
export default Filters;
