import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LocationInput from './LocationInput';
import { FocusedInputShape } from 'react-dates';
import RentalCarDatesRangeInput from './RentalCarDatesRangeInput';
import ButtonSubmit from './ButtonSubmit';
import { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSearchCarsContext } from 'containers/ListingCarPages/hooks/useSearchCarsContext';

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TimeRage {
  startTime: string;
  endTime: string;
}

export interface RentalCarSearchFormProps {
  haveDefaultValue?: boolean;
  setSearchData?: Dispatch<SetStateAction<any>>;
}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = ({
  haveDefaultValue,
  setSearchData,
}) => {
  // TRANSLATION HANDLER
  const { t } = useTranslation();

  // DEFAULT DATA FOR ARCHIVE PAGE
  const defaultPickUpInputValue = '0';
  const defaultDropOffInputValue = '0';

  const {
    setSearchInitReservationDate,
    setSearchEndReservationDate,
    setSearchFilters,
  } = useSearchCarsContext();
  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: '10:00 AM',
    endTime: '10:00 AM',
  });
  const [pickUpInputValue, setPickUpInputValue] = useState('');
  const [dropOffInputValue, setDropOffInputValue] = useState('');
  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | 'dropOffInput' | null
  >(null);
  const [dropOffLocationType, setDropOffLocationType] = useState<
    'same' | 'different'
  >('same');

  // USER EFFECT
  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue({
        startDate: moment(),
        endDate: moment().add(4, 'days'),
      });

      setPickUpInputValue(defaultPickUpInputValue);
      setDropOffInputValue(defaultDropOffInputValue);
    }
  }, []);

  const onSubmitSearchButton = () => {
    setSearchFilters((prev) => ({
      ...prev,
      others: {
        timeRangeValue,
        dateRangeValue,
        pickUpInputValue,
        dropOffInputValue,
      },
    }));

    setSearchInitReservationDate(dateRangeValue.startDate);
    setSearchEndReservationDate(dateRangeValue.endDate);
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === 'same'
              ? 'bg-black text-white shadow-black/10 shadow-lg'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
          onClick={(e) => setDropOffLocationType('same')}
        >
          {t('landing-searchSubTab-samePlace')}
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            dropOffLocationType === 'different'
              ? 'bg-black text-white shadow-black/10 shadow-lg'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
          onClick={(e) => setDropOffLocationType('different')}
        >
          {t('landing-searchSubTab-differentPlace')}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="w-full">
        <form className="w-full relative mt-8 rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
          {renderRadioBtn()}
          <div className=" flex flex-col md:flex-row w-full rounded-full [ nc-divide-field ] ">
            <div className="relative flex flex-col md:flex-row flex-grow [ nc-divide-field ] ">
              <LocationInput
                defaultValue={pickUpInputValue}
                onChange={(e) => setPickUpInputValue(e)}
                onInputDone={() =>
                  setFieldFocused(
                    dropOffLocationType === 'different'
                      ? 'dropOffInput'
                      : 'startDate'
                  )
                }
                placeHolder={t('landing-search-provincePlaceholder')}
                desc={t('landing-search-pickupPlaceholder')}
              />
              {dropOffLocationType === 'different' && (
                <LocationInput
                  defaultValue={dropOffInputValue}
                  onChange={(e) => setDropOffInputValue(e)}
                  onInputDone={() => setFieldFocused('startDate')}
                  placeHolder={t('landing-search-provincePlaceholder')}
                  desc={t('landing-search-dropoffPlaceholder')}
                  autoFocus={fieldFocused === 'dropOffInput'}
                />
              )}
            </div>
            <RentalCarDatesRangeInput
              defaultDateValue={dateRangeValue}
              defaultTimeValue={timeRangeValue}
              defaultFocus={
                fieldFocused === 'dropOffInput' ? null : fieldFocused
              }
              onFocusChange={(focus) => setFieldFocused(focus)}
              onChange={(data) => {
                setDateRangeValue(data.stateDate);
                setTimeRangeValue(data.stateTimeRage);
              }}
            />
            {/* BUTTON SUBMIT OF FORM */}
            <div className="px-4 py-3 flex items-center justify-center">
              <ButtonSubmit onClick={onSubmitSearchButton} />
            </div>
          </div>
        </form>
      </div>
    );
  };

  return renderForm();
};

export default RentalCarSearchForm;
