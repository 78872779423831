import { Dispatch, FC, SetStateAction, useState } from "react";
import "react-dates/initialize";
import ExperiencesSearchForm from "./ExperiencesSearchForm";
import RentalCarSearchForm from "./RentalCarSearchForm";
import { useTranslation } from "react-i18next";

export type SearchTab =
  | "landing-searchTab-oneDay"
  | "landing-searchTab-permanent"
  | "landing-searchTab-multipleDays";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  setSearchData?: Dispatch<SetStateAction<any>>;
}

const LandingSearchFilters: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "landing-searchTab-oneDay",
  setSearchData,
}) => {
  const { t } = useTranslation();
  const tabs: SearchTab[] = [
    "landing-searchTab-multipleDays",
  ];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <ul className="bg-white w-96 mx-auto rounded-lg shadow-lg flex text-center overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`w-full px-4 py-3 cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? "bg-neutral-200 font-extrabold"
                  : "text-neutral-500 hover:bg-neutral-100 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              <span>{t(tab)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const isArchivePage = !!currentTab;
    switch (tabActive) {
      case "landing-searchTab-oneDay":
        return <ExperiencesSearchForm haveDefaultValue={isArchivePage} />;
      case "landing-searchTab-multipleDays":
        return <RentalCarSearchForm haveDefaultValue={isArchivePage} setSearchData={setSearchData}/>;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default LandingSearchFilters;
