import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCarEquipment } from "api/carEquipmentApi";
import { API_STATUSES } from "api/types";
import { ICarEquipment } from "types/CarEquipment";

interface ICarEquipmentState {
  error: boolean,
  fetchCarEquipmentStatus: API_STATUSES,
  carEquipment: ICarEquipment[]
}

export const fetchCarEquipment = createAsyncThunk('conditions/fetchCarEquipment', getCarEquipment)

const initialState: ICarEquipmentState = {
  error: false,
  fetchCarEquipmentStatus: "IDLE",
  carEquipment: []
}

export const carEquipmentSlice = createSlice({
  name: 'carEquipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCarEquipment.pending, (state, action) => {
      state.fetchCarEquipmentStatus = 'PENDING'
    })

    builder.addCase(fetchCarEquipment.fulfilled, (state, action) => {
      state.fetchCarEquipmentStatus = 'DONE'
      state.carEquipment = action.payload.carEquipment
    })

    builder.addCase(fetchCarEquipment.rejected, (state, action) => {
      state.error = true
      state.fetchCarEquipmentStatus = 'ERROR'
    })
  }
})

const carEquipmentReducer = carEquipmentSlice.reducer

export { carEquipmentReducer }