import { useColorsState } from "hooks/useCarsColorState";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchColors } from "store/slices/CarColorSlice";

export const useLoadListingColors = () => {
  const { fetchItemsStatus } = useColorsState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (fetchItemsStatus === 'IDLE') {
      dispatch(fetchColors())
    }
  }, [])
  
}