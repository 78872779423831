import moment from 'moment';
import { Item } from 'types/Item/Item';

const getDayPrice = (
  currentItem: Item | undefined,
  date: moment.Moment
): number => {
  let price = 0;

  if (!currentItem) return 0;
  const {
    seasons = null,
    months_high_seasons,
    months_low_seasons,
    special_tour_days = [],
  } = currentItem;
  const month = date.month() + 1;
  const day = date.day() == 0 ? 7 : date.day();

  const season = seasons?.find((season) => season.number_day === day);
  const isHighSeason = months_high_seasons?.includes(`${month}`);
  const isLowSeason = months_low_seasons?.includes(`${month}`);

  price = parseFloat(
    isHighSeason
      ? season?.high_season_price || 0
      : isLowSeason
      ? season?.low_season_price || 0
      : 0
  );

  special_tour_days.forEach((specialDay) => {
    const specialDayStartDate = moment(specialDay.start_date);
    const specialDayEndDate = moment(specialDay.end_date).add(1, 'days');
    const isSpecialDay = date.isBetween(
      specialDayStartDate,
      specialDayEndDate,
      undefined,
      '[]'
    );
    if (isSpecialDay) price = parseFloat(specialDay.price);
  });

  return price;
};

export { getDayPrice };
