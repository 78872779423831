import { Link, useLocation } from "react-router-dom";
import { AccountNavbarOptionProps } from "./types/AccountNavbarOption";
import { AiOutlineArrowRight } from "react-icons/ai";

const ACCOUNT_MENU_OPTIONS: AccountNavbarOptionProps[] = [
    {
        title: "Vehicles",
        path: "/account/vehicles",
        active: false
    },
    {
        title: "Rents",
        path: "/account/rents",
        active: false
    },
    {
        title: "Settings",
        path: "/account",
        active: false
    },
    {
        title: "Reviews",
        path: "/account/reviews",
        active: false
    },
]

export default function AccountNavbar({className}: {className?: string}): JSX.Element {
    return (
        <aside className={className}>
            <AccountNavbarOptions/>
        </aside>
    )
}

function AccountNavbarOptions(): JSX.Element {
    return (
        <ul>
            {ACCOUNT_MENU_OPTIONS.map((account_menu_option, i) => {
                return <AccountNavbarOption key={`account_menu_option_${i}`} {...account_menu_option} />
            })}
        </ul>
    )
}

function AccountNavbarOption({path, title}: AccountNavbarOptionProps): JSX.Element {

    const {pathname} = useLocation();

    return (
        <Link to={path}>
            <li 
                className={`mb-4 ${pathname === path && 'text-teal-500'}`}>
                    {pathname === path && <AiOutlineArrowRight className="inline"/>} {title}
            </li>
        </Link>
    )
}