import Glide from "@glidejs/glide";
import React, { FC, useEffect, useMemo } from "react";
import NcImage from "shared/NcImage/NcImage";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
} 

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-3 aspect-h-1",
  uniqueID = "uniqueID",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}`;

  const [pos, setPos] = React.useState<number>(galleryImgs.length - 1);
  const [images, setImages] = React.useState<string[]>(pos >= 0 ? [galleryImgs[pos]] : []);
 

  setTimeout(() => {
    if(pos === 0) 
      setPos(galleryImgs.length - 1);
    else
      setPos(pos - 1);

    setImages([galleryImgs[pos]]);
  }, 3000);

  
  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {images.map((item, index) => (
              <li key={index} className="glide__slide">
                <div className={ratioClass}>
                  <NcImage src={item} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
