import { FC, useEffect, useState, useMemo } from "react";
import { BackgroundSection } from "components/BackgroundSection";
import { SectionSlider } from "components/SectionSlider";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks/useAppStore";
import { carsSelector } from "store/slices/carsSlice";
import { TaxonomyType } from "data/types";
import { env } from "env";
import moment from "moment";
import { useLoadListingCarPage } from "containers/ListingCarPages/hooks/useLoadListingCarPage";

export interface ListingCarPageProps {
  className?: string;
}
const RecomendationPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState<any>(null);
  useLoadListingCarPage();
  const data = useAppSelector(carsSelector.selectAll);

  const categories:TaxonomyType[] = useMemo(() => (data.filter((item) => item.is_recommended == 1)).map((item) => {
    let images_ = item.images?.map(function(image, index){
      return {
        order: image.order,
        url_item_image: image.url_item_image,
      }
    } ) || [];

    let imagerOrder = images_.sort((a, b) => a.order - b.order)
    //CURRENT MOMENT DATE
    const date = moment().format('YYYY-MM-DD');
    return {
      id: item.id_item,
      href: `listing-car-detail?id_item=${item.id_item}&target_date=${date}${null ? `&end_date=${null}` : ""}`,
      name: item.detail_item,
      taxonomy: "category",
      count: 22,
      thumbnail:`${env}/storage/${imagerOrder[0]?.url_item_image}`
      }
    }), [data]);

  useEffect(() => {
   
  }, [searchData]);

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >

      <div className=" relative">

        <div className="container relative py-12 mb-12 ">
          <BackgroundSection />
          {
            categories.length > 0 &&
            <SectionSlider
              heading={t("itemRecommendations-title")}
              subHeading={t("itemRecommendations-subtitle")}
              categoryCardType="card5"
              itemPerRow={4}
              categories={categories}
              uniqueClassName="ListingCarMapPage"
            />
          }
          
        </div>
      </div>
    </div>
  );
};

export default RecomendationPage;
