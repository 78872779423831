import { createCustomer, loginAsCustomer } from "api/customerApi";
import { FormControl } from "components/FormControl";
import {
  compareRule,
  maxLengthRule,
  patternRule,
  requiredRule,
  trimInputRule,
} from "helpers/formRulesHelper";
import { useCountriesState } from "hooks/useCountriesState";
import { FC, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchCountries } from "store/slices/countriesSlice";
import { customerActions } from "store/slices/customerSlice";
import { ICreateCustomer, ILoginCustomer } from "types/Customer";

interface ISignUpForm {
  fullname: string;
  email: string;
  phoneNumber: number;
  country: number;
  address: string;
  password: string;
  repeatedPassword: string;
}

const PageSignUp = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useHistory();
  const { countries, fetchCountriesStatus } = useCountriesState();
  const methods = useForm<ISignUpForm>();

  // Allows to keep sync the password value for compare when validate REPEATED PASSWORD and PASSWORD fields.
  const password = useRef({});
  password.current = methods.watch("password", "");

  const onSubmit: SubmitHandler<ISignUpForm> = async (data) => {
    const newCustomerData: ICreateCustomer = {
      id_customer_type: 1,
      code_customer: 303030,
      id_pais: data.country,
      name_customer: data.fullname,
      email: data.email,
      address_customer: data.address,
      phone_customer: String(data.phoneNumber),
      password: data.password,
      birthdate: '',
      license_number: '',
      license_expiration_date: '',
      passport_number: '',
      passport_expiration_date: '',
      license_url: '',
      passport_url: '',
      reservations: [],
      id_agent: null,
    };

    const { error } = await createCustomer(newCustomerData);
    if(!error){
      await loginCustomer({ email: data.email, password: data.password });
    }
  };

  interface ILoginForm {
    email: string;
    password: string;
  }

  const history = useHistory();

  const loginCustomer: SubmitHandler<ILoginForm> = async (data: ILoginCustomer) => {
    const { error, token, customer } = await loginAsCustomer(data);

    if (error) return alert("Error en el correo o contraseña");

    localStorage.setItem("auth-token", token);
    localStorage.setItem("id_customer", String(customer.id_customer));
    localStorage.setItem("customer", JSON.stringify(customer));
    dispatch(customerActions.setCustomerData({ customer }));

    const reservation_data = localStorage.getItem('reservation_data');
    if (reservation_data) {
      setTimeout(() => {
        const reservationData = JSON.parse(reservation_data);
        history.push('/checkout', reservationData);
        localStorage.removeItem('reservation_data');
      }, 1000);
    } else {
      history.push('/');
    }
    
    return;
  };

  useEffect(() => {
    if (fetchCountriesStatus === "IDLE") {
      dispatch(fetchCountries());
    }
  }, [dispatch, fetchCountriesStatus]);

  return (
    <div className={`nc-PageSignUp`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Registro || Rentacar</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("auth-signup")}
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}
          <FormProvider {...methods}>
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <FormControl
                id="register-customer-fullname"
                name="fullname"
                label={t("fullName")}
                registerOptions={{
                  required: requiredRule(t("requiredField")),
                  validate: trimInputRule(t("invalidField")),
                  maxLength: maxLengthRule(128, t("invalidField")),
                }}
              >
                {(field: any) => (
                  <Input type="text" placeholder="John Doe" {...field} />
                )}
              </FormControl>

              <FormControl
                id="register-customer-email"
                name="email"
                label={t("email")}
                registerOptions={{
                  required: requiredRule(t("requiredField")),
                  pattern: patternRule(
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    t("invalidEmail")
                  ),
                }}
              >
                {(field: any) => (
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    {...field}
                  />
                )}
              </FormControl>

              <FormControl
                id="register-customer-phoneNumber"
                name="phoneNumber"
                label={t("phoneNumber")}
                registerOptions={{ required: requiredRule(t("requiredField")) }}
              >
                {(field: any) => (
                  <Input type="number" placeholder="00 000 000" {...field} />
                )}
              </FormControl>

              <FormControl
                id="register-customer-country"
                name="country"
                label={t("country")}
              >
                {(field: any) => (
                  <Select className="mt-1" {...field}>
                    {countries?.map((_country) => (
                      <option key={_country.id_pais} value={_country.id_pais}>
                        {_country.nombre_pais}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>

              <FormControl
                id="register-customer-address"
                name="address"
                label={t("address")}
                registerOptions={{
                  required: requiredRule(t("requiredField")),
                  validate: trimInputRule(t("invalidField")),
                }}
              >
                {(field: any) => (
                  <Textarea
                    placeholder="Av. Home #000. Street #000."
                    className="mt-1 resize-none p-4"
                    {...field}
                  />
                )}
              </FormControl>

              <FormControl
                id="register-customer-password"
                name="password"
                label={t("password")}
                registerOptions={{
                  required: requiredRule(t("requiredField")),
                  validate: trimInputRule(t("invalidField")),
                }}
              >
                {(field: any) => (
                  <Input
                    type="password"
                    placeholder="*** *** ***"
                    className="mt-1"
                    {...field}
                  />
                )}
              </FormControl>

              <FormControl
                id="register-customer-repeated-password"
                name="repeatedPassword"
                label={t("repeatPassword")}
                registerOptions={{
                  required: requiredRule(),
                  validate: {
                    trimInputRule: trimInputRule(t("invalidField")),
                    validatePasswords: (currentValue) =>
                      compareRule(
                        currentValue,
                        password.current,
                        t("invalidComparePasswords")
                      ),
                  },
                }}
              >
                {(field: any) => (
                  <Input type="password" placeholder="*** *** ***" {...field} />
                )}
              </FormControl>

              <ButtonPrimary type="submit">{t("continue")}</ButtonPrimary>
            </form>
          </FormProvider>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("auth-signup-hasAccount")}{" "}
            <Link className="text-primary-6000" to="/login">
              {t("auth-login")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
