import { Brand } from "types/Item/Item";
import api from "./api";

const API_ENDPOINT = "/brands";

export interface ItemsResponse {
  message: string;
  error: boolean;
  brands?: Brand[];
}


const getBrands = async () => {
  return (await api.get<ItemsResponse>(API_ENDPOINT)).data;
};

export { getBrands };
