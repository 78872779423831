import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { env_bac_payment } from "env";

const API_LAYER_CONFIG: AxiosRequestConfig = {
    baseURL: `${env_bac_payment}`,
};

const axiosInstance = axios.create(API_LAYER_CONFIG);

const paymentGateway = (axios: AxiosInstance) => {
    return {
        get: <T>(url: string, config: AxiosRequestConfig = {}) => {
            return axios.get<T>(url, config);
        },
        post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => {
            return axios.post<T>(url, body, config);
        },
        put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) => {
            return axios.put<T>(url, body, config);
        },
        delete: <T>(url: string, config: AxiosRequestConfig = {}) => {
            return axios.delete<T>(url, config);
        },
    };
};

export default paymentGateway(axiosInstance);
