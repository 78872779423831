import { useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppStore";
import { fetchAmenities } from "store/slices/amenitiesSlice";
import { fetchCarEquipment } from "store/slices/carEquipmentSlice";
import { fetchConditions } from "store/slices/conditionsSlice";
import { fetchCountries } from "store/slices/countriesSlice";
import { fetchCustomerData, customerActions } from "store/slices/customerSlice";
import { fetchInsurances } from "store/slices/insurancesSlice";
import { fetchItemTypes } from "store/slices/itemsTypeSlice";
import { useAmenitiesState } from "./useAmenitiesState";
import { useCarEquipmentState } from "./useCarsEquipmentState";
import { useConditionsState } from "./useConditionsState";
import { useCountriesState } from "./useCountriesState";
import { useCustomerState } from "./useCustomerState";
import { useInsurancesState } from "./useInsurancesState";
import { useItemTypesState } from "./useItemTypesState";

// This hook must load GLOBAL data that must be initializated instantly before render the app
const useLoadGlobalData = () => {
  const { fetchCountriesStatus } = useCountriesState();
  const { fetchAmenitiesStatus } = useAmenitiesState();
  const { fetchConditionsStatus } = useConditionsState();
  const { fetchInsurancesStatus } = useInsurancesState();
  const { fetchCarEquipmentStatus } = useCarEquipmentState();
  const { fetchCustomerStatus } = useCustomerState();
  const { fetchItemTypesStatus } = useItemTypesState();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchCountriesStatus === "IDLE") {
      dispatch(fetchCountries());
    }
  }, []);

  useEffect(() => {
    if (fetchAmenitiesStatus === "IDLE") {
      dispatch(fetchAmenities());
    }
  }, []);

  useEffect(() => {
    if (fetchConditionsStatus === "IDLE") {
      dispatch(fetchConditions());
    }
  }, []);

  useEffect(() => {
    if (fetchInsurancesStatus === "IDLE") {
      dispatch(fetchInsurances());
    }
  }, []);
 
  useEffect(() => {
    if (fetchCarEquipmentStatus === "IDLE") {
      dispatch(fetchCarEquipment());
    }
  }, []);

  useEffect(() => {
    if (fetchItemTypesStatus === "IDLE") {
      dispatch(fetchItemTypes());
    }
  }, []);

  useEffect(() => {
    if (fetchCustomerStatus === "IDLE") {
      const token = localStorage.getItem("auth-token");

      if (!token) {
        dispatch(customerActions.setCustomerData({ customer: null }));
        return;
      }

      const id_customer = localStorage.getItem("id_customer");
      dispatch(fetchCustomerData(id_customer as string));
    }
  }, []);
};

export { useLoadGlobalData };
